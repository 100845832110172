import React from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import "./AssessmentStatus.css";

function SencoReportsButton(props) {
  let assessment = props.assessment;
  let classDetails = props.classDetails;
  let classAssessments = props.classAssessmentsValues;
  let teachers = props.teachers;
  let teacherid = props.teacherid;
  let students = props.students;
  let schoolid = props.schoolid;

  let gradeLevel = classDetails.grade_level;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  let studentsReadingScore = props.studentsReadingScore;

  console.log(props);

  console.log(students, "BUTTON");

  const assessmentOrder = {
    baseline: 1,
    midpoint: 2,
    "end of year": 3,
  };

  const sortAssessments = (a, b) => {
    const getSortKey = (name) => {
      name = name.toLowerCase();
      if (name.includes("baseline") || name.includes("autumn"))
        return assessmentOrder["baseline"];
      if (name.includes("midpoint") || name.includes("spring"))
        return assessmentOrder["midpoint"];
      if (name.includes("end of year") || name.includes("summer"))
        return assessmentOrder["end of year"];
      return 4; // Default sort key
    };

    let aOrder = getSortKey(a.name);
    let bOrder = getSortKey(b.name);

    console.log(`Sorting ${a.name} (${aOrder}) and ${b.name} (${bOrder})`); // Debugging output

    return aOrder - bOrder;
  };

  let classAssessmentsArray = [];
  const getImageName = (assessmentName) => {
    assessmentName = assessmentName.toLowerCase();
    if (
      assessmentName.includes("baseline") ||
      assessmentName.includes("point 1") ||
      assessmentName.includes("autumn")
    ) {
      return "autumn";
    } else if (
      assessmentName.includes("midpoint") ||
      assessmentName.includes("point 2") ||
      assessmentName.includes("spring")
    ) {
      return "spring";
    } else if (
      assessmentName.includes("end of year") ||
      assessmentName.includes("point 3") ||
      assessmentName.includes("summer")
    ) {
      return "summer";
    } else {
      return assessmentName.split(" ")[0]; // Default to the first word of the assessment name
    }
  };

  classAssessments.forEach((classAssessment) => {
    const imageName = getImageName(classAssessment.name);

    classAssessmentsArray.push({
      ...classAssessment,
      imageName: imageName.toLowerCase(), // Convert image name to lowercase
    });
  });

  console.log(
    "Before sorting:",
    classAssessmentsArray.map((a) => `${a.name} (${a.imageName})`)
  );
  classAssessmentsArray.sort(sortAssessments);
  console.log(
    "After sorting:",
    classAssessmentsArray.map((a) => `${a.name} (${a.imageName})`)
  );

  const navigate = useNavigate();

  console.log(assessment, "test");

  const sencoReports = (assessment) => {
    navigate("/sencoreports", {
      state: {
        assessment: { assessment },
        classDetails: { classDetails },
        classAssessments: { classAssessments },
        classAssessmentsArray: { classAssessmentsArray },
        teachers: { teachers },
        teacherid: { teacherid },
        gradeLevel: { gradeLevel },
        students: { students },
        studentsReadingScore: { studentsReadingScore },
        schoolid:{schoolid}
      },
    });
  };

  return (
    <Col className="col-4">
      <button
        className={`light-blue-button assessment-button selected`}
        onClick={() => {
          sencoReports(classAssessments);
        }}
      >
        SENCO Handover
        <img
          src={`/assets/imgs/sencoImg.svg`}
          className="season-assessment-icon"
        />
      </button>
    </Col>
  );
}

export default SencoReportsButton;
