import { Component, Fragment } from "react";

import {withRouter} from '../utils/withRouter.js';

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import './NavigationButton.css';

// class Navigationbutton extends Component {

//     constructor(props) {
//         super();
//         this.navigate = this.navigate.bind(this);
//     }

//     navigate(event) {

//         // Redirect the user back to the login page
//         this.props.router.navigate(this.props.route);
//     }

//     render() {
        
//         if( !this.props.isLoggedIn ) {
//             console.log( "Returning null", this.props.isLoggedIn);
//             console.log( this.isLoggedIn );
//             return null;
//         }

//         return <Fragment><a href="#" onClick={this.navigate}><img src={this.props.img} alt={this.props.alt}/></a></Fragment>
//     }
// }

function Navigationbutton(props) {

    // let navigate = useNavigate();

    // async function handleClick(teacherid, event) {

    //     console.log( 'teacherid', teacherid );

    //     navigate(props.route, {state: {teacherid: 'foobar'}});
    // }

    if( !props.isLoggedIn ) {
        return null;
    }

    return(
        //<Fragment><a href="#" onClick={(e) => handleClick(props.teacherid, e)}><img src={props.img} alt={props.alt}/></a></Fragment>
        <Link to={props.route} state={{teacherid: props.teacherid}}><img src={props.img} alt={props.alt} className="img-button" /></Link>
    )
}

export default withRouter(Navigationbutton);