import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "./SimpleNavigationButton.css";

function SimpleNavigationButton({ label, path, colour }) {
  return (
    <Link to={path}>
      <button
        className={`${colour}-button`}
        style={{
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        }}
      >
        {label}
      </button>
    </Link>
  );
}

export default SimpleNavigationButton;
