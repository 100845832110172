import React from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./AssessmentStatus.css";

function TaskOptions(props) {
  let classDetails = props.classDetails;
  let classAssessments = props.classAssessmentsValues;
  let teachers = props.teachers;

  let gradeLevel = classDetails.grade_level;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  let schoolid = props.schoolid;
  let teacherid = props.teacherid;
  let name = props.name;

  const assessmentOrder = {
    baseline: 1,
    midpoint: 2,
    "end of year": 3,
  };

  console.log(props)

  const sortAssessments = (a, b) => {
    const getSortKey = (name) => {
      name = name.toLowerCase();
      if (name.includes("baseline") || name.includes("autumn"))
        return assessmentOrder["baseline"];
      if (name.includes("midpoint") || name.includes("spring"))
        return assessmentOrder["midpoint"];
      if (name.includes("end of year") || name.includes("summer"))
        return assessmentOrder["end of year"];
      return 4; // Default sort key
    };

    let aOrder = getSortKey(a.name);
    let bOrder = getSortKey(b.name);

    return aOrder - bOrder;
  };

  const getImageName = (assessmentName) => {
    assessmentName = assessmentName.toLowerCase();
    if (
      assessmentName.includes("baseline") ||
      assessmentName.includes("point 1") ||
      assessmentName.includes("autumn")
    ) {
      return "autumn";
    } else if (
      assessmentName.includes("midpoint") ||
      assessmentName.includes("point 2") ||
      assessmentName.includes("spring")
    ) {
      return "spring";
    } else if (
      assessmentName.includes("end of year") ||
      assessmentName.includes("point 3") ||
      assessmentName.includes("summer")
    ) {
      return "summer";
    } else {
      return assessmentName.split(" ")[0]; // Default to the first word of the assessment name
    }
  };

  let baselineTasks
  let midpointTasks
  let endOfYearTasks

  if (gradeLevel1){
    baselineTasks = [
      "Rhyming",
      "Initial Phoneme",
      "Phoneme Blending",
      "Letter Name",
      "Deletion",
    ];
    midpointTasks = [
      "Phoneme Blending",
      "Letter Name",
      "Initial Phoneme",
      "Letter Sound",
      "Deletion",
    ];
    endOfYearTasks = [
      "Initial Phoneme",
      "Letter Sound",
      "Word Recognition",
      "Deletion",
      "Nonword Recognition",
    ];
  } else {
    baselineTasks = [
     "Alphabetic Knowledge (a)",
    "Phonological/Phonemic Awareness (a)",
    "Phonological/Phonemic Awareness (b)",
    "Spelling (a)",
    "Spelling (b)",
    "Word Reading (a)",
    "Word Reading (b)",
    "Listening & Reading Comprehension (a)",
    "Listening & Reading Comprehension (b)",
    "Listening & Reading Comprehension (c)",
    "Listening & Reading Comprehension (d)",
    ];
    midpointTasks = [
      "Alphabetic Knowledge (a)",
      "Phonological/Phonemic Awareness (a)",
      "Phonological/Phonemic Awareness (b)",
      "Spelling (a)",
      "Spelling (b)",
      "Word Reading (a)",
      "Word Reading (b)",
      "Listening & Reading Comprehension (a)",
      "Listening & Reading Comprehension (b)",
      "Listening & Reading Comprehension (c)",
      "Listening & Reading Comprehension (d)",
    ];
    endOfYearTasks = [
      "Alphabetic Knowledge (a)",
      "Phonological/Phonemic Awareness (a)",
      "Phonological/Phonemic Awareness (b)",
      "Spelling (a)",
      "Spelling (b)",
      "Word Reading (a)",
      "Word Reading (b)",
      "Listening & Reading Comprehension (a)",
      "Listening & Reading Comprehension (b)",
      "Listening & Reading Comprehension (c)",
      "Listening & Reading Comprehension (d)",
    ];
  }
  

  const navigate = useNavigate();

  const toAssignTask = (tasks) => {
    navigate("/assigntask", {
      state: {
        tasks: { tasks },
        classDetails: { classDetails },
        teachers: { teachers },
        gradeLevel: { gradeLevel },
        schoolid: { schoolid },
        teacherid: { teacherid },
        name: { name },
      },
    });
  };

  const handleButtonClick = (taskType) => {
    let relevantTasks;
    if (taskType === "baseline") {
      relevantTasks = baselineTasks;
    } else if (taskType === "midpoint") {
      relevantTasks = midpointTasks;
    } else if (taskType === "endOfYear") {
      relevantTasks = endOfYearTasks;
    }

    const filteredTasks = classAssessments.filter((assessment) =>
      relevantTasks.includes(assessment.name)
    );

    toAssignTask(filteredTasks);
  };

  let classAssessmentsArray = [];
  classAssessments.forEach((classAssessment) => {
    const imageName = getImageName(classAssessment.name);

    classAssessmentsArray.push({
      ...classAssessment,
      imageName: imageName.toLowerCase(), // Convert image name to lowercase
    });
  });

  classAssessmentsArray.sort(sortAssessments);

  console.log(classAssessments, "CAA");

  return (
    <div>
      {classAssessmentsArray.length === 0 ? (
        <Row>
          <Col>
            <Alert variant="info" className="text-center">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="3x"
                className="mb-3"
              />
              <h4>No Retest Available</h4>
              <p>
                Once your students begin screening, the retest will then become
                available.
              </p>
            </Alert>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="col-4">
            <button
              className="light-blue-button assessment-button selected"
              onClick={() => handleButtonClick("baseline")}
            >
              Baseline
              <br></br>
              <img
                src="/assets/imgs/autumn-icon.png"
                className="season-assessment-icon"
                alt="Baseline"
              />
            </button>
          </Col>
          <Col className="col-4">
            <button
              className="light-blue-button assessment-button selected"
              onClick={() => handleButtonClick("midpoint")}
            >
              Midpoint
              <br></br>
              <img
                src="/assets/imgs/spring-icon.png"
                className="season-assessment-icon"
                alt="Midpoint"
              />
            </button>
          </Col>
          <Col className="col-4">
            <button
              className="light-blue-button assessment-button selected"
              onClick={() => handleButtonClick("endOfYear")}
            >
              End of Year
              <br></br>
              <img
                src="/assets/imgs/summer-icon.png"
                className="season-assessment-icon"
                alt="End of Year"
              />
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default TaskOptions;
