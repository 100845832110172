import React from "react";
import { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";

import "./ClassOverview.css";

import ResultsStatus from "./ResultsStatus";
import ReportsButton from "./ReportsButton";
import ClassStudents from "./ClassStudents";
import SimpleNavigatioButton from "./SimpleNavigationButton";
import GradeCard from "./GradeCard";

import { getClassAssessments } from "../services/APIClient";

function ResultClassOverview(props) {
  // Class Personnel
  let classDetails = props.classDetails;
  let classid = classDetails.id;
  let gradeLevel = props.gradeLevel;
  let teachers = props.teachers;
  let teacherid = props.teacherid;
  let students = props.students;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;
  let schoolid = props.schoolid;

  const [classAssessments, setClassAssessments] = useState([]);
  const [gradeLevel1Assessments, setGradeLevel1Assessments] = useState([]);
  const [gradeLevel2Assessments, setGradeLevel2Assessments] = useState([]);

  const classAssessmentsValues = Object.values(classAssessments);

  console.log(students, "overview");

  console.log(props);

  useEffect(() => {
    getClassAssessments(classid).then((response) => {
      console.log(response);
      setClassAssessments(response);
    });
  }, [classid]);

  console.log("classAssessmentsValues:", classAssessmentsValues);

  useEffect(() => {
    const gradeLevel1Arr = [];
    const gradeLevel2Arr = [];

    Object.values(classAssessments).forEach((assessment) => {
      if (!assessment.task) {
        if (assessment.grade_level1) {
          gradeLevel1Arr.push(assessment);
        }
        if (assessment.grade_level2) {
          gradeLevel2Arr.push(assessment);
        }
      }
    });

    setGradeLevel1Assessments(gradeLevel1Arr);
    setGradeLevel2Assessments(gradeLevel2Arr);
  }, [classAssessments]);

  // console.log("classAssessments:", classAssessments)

  console.log("gradeLevel1Assessments:", gradeLevel1Assessments);
  console.log("gradeLevel2Assessments:", gradeLevel2Assessments);

  const prefix = gradeLevel1Assessments[0]?.name.split(" ")[0];

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-7">
            <Row className="inline-block">
              <Col className="col-6">
                <h2 className="page-description-header">Results Overview</h2>
              </Col>
              <Col className="col-3">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />
              </Col>
            </Row>
            <p className="paragraph-font-size">
              Select the assessment that you would like to see the results for.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            {gradeLevel1Assessments.length > 0 ? (
              gradeLevel2 ? (
                <Accordion className="results-custom-accordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <strong>{prefix} Screener Results</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ResultsStatus
                        classAssessmentsValues={gradeLevel1Assessments}
                        classDetails={classDetails}
                        teachers={teachers}
                        schoolid={schoolid}
                        teacherid={teacherid}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : (
                <ResultsStatus
                  classAssessmentsValues={gradeLevel1Assessments}
                  classDetails={classDetails}
                  teachers={teachers}
                  schoolid={schoolid}
                  teacherid={teacherid}
                />
              )
            ) : null}
            {gradeLevel2Assessments.length > 0 ? (
              <ResultsStatus
                classAssessmentsValues={gradeLevel2Assessments}
                classDetails={classDetails}
                teachers={teachers}
                schoolid={schoolid}
                teacherid={teacherid}
              />
            ) : null}
            {/* {schoolid === "e0d40e40-0364-11ed-a237-4f910a76850a" ||
              ("559ebb98-09dc-11ee-be56-0242ac120002" && (
                <ReportsButton
                  classAssessmentsValues={classAssessmentsValues}
                  classDetails={classDetails}
                  teachers={teachers}
                  teacherid={teacherid}
                  schoolid={schoolid}
                />
              ))} */}
            <br></br>
            <Col>
              <SimpleNavigatioButton
                path="/resultsdashboard"
                label="Back"
                colour="blue"
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResultClassOverview;
