import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const CandyBearAnalysisGraph = ({
  selectedChild,
  setSelectedChildID,
  individualStudentResult,
}) => {
  const [taskData, setTaskData] = useState({});
  const [assessmentNames, setAssessmentNames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedChild?.student?.id) {
      setSelectedChildID(selectedChild.student.id);
    }
  }, [selectedChild]);

  useEffect(() => {
    if (individualStudentResult && individualStudentResult.length > 0) {
      setLoading(false);

      const tasksByName = {};
      const names = [];

      // Reverse the individualStudentResult array to process assessments in reverse index order
      const reversedResults = [...individualStudentResult].reverse();

      reversedResults.forEach((assessment, index) => {
        names.push(assessment.name);

        // Check if the assessment has tasks before processing
        if (assessment.tasks && assessment.tasks.length > 0) {
          assessment.tasks.forEach((task) => {
            if (!tasksByName[task.name]) {
              // Initialize an array of zeros based on the total number of assessments
              tasksByName[task.name] = new Array(reversedResults.length).fill(
                0
              );
            }
            tasksByName[task.name][index] = Math.round(task.standardScore || 0); // Round to nearest whole number
          });
        } else {
          // If there are no tasks, add zeros for all tasks in this assessment's index
          Object.keys(tasksByName).forEach((taskName) => {
            tasksByName[taskName][index] = 0;
          });
        }
      });

      setTaskData(tasksByName);
      setAssessmentNames(names);
    } else {
      setLoading(true);
      setTaskData({});
      setAssessmentNames([]);
    }
  }, [individualStudentResult]);

  if (loading) {
    return <div>Loading...</div>; // Placeholder while data is being fetched
  }

  // Colors for the bars
  const colors = [
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 165, 0, 0.6)",
    "rgba(144, 238, 144, 0.6)",
  ];
  const borderColors = [
    "rgba(54, 162, 235, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(75, 192, 192, 1)",
  ];

  // Prepare data for the chart
  const taskNames = Object.keys(taskData);
  const datasets = assessmentNames.map((name, index) => ({
    label: name,
    data: taskNames.map((taskName) => taskData[taskName][index]),
    backgroundColor: colors[index % colors.length], // Use the color for the bar
    borderColor: borderColors[index % borderColors.length], // Use the border color for the bar
    borderWidth: 1,
  }));

  // Data for the chart
  const data = {
    labels: taskNames,
    datasets,
  };

  console.log(selectedChild.student.name)
  console.log(setSelectedChildID)
  console.log(individualStudentResult)

  // Options for the chart
  const options = {
    responsive: true,
    indexAxis: "x", // Display tasks on the x-axis
    scales: {
      x: {
        title: {
          display: true,
          text: "Tasks",
          font: {
            size: 16, // Increase font size for the x-axis title
          },
        },
        ticks: {
          font: {
            size: 14, // Increase the font size of the x-axis labels
          },
        },
        stacked: false, // Disable stacking to display grouped bars side by side
      },
      y: {
        title: {
          display: true,
          text: "Scores",
          font: {
            size: 16, // Increase the font size of the y-axis title
          },
        },
        ticks: {
          stepSize: 10, // Adjust step size for ticks
          font: {
            size: 14, // Increase the font size of the y-axis labels
          },
          min: 0,
          max: 140,
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Show the legend
        position: "top", // Position the legend at the top
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label} - ${Math.round(context.raw)}`;
          },
        },
      },
      title: {
        display: true, // Display the title
        text: `${selectedChild.student.name} Analysis - Progress Between Testing Points - Standard Scores`,
        font: {
          size: 18, // Font size for the title
        },
        padding: {
          bottom: 20, // Space below the title
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default CandyBearAnalysisGraph;
