import React from "react";
import { Link } from "react-router-dom";

// import button from 'react-bootstrap/button';

import "./ClassSettingsButton.css";

function ClassSettingsbutton(props) {
  let group = props.group;

  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let name = props.name;
  let gradeLevel = props.gradeLevel;
  let students = props.students;
  let qrCodeDetails = props.qrCodeDetails;

  return (
    <Link
      to={`/class/settings`}
      state={{
        group: { group },
        classDetails: { classDetails },
        teachers: { teachers },
        sharedWith: { sharedWith },
        name: { name },
        gradeLevel: { gradeLevel },
        students: { students },
        qrCodeDetails: { qrCodeDetails },
      }}
      className="settings-cog"
    >
      {/* <button>
                <p>Settings</p>
            </button> */}
      <img
        src="/assets/imgs/edit-icon.svg"
        className="settings-cog"
        alt="Settings"
      />
    </Link>
  );
}

export default ClassSettingsbutton;
