import React from "react";
import ReactPaginate from "react-paginate";
import "./PaginationComponent.css";

function PaginationComponent({ itemsPerPage, totalItems, handlePageClick }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="pagination-container">
      <ReactPaginate
        breakLabel="..."
        nextLabel="›"
        onPageChange={(data) => handlePageClick(data.selected + 1)}
        pageRangeDisplayed={10}
        marginPagesDisplayed={1}
        pageCount={totalPages}
        previousLabel="‹"
        renderOnZeroPageCount={null}
        containerClassName="justify-content-center pagination"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
        disabledClassName="disabled"
      />
    </div>
  );
}

export default PaginationComponent;
