import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import AddStudentsOverview from "../components/AddStudentsOverview";
import OrientationPrompt from "../components/OrientationPrompt";

function AddStudents(props) {
  const location = useLocation();

  let name = location.state.name.name;
  let gradeLevel = location.state.gradeLevel.gradeLevel;
  let schoolid = location.state?.schoolid.schoolid;
  let isSpecialEdClass = location.state?.isSpecialEdClass.isSpecialEdClass;

  let teacherid = location.state.classDetails.classDetails.teacherId;
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <AddStudentsOverview
            classDetails={location.state.classDetails}
            teachers={location.state.teachers}
            sharedWith={location.state.sharedWith}
            // classDetails={specificClass}
            name={name}
            gradeLevel={gradeLevel}
            schoolid={schoolid}
            isSpecialEdClass={isSpecialEdClass}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default AddStudents;
