import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import ReactToPrint from "react-to-print";

import ResultsHistory from "./ResultsHistory";

import "./PrintStudentResults.css";


function PrintStudentResults(props) {
  let individualStudentResult = props.individualStudentResult;
  let assessmentResultsByStudent = props.assessmentResultsByStudent;
  let student = props.student;
  let classDetails = props.classDetails;
  let detailedView = props.detailedView;
  let buttonName = props.buttonName;

  console.log(props)

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const currentYear = currentDate.getFullYear();

  // console.log(classAssessments[0].name)
  const componentRef = useRef();

  return (
    <>
      <StudentPrintable
        individualStudentResult={individualStudentResult}
        // toggleScoreType={toggleScoreType}
        assessmentResultsByStudent={assessmentResultsByStudent}
        student={student}
        classDetails={classDetails}
        currentDay={currentDay}
        currentMonth={currentMonth}
        currentYear={currentYear}
        detailedView={detailedView}
        ref={componentRef}
      />

      <ReactToPrint
        content={() => componentRef.current}
        trigger={() => (
          <button className="blue-button" style={{ borderRadius: "8px" }}>
            {buttonName}
          </button>
        )}
      />
    </>
  );
}

const StudentPrintable = React.forwardRef((props, ref) => (
  <div className="hide-div landscape-print">
    <div ref={ref}>
      <Row className="mt-5 text-center">
        <Col className="printout-logo col-2" ></Col>
        <Col className="col-4"><h3 className="printout-page-description-header">{props.student.name}</h3></Col>
        <Col className="col-4"><h3 className="printout-page-description-header">{props.classDetails.name}</h3></Col>
        <Col className="col-2"><h3 className="printout-page-description-header">{props.currentMonth} {props.currentYear}</h3></Col>
      </Row>
      <Row>
        <ResultsHistory
          individualStudentResult={props.individualStudentResult}
          // toggleScoreType={props.toggleScoreType}
          assessmentResultsByStudent={props.assessmentResultsByStudent}
          detailedView={props.detailedView}
          isPrintOut={true}
        />
      </Row>
    </div>
  </div>
));

export default PrintStudentResults;
