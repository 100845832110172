import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./LiteracyObservationsModal.css";
import {
  getJiSiReportByStudentId,
  upsertJiSiReport,
} from "../services/APIClient";

function LiteracyObservationsModal({
  show,
  handleClose,
  studentName,
  currentStudentId,
  teacherid,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [literacyObservations, setLiteracyObservations] = useState("");

  useEffect(() => {
    if (show && currentStudentId) {
      // Fetch literacy observations when the modal is shown and currentStudentId is available
      getJiSiReportByStudentId(currentStudentId)
        .then((response) => {
          if (response && response.literacyobservations !== undefined) {
            setLiteracyObservations(response.literacyobservations);
            setSelectedOption(response.literacyobservations); // Pre-select the option if available
          } else {
            setLiteracyObservations("");
            setSelectedOption("");
          }
        })
        .catch((error) => {
          console.error("Error fetching literacy observations:", error);
          setLiteracyObservations("");
          setSelectedOption("");
        });
    }
  }, [show, currentStudentId]);

  const handleOptionClick = (text) => {
    const updatedOption = selectedOption === text ? "" : text;
    setSelectedOption(updatedOption);
    setLiteracyObservations(updatedOption);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
  };

  const handleModalClose = () => {
    setSelectedOption(literacyObservations); // Reset selected option to current literacy observations
    handleClose();
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      literacyobservations: literacyObservations,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      upsertJiSiReport(reportData)
        .then((response) => {
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  const items = [
    { text: "Highly Capable and competent", className: "green-border" },
    { text: "Capable and competent", className: "yellow-border" },
    { text: "Managing Comfortably", className: "light-orange-border" },
    { text: "Experiencing some difficulty", className: "orange-border" },
    { text: "Experiencing significant difficulty", className: "red-border" },
  ];

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Literacy Observations
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {items.map((item, index) => (
          <div
            key={index}
            className={`observation-item ${item.className} ${
              selectedOption === item.text ? `selected ${item.className}` : ""
            }`}
            onClick={() => handleOptionClick(item.text)}
          >
            {item.text}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="primary"
          onClick={saveChanges}
          className="modal-button yes-button"
        >
          Confirm
        </button>
        <button
          variant="secondary"
          onClick={handleModalClose}
          className="modal-button no-button"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default LiteracyObservationsModal;
