import React from "react";
import { Link } from "react-router-dom";

import "./AssignAssessmentButton.css";

function AssignAssessmentbutton(props) {
  let students = props.students;
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let name = props.name;
  let gradeLevel = props.gradeLevel;

  return (
    <Link
      to={`/assignassessment`}
      state={{
        classDetails: { classDetails },
        teachers: { teachers },
        sharedWith: { sharedWith },
        name: { name },
        gradeLevel: { gradeLevel },
        students: { students },
      }}
    >
      <button
        className="blue-button"
      >
        Assign Assessments
      </button>
    </Link>
  );
}

export default AssignAssessmentbutton;
