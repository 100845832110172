import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Col, Row, Modal, Table, Button } from "react-bootstrap";

import {
  getStudentsForClass,
  getAssessmentTypes,
  assignAssessment,
  getStudents,
} from "../services/APIClient";

import AssessmentTable from "./AssessmentTable";
import SelectClassStudents from "./SelectClassStudents";
import GradeCard from "./GradeCard";
import ReturnToAssessmentOverview from "./ReturnToAssessmentOverview";

import "./AssignAssessmentOverview.css";

function AssignAssessmentOverview() {
  const location = useLocation();
  const navigate = useNavigate();

  const classDetails = location.state.classDetails.classDetails;
  const name = location.state.name.name;
  const teachers = location.state.teachers.teachers;
  const gradeLevel = location.state.gradeLevel.gradeLevel;
  const gradeLevelOptions = location.state.gradeLevelOptions.gradeLevelOptions;

  const classid = location.state.classDetails.classDetails.id;
  const teacherid = classDetails.teacherId;
  
  const [studentsResponse, setStudentsResponse] = useState("");
  const [classStudents, setClassStudents] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [selectStudentId, setSelectStudentId] = useState([]);
  const [selectAssessmentId, setSelectAssessmentId] = useState(null);
  const [selectAssessmentName, setSelectAssessmentName] = useState(null);
  const [isCheck, setIsCheck] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const gradeLevel1 = classDetails.grade_level1;
  const gradeLevel2 = classDetails.grade_level2;
  const trialClass = classDetails.trial_class;

  useEffect(() => {
    getStudents(classid)
      .then((response) => setStudentsResponse(response))
      .catch((err) => setError("Failed to fetch students."));
  }, [classid]);

  useEffect(() => {
    getStudentsForClass(classid)
      .then((response) => setClassStudents(response.students))
      .catch((err) => setError("Failed to fetch students for class."));
  }, [classid]);

  useEffect(() => {
    getAssessmentTypes()
      .then((response) => {
        const filteredAssessments = response.filter(assessment => assessment.task !== true);
        setAssessments(filteredAssessments);
      })
      .catch(() => setError("Failed to fetch assessments."));
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const newSelectStudentId = selectStudentId.map((studentId) => ({ id: studentId }));

  const handleClick = () => {
    assignAssessment(newSelectStudentId, selectAssessmentId, selectAssessmentName)
      .then(() => navigate(-1, { state: { toast: { status: true, msg: "Assessments Assigned" } } }))
      .catch(() => navigate(-1));
  };

  const allAssigned = classStudents?.every(
    (student) =>
      student.disabled ||
      (student.assessments && student.assessments.some((assessment) => !assessment.completed))
  );

  const getSelectedStudentInfo = () => {
    return classStudents
      ?.filter((student) => selectStudentId.includes(student.id))
      ?.map((student, index) => ({
        index: index + 1,
        name: student.name,
        assessment: selectAssessmentName || "N/A"
      }));
  };

  const selectedStudents = getSelectedStudentInfo();
  const noStudentsSelectedMessage = allAssigned
    ? "All students in the class have already been assigned a screener."
    : "No students have been selected to assign a screener.";

  console.log("classStudents:", classStudents)
  console.log("selectAssessmentId:", selectAssessmentId)


  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-5">
            <h2 className="page-description-header">Assign Screener</h2>
            <p>Assign screeners to the students in your class.</p>
          </Col>
          <Col className="col-2">
            <GradeCard
              gradeLevel={gradeLevel}
              gradeLevel1={gradeLevel1}
              gradeLevel2={gradeLevel2}
              trialClass={trialClass}
            />
          </Col>
          <Col className="col-5">
            <Row>
              <Col className="col-6"></Col>
              <Col className="col-6">
                <ReturnToAssessmentOverview
                  classDetails={classDetails}
                  teachers={teachers}
                  name={name}
                  gradeLevel={gradeLevel}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        {error && <p className="text-danger">{error}</p>}
        <Row>
          <Col className="col-7 vertical-border-line">
            <SelectClassStudents
              students={studentsResponse.students}
              classStudents={classStudents}
              selectStudentId={selectStudentId}
              setSelectStudentId={setSelectStudentId}
              isCheck={isCheck}
              setIsCheck={setIsCheck}
              name={name}
              selectAssessmentId={selectAssessmentId}
              gradeLevel1={gradeLevel1}
              gradeLevel2={gradeLevel2}
              
            />
          </Col>
          <Col className="col-5">
            <AssessmentTable
              assessments={assessments}
              selectAssessmentId={selectAssessmentId}
              setSelectAssessmentId={setSelectAssessmentId}
              setSelectAssessmentName={setSelectAssessmentName}
              classDetails={classDetails}
              gradeLevelOptions={gradeLevelOptions}
              
            />
          </Col>
          <Col>
            <div className="button-placer">
              {selectAssessmentId && (
                <button onClick={handleShowModal} className="blue-button save-button">
                  Assign
                </button>
              )}
            </div>
          </Col>
        </Row>

        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Screener Assignment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedStudents?.length === 0 ? (
              <p>{noStudentsSelectedMessage}</p>
            ) : (
              <>
                <p>The following students will receive the screener:</p>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Student Name</th>
                      <th>Screener Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedStudents?.map((student) => (
                      <tr key={student.index}>
                        <td>{student.index}</td>
                        <td>{student.name}</td>
                        <td>{student.assessment}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="white-button" onClick={handleCloseModal}>
              Close
            </button>
            {selectedStudents?.length > 0 && (
              <button className="green-button" blue-button onClick={handleClick}>
                Assign
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default AssignAssessmentOverview;
