import React, { useEffect, useState } from "react";
import HeadingBanner from "../components/HeadingBanner";
import ResultsDashCard from "../components/ResultsDashCard";
import LeftPanel from "../components/LeftPanel";
import AlpacaInfoGesture from "../components/AlpacaInfoGesture";
import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
} from "../services/APIClient";
import { Container, Row, Col } from "react-bootstrap";
import "../components/ClassCard.css";
import ClassFilter from "../components/ClassFilter";
import OrientationPrompt from "../components/OrientationPrompt";

function ResultsDashboard() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [speechText, setSpeechText] = useState(null);
  const [classes, setClasses] = useState([]);
  const [rollNumber, setRollNumber] = useState(null);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [teacherRoles, setTeacherRoles] = useState(null);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    whoami().then((response) => {
      setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      setTeacherRoles(response.data.stakeholder.additionalRoles)
    });
  }, []);

  useEffect(() => {
    async function fetchSchoolData() {
      try {
        const teachersResponse = await getTeachers(schoolid);
        setTeachers(teachersResponse);

        let classesResponse = await getClasses();

        classesResponse = classesResponse.filter(
          (classItem, index, self) =>
            index === self.findIndex((t) => t.id === classItem.id)
        );

        classesResponse.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        );
        setClasses(classesResponse);

        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const rollNumber = matchingSchool.rollnumber;
          const countryid = matchingSchool.countryid;
          setRollNumber(rollNumber);

          getCountryGrades(countryid)
            .then((gradesResponse) => {
              setGradeLevelOptions([gradesResponse]);
            })
            .catch((error) => {
              console.error("Error fetching country grades:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    }

    if (schoolid) {
      fetchSchoolData().finally(() => {
        setLoading(false);
      });
    }
  }, [schoolid]);

  useEffect(() => {
    if (name) {
      let nameArray = name.split(" ");
      setFirstname(nameArray[0]);
    }
  }, [name, schoolid]);

  useEffect(() => {
    setSpeechText(
      `Whoa, slow down there ${firstname}. We need to create a class first. Click into "Admin" on the Left to get this started`
    );
  }, [firstname]);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`${name}'s Results`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
        />

        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? (
              <div className="spinner-container">
                <div className="big-ball-spinner"></div>
              </div>
            ) : (
              <Row className="row-position">
                {classes.length > 0 && (
                  <>
                    <ClassFilter
                      classes={classes}
                      setFilteredClasses={setFilteredClasses}
                      gradeLevelOptions={gradeLevelOptions}
                      teacherRoles={teacherRoles}
                    />
                    <br></br>
                  </>
                )}
                {classes.length === 0 ? (
                  <>
                    <Col className="col-2"></Col>
                    <Col className="alpaca-message-positioner">
                      <AlpacaInfoGesture speechText={speechText} />
                    </Col>
                  </>
                ) : (
                  filteredClasses.map((item, index) => (
                    <ResultsDashCard
                    key={`${item.id}-${index}`}
                    teachers={teachers}
                      classDetails={item}
                      rollNumber={rollNumber}
                      teacherid={teacherid}
                      schoolid={schoolid}
                    />
                  ))
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
      {isPortrait && <OrientationPrompt />}
    </div>
  );
}

export default ResultsDashboard;
