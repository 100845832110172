import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./SupportPlanModal.css";
import {
  getSencoReportByStudentId,
  upsertSencoReport,
} from "../services/APIClient";

const supportOptions = [
  { text: "Speak to Parents", color: "purple" },
  { text: "SET in group", color: "purple" },
  { text: "individual SET", color: "purple" },
  { text: "Classroom support plan - talk to teacher", color: "purple" },
  { text: "Review classroom support plan", color: "purple" },
  { text: "Social Skills", color: "purple" },
  { text: "Attention and Concentration", color: "purple" },
  { text: "Fluency and Decoding", color: "purple" },
];

const SencoSupportPlanModal = ({
  show,
  handleClose,
  studentName,
  // sencoSupportPlan,
  // setSencoSupportPlan,
  // handleSaveChanges,
  currentStudentId,
  teacherid,
  callClassName,
  setName,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sencoSupportPlan, setSencoSupportPlan] = useState([]);

  useEffect(() => {
    if (show && currentStudentId) {
      // Fetch literacy observations when the modal is shown and currentStudentId is available
      getSencoReportByStudentId(currentStudentId)
        .then((response) => {
          if (response && response.supportplan !== undefined) {
            setSencoSupportPlan(response.supportplan);
            setSelectedOptions(response.supportplan || []); // Pre-select the options if available
          } else {
            setSencoSupportPlan([]);
            setSelectedOptions([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching literacy observations:", error);
          setSencoSupportPlan([]);
          setSelectedOptions([]);
        });
    }
  }, [show, currentStudentId, setSencoSupportPlan]);

  const handleOptionClick = (text) => {
    const updatedOptions = selectedOptions.includes(text)
      ? selectedOptions.filter((option) => option !== text)
      : [...selectedOptions, text];
    setSelectedOptions(updatedOptions);
    setSencoSupportPlan(updatedOptions);
    console.log(`Selected options for ${studentName}:`, updatedOptions);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
  };

  const handleModalClose = () => {
    setSelectedOptions([]); // Reset selected options to an empty array
    handleClose();
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      supportplan: sencoSupportPlan,
      setname: setName,
      classname: callClassName,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      // setLastSavedStudentId(currentStudentId); // Update last saved student ID
      // resetStateVariables();
      upsertSencoReport(reportData)
        .then((response) => {
          // Reset only if saving for a different student
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Classroom Support Plan
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="options-container">
          {supportOptions.map((option) => (
            <div
              key={option.text}
              className={`option ${option.color} ${
                (selectedOptions || []).includes(option.text) ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.text)}
            >
              {option.text}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="modal-button no-button" onClick={handleModalClose}>
          Cancel
        </button>
        <button className="modal-button yes-button" onClick={saveChanges}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SencoSupportPlanModal;
