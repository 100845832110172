import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const StudentTaskScoresChart = ({ assessmentResultsByStudent }) => {
  // Extract unique students and their tasks
  const studentData = assessmentResultsByStudent.map((student) => {
    const tasks = student.tasks.reduce((acc, task) => {
      acc[task.name] = (task.score * 100).toFixed(2); // Convert to percentage and round to 2 decimal places
      return acc;
    }, {});

    return {
      name: student.student.name,
      tasks,
    };
  });

  // Create a bar chart for each student
  const studentCharts = studentData.map((student) => {
    // Gather scores for the current student and sort in descending order
    const scores = Object.entries(student.tasks)
      .map(([taskName, score]) => ({
        taskName,
        score: parseFloat(score),
      }))
      .sort((a, b) => b.score - a.score);

    const data = {
      labels: scores.map((score) => score.taskName),
      datasets: [
        {
          label: student.name,
          data: scores.map((score) => score.score),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      indexAxis: "y", // Display bars horizontally
      scales: {
        x: {
          title: {
            display: true,
            text: "Scores",
            font: {
              size: 16, // Increase font size for the x-axis title
            },
          },
          ticks: {
            callback: (value) => value.toFixed(2), // Ensure x-axis labels are rounded to 2 decimal places
            font: {
              size: 14, // Increase the font size of the x-axis labels
            },
          },
        },
        y: {
          title: {
            display: true,
            text: "Tasks",
            font: {
              size: 16, // Increase the font size of the y-axis title
            },
          },
          ticks: {
            autoSkip: false,
            font: {
              size: 14, // Increase the font size of the y-axis labels
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
            },
          },
        },
        title: {
          display: false, // Remove the chart-specific title
        },
      },
    };

    return (
      <div key={student.name} style={{ marginBottom: "40px" }}>
        <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
          {student.name}
        </h3>
        <Bar data={data} options={options} />
      </div>
    );
  });

  return (
    <div>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Student Performance in All 5 Subtests
      </h2>
      {studentCharts}
    </div>
  );
};

export default StudentTaskScoresChart;
