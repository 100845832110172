import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { getClass, getStudents, getQR } from "../services/APIClient";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import SencoReportsOverview from "../components/SencoReportsOverview";
import OrientationPrompt from "../components/OrientationPrompt";

import { Container, Row, Col } from "react-bootstrap";

import "../components/ClassOverview.css";

function SencoReports(props) {
  const location = useLocation();

  let classDetails = location.state.classDetails.classDetails;
  let name = classDetails.name;
  let teachers = location.state.teachers.teachers;
  let gradeLevel = location.state?.gradeLevel?.gradeLevel;
  let classAssessments = location.state.classAssessments.classAssessments;
  let assessment = location.state.assessment.assessment;

  let classid = location.state.classDetails.classDetails.id;
  // let teacherid = classDetails.teacherId;
  let students = location.state.students.students;

  let schoolid = location.state.schoolid.schoolid;
  let teacherid = location.state.teacherid.teacherid;

  console.log(location);
  let studentsReadingScore =
    location.state.studentsReadingScore.studentsReadingScore;

  console.log(teacherid, "jisisreports");

  const [group, setGroup] = useState("");
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    getClass(classid).then((response) => {
      setGroup(response);
    });
  }, [classid]);

  let sharedWith = group.sharedWith;

  console.log(students, "HELLO");

  let [qrCodeDetails, setQRCodeDetails] = useState("");

  useEffect(() => {
    getQR(classid).then((response) => {
      setQRCodeDetails(response);
    });
  }, [classid]);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <SencoReportsOverview
            assessment={assessment}
            classDetails={classDetails}
            teachers={teachers}
            sharedWith={sharedWith}
            students={students}
            gradeLevel={gradeLevel}
            name={name}
            qrCodeDetails={qrCodeDetails}
            classAssessments={classAssessments}
            classid={classid}
            teacherid={teacherid}
            studentsReadingScore={studentsReadingScore}
            schoolid={schoolid}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default SencoReports;
