import { Component, Fragment } from "react";

import {withRouter} from '../utils/withRouter.js';

import { useNavigate } from "react-router-dom";

import './Logout.css';

// class Logout extends Component {

//     constructor() {
//         super();
//         this.handleLogout = this.handleLogout.bind(this);
//         this.state = {
//             id: "",
//             password: "",
//             error: false
//         };
//     }

//     handleLogout(event) {
//         event.preventDefault();

//         // There should only be one key/value pair in localStorage 
//         // so we can be lazy and just call clear()
//         localStorage.clear();


//         // Redirect the user back to the login page
//         this.props.router.navigate("/");
//     }

//     render() {

//         if( !this.props.isLoggedIn ) {
//             return null;
//         }

//         return <Fragment><a href="#" onClick={this.handleLogout}><img src="assets/imgs/logout_icon.svg" alt="logout"/></a></Fragment>
//     }
// }

function Logout(props) {

    let navigate = useNavigate();

    async function handleLogout(event) {
        event.preventDefault();

        // There should only be one key/value pair in localStorage 
        // so we can be lazy and just call clear()
        localStorage.clear();

        navigate("/");
    }

    if( !props.isLoggedIn ) {
        return null;
    }

    return(
        <Fragment><a href="#" onClick={handleLogout}><img src="/assets/imgs/logout_icon.svg" alt="logout" className="img-button"/></a></Fragment>
    )
}

export default withRouter(Logout);