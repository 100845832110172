import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import ReactToPrint from "react-to-print";

import AllReportCardsHistory from "./AllReportCardsHistory";
import "./PrintStudentResults.css";

function PrintAllStudentReportCards(props) {
  const {
    individualStudentResult,
    assessmentResultsByStudent,
    student,
    classDetails,
    detailedView,
    buttonName,
    studentResults,
    students,
    schoolid,
    teacherid,
    toggleScoreType,
  } = props;

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const currentYear = currentDate.getFullYear();

  const componentRef = useRef();

  return (
    <>
      <StudentPrintable
        individualStudentResult={individualStudentResult}
        assessmentResultsByStudent={assessmentResultsByStudent}
        student={student}
        classDetails={classDetails}
        currentDay={currentDay}
        currentMonth={currentMonth}
        currentYear={currentYear}
        detailedView={detailedView}
        ref={componentRef}
        studentResults={studentResults}
        toggleScoreType={toggleScoreType}
        isPrintOut={true}
        teacherid={teacherid}
        schoolid={schoolid}
        students={students}
      />

      <ReactToPrint
        content={() => componentRef.current}
        trigger={() => (
          <button className="blue-button" style={{ borderRadius: "8px" }}>
            Print All Report Cards
          </button>
        )}
      />
    </>
  );
}

const StudentPrintable = React.forwardRef((props, componentRef) => (
  <div className="hide-div landscape-print">
    <div ref={componentRef}>
      <Row className="mt-5 text-center">
        <Col className="printout-logo col-2"></Col>
        <Col className="col-5">
          <h3 className="printout-page-description-header">Report Cards</h3>
        </Col>
        <Col className="col-5">
          <h3 className="printout-page-description-header">
            {props.classDetails.name}
          </h3>
        </Col>
        {/* <Col className="col-5">
          <h3 className="printout-page-description-header">
            {props.currentMonth} {props.currentYear}
          </h3>
        </Col> */}
        <Col className="col-2"></Col>
      </Row>
      <Row>
        <AllReportCardsHistory
          studentResults={props.studentResults}
          toggleScoreType={props.toggleScoreType}
          assessmentResultsByStudent={props.assessmentResultsByStudent}
          teacherid={props.teacherid}
          schoolid={props.schoolid}
          students={props.students}
        />
      </Row>
    </div>
  </div>
));

export default PrintAllStudentReportCards;
