import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { getClass, getStudents, getQR } from "../services/APIClient";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import RetestTaskDetailOverview from "../components/RetestTaskDetailOverview";
import OrientationPrompt from "../components/OrientationPrompt";

import { Container, Row, Col } from "react-bootstrap";

import "../components/ClassOverview.css";

function RetestTaskDetail(props) {
  const location = useLocation();

  let classDetails = location.state.classDetails.classDetails;
  let schoolid = location?.state?.schoolid?.schoolid;
  let teacherid = location?.state?.teacherid?.teacherid;
  let name = location.state.name.name;
  let teachers = location.state.teachers.teachers;
  let gradeLevel = location.state?.gradeLevel?.gradeLevel;
  let gradeLevelOptions = location.state?.gradeLevelOptions?.gradeLevelOptions;

  let classid = location.state.classDetails.classDetails.id;
  // let teacherid = classDetails.teacherId;
  console.log(teacherid, "Teacher ID");

  const [group, setGroup] = useState("");
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    getClass(classid).then((response) => {
      setGroup(response);
    });
  }, [classid]);

  let sharedWith = group.sharedWith;

  let [studentsResponse, setStudentsResponse] = useState("");

  useEffect(() => {
    getStudents(classid).then((response) => {
      setStudentsResponse(response);
    });
  }, [classid]);

  let students = studentsResponse.students;

  let [qrCodeDetails, setQRCodeDetails] = useState("");

  useEffect(() => {
    getQR(classid).then((response) => {
      setQRCodeDetails(response);
    });
  }, [classid]);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <RetestTaskDetailOverview
            classDetails={classDetails}
            teachers={teachers}
            sharedWith={sharedWith}
            students={students}
            gradeLevel={gradeLevel}
            gradeLevelOptions={gradeLevelOptions}
            name={name}
            qrCodeDetails={qrCodeDetails}
            teacherid={teacherid}
            schoolid={schoolid}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default RetestTaskDetail;
