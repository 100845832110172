import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ReadingScoreNotesModal.css";
import {
  getJiSiReportByStudentId,
  upsertJiSiReport,
} from "../services/APIClient";

const options = [
  { text: "Generally Excellent", color: "green" },
  { text: "Hearing and recoding sounds in words excellent", color: "green" },
  { text: "Hearing and recoding sounds in words good", color: "green" },
  { text: "Concepts about print well developed", color: "green" },
  { text: "Word attack skills good of unknown words", color: "yellow" },
  { text: "Reading good but comprehension poor", color: "yellow" },
  { text: "Reflective of reading ability", color: "yellow" },
  { text: "Letter identification good but blending poor", color: "yellow" },
  { text: "Not reflective of reading ability", color: "orange" },
  { text: "Hearing and recoding sounds in words poor", color: "orange" },
  { text: "Word attack skills poor of unknown words", color: "orange" },
  { text: "Needed support to complete task", color: "orange" },
  { text: "Fluency poor when reading", color: "red" },
  { text: "Child appeared to guess answers", color: "red" },
  { text: "Struggled with task", color: "red" },
  { text: "Struggled with task and got upset and overwhelmed", color: "red" },
];

const ReadingScoreNotesModal = ({
  show,
  handleClose,
  studentName,
  currentStudentId,
  teacherid,
  setSaveClicked,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [readingScoreNotes, setReadingScoreNotes] = useState([]);

  useEffect(() => {
    if (show && currentStudentId) {
      // Fetch literacy observations when the modal is shown and currentStudentId is available
      getJiSiReportByStudentId(currentStudentId)
        .then((response) => {
          if (response && response.readingscorenotes) {
            setReadingScoreNotes(response.readingscorenotes);
            setSelectedOptions(response.readingscorenotes); // Pre-select the option if available
          } else {
            setReadingScoreNotes([]);
            setSelectedOptions([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching literacy observations:", error);
          setReadingScoreNotes([]);
          setSelectedOptions([]);
        });
    }
  }, [show, currentStudentId, setReadingScoreNotes]);

  const handleOptionClick = (text) => {
    const updatedOptions = selectedOptions.includes(text)
      ? selectedOptions.filter((option) => option !== text)
      : [...selectedOptions, text];
    setSelectedOptions(updatedOptions);
    setReadingScoreNotes(updatedOptions);
    console.log(`Selected options for ${studentName}:`, updatedOptions);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
    setSaveClicked(true);
  };

  const handleModalClose = () => {
    setSelectedOptions([]);
    handleClose();
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      readingscorenotes: readingScoreNotes,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      upsertJiSiReport(reportData)
        .then((response) => {
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Observations about reading score
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="options-container">
          {options.map((option) => (
            <div
              key={option.text}
              className={`option ${option.color} ${
                (selectedOptions || []).includes(option.text) ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.text)}
            >
              {option.text}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="primary"
          onClick={saveChanges}
          className="modal-button yes-button"
        >
          Confirm
        </button>
        <button
          variant="secondary"
          onClick={handleModalClose}
          className="modal-button no-button"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReadingScoreNotesModal;
