import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import "./AddStudentsFunction.css";

function AddStudentsFunction(props) {
  const [studentNameString, setStudentNameString] = useState("");
  const [students, setStudents] = useState([]);
  const [showVideo, setShowVideo] = useState(false); // State to toggle video visibility

  const commaSpace = ", ";
  const comma = ",";
  const linebreak = "\n";

  function inputInterpreter() {
    if (studentNameString.includes(linebreak)) {
      let studentArr = studentNameString.split(linebreak);
      let newStudents = [...students, ...studentArr];
      setStudents(newStudents);
    } else if (studentNameString.includes(comma)) {
      let studentArr = studentNameString.split(comma);
      let newStudents = [...students, ...studentArr];
      setStudents(newStudents);
    } else if (studentNameString.includes(commaSpace)) {
      let studentArr = studentNameString.split(commaSpace);
      let newStudents = [...students, ...studentArr];
      setStudents(newStudents);
    } else {
      let newStudents = [...students, studentNameString];
      setStudents(newStudents);
    }
  }

  function clearTextarea() {
    document.getElementById("student-textarea").value = "";
  }

  function extractData(sheetData) {
    const studentNames = [];
    let nameStarts = false;
    sheetData.forEach((element) => {
      element.forEach((rowString) => {
        if (rowString.includes("function")) {
          nameStarts = false;
        } else if (nameStarts) {
          const studentName = rowString
            .replace(/<style[^>]*>.*?<\/style>/gs, "") // non-greedy match for style tags
            .replace(/<script[^>]*>.*?<\/script>/gs, "") // non-greedy match for script tags
            .replace(/<[^>]+>/g, "") // remove HTML tags
            .replace(/[\r\n]+ +/g, "") // remove leading spaces and repeated CR/LF
            .trim();

          if (studentName) studentNames.push(studentName);
        }

        if (rowString.includes("Name")) {
          nameStarts = true;
        }
      });
    });
    console.log(studentNames);
    return studentNames;
  }

  useEffect(() => {
    let studentInput = document.getElementById("student-textarea");
    let addToListBtn = document.getElementById("student-add-button");

    addToListBtn.addEventListener("click", inputInterpreter);
    addToListBtn.addEventListener("click", clearTextarea);

    studentInput.addEventListener("keypress", function (e) {
      if (e.key === "Enter") {
        e.preventDefault();
        addToListBtn.click();
      }
    });

    if (studentInput.value === "") {
      addToListBtn.disabled = true;
    } else {
      addToListBtn.disabled = false;
    }
  }, [studentNameString]);

  useEffect(() => {
    if (students.length > 0) {
      let finalStudentArr = [];
      for (let student of students) {
        if (Array.isArray(student)) {
          finalStudentArr.push(...student);
        } else {
          finalStudentArr.push(student);
        }
      }

      let studentObjects = finalStudentArr.map((name) => ({ name }));
      let tempArray = [...props.studentData, ...studentObjects];

      props.sendStudentData(tempArray);
      setStudents([]);
      document.getElementById("student-add-button").disabled = true;
    }
  }, [students]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;

    if (
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileType === "application/vnd.ms-excel"
    ) {
      const reader = new FileReader();
      let sheetData = [];
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const xlsxStudents = extractData(sheetData);

        setStudents((prevStudents) => [...prevStudents, ...xlsxStudents]);
      };

      reader.readAsArrayBuffer(file);
    } else {
      Papa.parse(file, {
        complete: (results) => {
          const csvStudents = results.data
            .map((row) => {
              const [firstName, lastName] = row;
              return `${firstName || ""} ${lastName || ""}`.trim();
            })
            .filter((name) => name.trim() !== "");
          setStudents((prevStudents) => [...prevStudents, ...csvStudents]);
        },
      });
    }
  };

  return (
    <>
      <Row className="textarea-container flex-column">
        <h3 className="class-overview-table-header">New Student Names</h3>
        <Col>
          <InputGroup className="form-positioning">
            <Form.Control
              as="textarea"
              aria-label="With textarea"
              className="form-positioning"
              id="student-textarea"
              onChange={(e) => setStudentNameString(e.target.value)}
              minLength="1"
            />
            <Button variant="outline-primary" id="student-add-button">
              Add
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col className="csv-upload mt-3">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
               Upload CSV or XLSX with student names from Aladdin / Arbor /
              iSAMS or any school MIS system
            </Form.Label>
            <br></br>

            {/* Button to toggle video visibility */}
            <button
              className="white-button"
              onClick={() => setShowVideo((prev) => !prev)}
            >
              {showVideo ? "Hide Guide" : "Click here to watch a guide"}
            </button>
            {showVideo && (
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/v5F5Hj-q2b4?si=1e5dsf7Wpiy5hdm6"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            )}
            <br></br>

            <Form.Control
              type="file"
              accept=".csv, .xlsx"
              onChange={handleFileUpload}
              className="uploadFile"
              style={{ width: "50%" }}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default AddStudentsFunction;
