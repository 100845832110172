import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import "./ShareClassOverview.css";
import "./ReferFriendsOverview.css";
import SimpleNavigationButton from "./SimpleNavigationButton";
import { submitMultipleReferrals } from "../services/APIClient";
import { useNavigate } from "react-router-dom";

function ReferFriendsOverview(props) {
  const navigate = useNavigate();

  const [referrals, setReferrals] = useState([
    { email: "", firstName: "", lastName: "", emailError: "" },
    { email: "", firstName: "", lastName: "", emailError: "" },
    { email: "", firstName: "", lastName: "", emailError: "" },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [modalReferrals, setModalReferrals] = useState([]);
  const [isAllEmailsValid, setIsAllEmailsValid] = useState(false);

  const teacherid = props.teacherid;
  const teacherAFullName = props.name;
  const teacherEmail = props.teacherEmail;

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (index, field, value) => {
    const newReferrals = [...referrals];
    if (field === "email") {
      newReferrals[index][field] = value;
      newReferrals[index].emailError = isValidEmail(value)
        ? ""
        : "Invalid email address";
    } else {
      newReferrals[index][field] = value;
    }
    setReferrals(newReferrals);
    validateAllEmails(newReferrals);
  };

  const validateAllEmails = (referralsList) => {
    const allValid = referralsList.every(
      (referral) => isValidEmail(referral.email) || referral.email === ""
    );
    setIsAllEmailsValid(allValid);
  };

  const handleShow = () => {
    const filteredReferrals = referrals.filter(
      (ref) => ref.email && ref.firstName && ref.lastName
    );
    setModalReferrals(filteredReferrals);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {
    const teacherADetails = {
      fullName: teacherAFullName,
      email: teacherEmail,
    };

    const filteredReferrals = referrals.filter(
      (ref) => ref.email.trim() && ref.firstName.trim() && ref.lastName.trim()
    );
    setModalReferrals(filteredReferrals);

    if (filteredReferrals.length === 0) {
      console.error("No referrals to submit, please check the entries.");
      return;
    }

    console.log("Submitting these referrals:", filteredReferrals);

    try {
      const response = await submitMultipleReferrals(
        teacherid,
        filteredReferrals,
        teacherADetails
      );
      console.log("Submission successful", response);
      navigate("/masterdashboard");
      handleClose();
    } catch (error) {
      console.error("Submission failed", error);
    }
  };

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col>
            <h2 className="page-description-header">Refer A Friend!!</h2>
            <p>
              <strong>
                Got a friend at another school interested in ALPACA? Here's what
                to do!
              </strong>
            </p>
            <p>
              1. Enter the email address of your friend. This should be their
              school/work email address.
            </p>
            <p>
              2. Enter their First and Last name (so we know who the incredible
              person is that you would like to invite).
            </p>
            <p>
              3. Click Review Referrals and then Send. You can refer up to three
              at a time.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <div className="refer-container">
              {referrals.map((referral, index) => (
                <div className="refer-row" key={index}>
                  <img
                    src="../assets/imgs/referFriend.png"
                    alt="Refer a Friend"
                  />
                  <div className="email-container">
                    <input
                      type="email"
                      placeholder="Email (school address)"
                      value={referral.email}
                      onChange={(e) =>
                        handleInputChange(index, "email", e.target.value)
                      }
                      style={{
                        borderColor: referral.emailError ? "red" : "#0080b5",
                      }}
                    />
                    {referral.emailError && (
                      <div className="invalid-email">{referral.emailError}</div>
                    )}
                  </div>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={referral.firstName}
                    onChange={(e) =>
                      handleInputChange(index, "firstName", e.target.value)
                    }
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={referral.lastName}
                    onChange={(e) =>
                      handleInputChange(index, "lastName", e.target.value)
                    }
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="shareclass-button-placer">
            <SimpleNavigationButton
              path="/masterdashboard"
              label="Back"
              colour="white"
            />
            <button
              className="submit-invite-button green-button"
              onClick={handleShow}
              disabled={!isAllEmailsValid}
            >
              Review Referrals
            </button>
          </Col>
        </Row>
      </Container>

      {/* Modal to review referrals before submitting */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Review Referrals</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalReferrals.length > 0 ? (
            <>
              <p>Do these names and emails look right?</p>
              <p>
                Press <strong>Send</strong> when happy.
              </p>
              <p>
                If you want to go back and make changes, press{" "}
                <strong>Cancel</strong> and you can continue where you left off.
              </p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {modalReferrals.map((referral, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{referral.firstName}</td>
                      <td>{referral.lastName}</td>
                      <td>{referral.email}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <p>
              There are no colleagues to send. Please add some colleagues and
              try again.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="submit-invite-button white-button"
            onClick={handleClose}
          >
            Cancel
          </button>
          {modalReferrals.length > 0 && (
            <button
              className="submit-invite-button green-button"
              onClick={handleSubmit}
            >
              Send
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReferFriendsOverview;
