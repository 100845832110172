import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  getJiSiReportByStudentId,
  upsertJiSiReport,
} from "../services/APIClient";

const SetEalModal = ({
  show,
  handleClose,
  studentName,
  // eal,
  // setEal,
  // handleSaveChanges,
  currentStudentId,
  teacherid,
}) => {
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [eal, setEal] = useState(false);

  const handleYesClick = () => {
    setEal(true);
    setConfirmChanges(true);
  };

  const handleNoClick = () => {
    setEal(false);
    setConfirmChanges(true);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
    setConfirmChanges(false);
  };

  const handleModalClose = () => {
    handleClose();
    setConfirmChanges(false);
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      eal: eal,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      // setLastSavedStudentId(currentStudentId); // Update last saved student ID
      // resetStateVariables();
      upsertJiSiReport(reportData)
        .then((response) => {
          // Reset only if saving for a different student
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Set Student as EAL
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {confirmChanges ? (
          <p>Are you sure you want to save changes for {studentName}?</p>
        ) : (
          <p>Do you want to set {studentName} as EAL?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {confirmChanges ? (
          <div className="button-group">
            <button
              variant="primary"
              onClick={saveChanges}
              className="modal-button yes-button"
            >
              Confirm
            </button>
            <button
              variant="secondary"
              onClick={handleModalClose}
              className="modal-button no-button"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="button-group">
            <button
              // variant="secondary"
              onClick={handleYesClick}
              className="modal-button yes-button"
            >
              Yes
            </button>
            <button
              // variant="secondary"
              onClick={handleNoClick}
              className="modal-button no-button"
            >
              No
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SetEalModal;
