import React from "react";
import { Link } from "react-router-dom";

import { addStudents } from "../services/APIClient";

import Col from "react-bootstrap/Col";
// import button from 'react-bootstrap/button';

import "./SaveStudentsButton.css";

function SaveStudentsButton(props) {
  let studentDetails = props.studentDetails;
  let classDetails = props.classDetails;
  let classid = classDetails.id;
  let teachers = props.teachers;
  let schoolid = props.schoolid;

  let name = props.name;
  let gradeLevel = props.gradeLevel;
  console.log(studentDetails, "details");

  return (
    <Link
      to={`/class/detail`}
      state={{
        classDetails: { classDetails },
        teachers: { teachers },
        name: { name },
        gradeLevel: { gradeLevel },
        schoolid: { schoolid },
        fromAddStudents: true,
      }}
    >
      <button
        onClick={props.handleStudentSubmit}
        className="green-button"
        style={{
          border: "1px solid #16971b",
        }}
      >
        Save
      </button>
    </Link>
  );
}

// function SaveStudentsButton(props) {
//   let studentDetails = props.studentDetails;
//   let classDetails = props.classDetails;
//   let classid = classDetails.id;
//   let teachers = props.teachers;

//   let name = props.name;
//   let gradeLevel = props.gradeLevel;

//   return (
//     <button
//       onClick={props.handleStudentSubmit}
//       className="green-button"
//       style={{
//         border: "1px solid #16971b",
//       }}
//     >
//       Save
//     </button>
//   );
// }

export default SaveStudentsButton;
