import React from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function EditClassTeacherSpecialDetails({
  currentTeacherName,
  classOwnerName,
  teachers,
  setNewClassTeacherId,
}) {
  // Handle dropdown change
  const handleTeacherChange = (e) => {
    setNewClassTeacherId(e.target.value);
  };

  // Tooltip content
  const tooltip = (
    <Tooltip id="teacher-tooltip">
      If the new teacher isn't listed, please go to the "Invite Colleagues"
      section on the homepage and invite them before creating their class! If
      you have already invited them, please ensure they have created their
      account first before creating their classroom!
    </Tooltip>
  );

  // Determine the old class teacher to display
  const oldClassTeacherName = classOwnerName || currentTeacherName;

  return (
    <Form className="form">
      {/* Old Class Teacher Field */}
      <Form.Group as={Row} className="mb-3" controlId="old-class-teacher">
        <Form.Label className="label" column sm="4">
          <p>Old Class Teacher</p>
        </Form.Label>
        <Col sm="8">
          <Form.Control
            placeholder={oldClassTeacherName}
            size="md"
            type="text"
            disabled
          />
        </Col>
      </Form.Group>

      {/* New Class Teacher Field */}
      <Form.Group as={Row} className="mb-3" controlId="new-class-teacher">
        <Form.Label className="label" column sm="4">
          <p>New Class Teacher</p>
        </Form.Label>
        <Col sm="7">
          <Form.Control as="select" size="md" onChange={handleTeacherChange}>
            <option value="">Select a Teacher</option>
            {teachers.map((teacher) => (
              <option key={teacher.id} value={teacher.id}>
                {teacher.name}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col sm="1">
          {/* Tooltip Icon */}
          <OverlayTrigger placement="right" overlay={tooltip}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="fa-2x"
              style={{ cursor: "pointer", color: "red" }}
            />
          </OverlayTrigger>
        </Col>
      </Form.Group>
    </Form>
  );
}

export default EditClassTeacherSpecialDetails;
