import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import DeleteClassButton from "./DeleteClassButton";
import EditClassDetailsSpecial from "./EditClassDetailsSpecial";
import EditClassTeacherSpecialDetails from "./EditClassTeacherSpecialDetails";

import SaveClassDetailsSpecial from "./SaveClassDetailsSpecial";
import BackButton from "./BackButton";

import "./ClassOverview.css";

function ClassSettingsSpecialOverview(props) {
  let classDetails = props.classDetails.classDetails;
  let gradeLevel = props.gradeLevel;
  let name = props.name;
  let teachers = props.teachers.teachers;
  let teacherid = props.teacherid; // Assuming teacherid is passed as a prop
  let sharedWith = props.sharedWith;
  let qrCodeDetails = props.qrCodeDetails;
  let classid = props.classid;
  let schoolid = props.schoolid;

  // State for new class name and grade
  let [newClassName, setNewClassName] = useState(`24-${name}`);
  let [newGrade, setNewGrade] = useState("");
  let [classTeacherName, setClassTeacherName] = useState("");
  const [newClassTeacherId, setNewClassTeacherId] = useState(""); // New state for selected teacher ID
  const [isNameUpdated, setIsNameUpdated] = useState(false);

  useEffect(() => {
    // Find the teacher's name based on teacherid
    const teacher = teachers.find((teacher) => teacher.id === teacherid);
    if (teacher) {
      setClassTeacherName(teacher.name);
      console.log("Class Teacher Name:", teacher.name);
    } else {
      console.log("Teacher not found");
    }
  }, [teacherid, teachers]);

  // Function to update class name state
  const updateClassName = (name) => {
    setNewClassName(name);
  };

  // Function to update grade state
  const updateGrade = (grade) => {
    setNewGrade(grade);
  };

  // Inline styles for the text box
  const textBoxStyle = {
    border: "1px solid #ddd", // Light gray border
    borderRadius: "0.25rem", // Rounded corners
    padding: "1rem", // Padding
    marginBottom: "1rem", // Margin below
    fontSize: "18px", // Font size
    fontWeight: "bold", // Font weight
    textAlign: "center", // Center align text
    // color: "#333", // Dark text color
  };

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col>
            <h2 className="page-description-header">{name}</h2>
            <p>Edit the details of your class.</p>
          </Col>
          <Col>
            <Row>
              {/* <Col>
                <DeleteClassButton classDetails={classDetails} />
              </Col> */}
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="button-container">
          <Col className="col-7 vertical-border">
            <br />
            <Form className="form">
              <div style={textBoxStyle}>
                <p>
                  Please name classroom as it appears in your school MIS system
                  e.g Aladdin / Arbor / i-SAMS - this will assist with reporting
                  later.
                </p>
              </div>
              {/* Old Class Name Field */}
              <Form.Group as={Row} className="mb-3" controlId="old-class-name">
                <Form.Label className="label" column sm="4">
                  <p>Old Class Name</p>
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    placeholder={name}
                    size="md"
                    type="text"
                    disabled
                  />
                </Col>
              </Form.Group>

              {/* New Class Name Field */}
            </Form>

            {/* Flex container to hold the components side by side */}
            <div className="edit-details-container">
              <EditClassDetailsSpecial
                classDetails={classDetails}
                teachers={teachers}
                sharedWith={sharedWith}
                name={name}
                gradeLevel={gradeLevel}
                sendUpdateClassName={updateClassName}
                sendUpdateGrade={updateGrade}
                setIsNameUpdated={setIsNameUpdated}
                isNameUpdated={isNameUpdated}
              />
              <EditClassTeacherSpecialDetails
                currentTeacherName={classTeacherName}
                teachers={teachers}
                setNewClassTeacherId={setNewClassTeacherId}
              />
            </div>

            <Row className="button-placer">
              <Col className="col-6">
                <BackButton />
              </Col>
              <Col className="col-6">
                <SaveClassDetailsSpecial
                  newClassName={newClassName}
                  newGrade={newGrade}
                  classDetails={classDetails}
                  teachers={teachers}
                  newClassTeacherId={newClassTeacherId} // Pass the selected teacher ID
                  schoolid={schoolid}
                  isNameUpdated={isNameUpdated}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-5 right-side-container">
            {/* Optional: Uncomment if needed
            <h3>Students Login</h3>
            <Row>
              <Col className="col-6">
                <ViewLogins
                  name={name}
                  label="View/Print QR Codes"
                  gradeLevel={gradeLevel}
                  qrCodeDetails={qrCodeDetails}
                />
              </Col>
              <Col className="col-6">
                <PrintAllLoginsButton
                  name={name}
                  label="Print Class QR Codes"
                  qrCodeDetails={qrCodeDetails}
                />
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ClassSettingsSpecialOverview;
