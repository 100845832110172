import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./AdditionalNeedsModal.css";
import {
  getSencoReportByStudentId,
  upsertSencoReport,
} from "../services/APIClient";

const additionalNeedsOptions = [
  { text: "Autism", color: "blue" },
  { text: "Developmental Coordination Disorder (DCD)", color: "blue" },
  { text: "Speech and Language difficulty", color: "blue" },
  { text: "ADHD", color: "blue" },
  { text: "Dyslexia", color: "blue" },
];

const SencoAdditionalNeedsModal = ({
  show,
  handleClose,
  studentName,
  // sencoAdditionalNeeds,
  // setSencoAdditionalNeeds,
  // handleSaveChanges,
  currentStudentId,
  teacherid,
  callClassName,
  setName,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sencoAdditionalNeeds, setSencoAdditionalNeeds] = useState([]);

  useEffect(() => {
    if (show && currentStudentId) {
      getSencoReportByStudentId(currentStudentId)
        .then((response) => {
          if (response && response.additionalneeds !== undefined) {
            setSencoAdditionalNeeds(response.additionalneeds);
            setSelectedOptions(response.additionalneeds || []); // Pre-select the options if available
          } else {
            setSencoAdditionalNeeds([]);
            setSelectedOptions([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching literacy observations:", error);
          setSencoAdditionalNeeds([]);
          setSelectedOptions([]);
        });
    }
  }, [show, currentStudentId, setSencoAdditionalNeeds]);

  const handleOptionClick = (text) => {
    const updatedOptions = selectedOptions.includes(text)
      ? selectedOptions.filter((option) => option !== text)
      : [...selectedOptions, text];
    setSelectedOptions(updatedOptions);
    setSencoAdditionalNeeds(updatedOptions);
    console.log(`Selected options for ${studentName}:`, updatedOptions);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
  };

  const handleModalClose = () => {
    setSelectedOptions([]); // Reset selected options to an empty array
    handleClose();
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      additionalneeds: sencoAdditionalNeeds,
      setname: setName,
      classname: callClassName,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      // setLastSavedStudentId(currentStudentId); // Update last saved student ID
      // resetStateVariables();
      upsertSencoReport(reportData)
        .then((response) => {
          // Reset only if saving for a different student
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Additional Needs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="options-container">
          {additionalNeedsOptions.map((option) => (
            <div
              key={option.text}
              className={`option-additional ${option.color} ${
                (selectedOptions || []).includes(option.text) ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.text)}
            >
              {option.text}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="modal-button no-button" onClick={handleModalClose}>
          Cancel
        </button>
        <button className="modal-button yes-button" onClick={saveChanges}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SencoAdditionalNeedsModal;
