import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import "./ClassPersonnel.css";

function ClassPersonnel(props) {
  const { classDetails, teachers, sharedWith, classOwnerId } = props;

  // Find the class creator and owner
  const classCreator = teachers?.find(
    (teacher) => teacher.id === classDetails.teacherId
  );
  const classOwner = teachers?.find((teacher) => teacher.id === classOwnerId);

  // Create an array with the class creator and owner, avoiding duplicates
  let classPersonnelArray = [classCreator, classOwner].filter(
    (person, index, self) => person && self.indexOf(person) === index
  );

  // Extract the IDs from the sharedWith array
  const sharedWithIds = sharedWith?.map((t) => t.id) || [];

  // Find and add additional teachers based on sharedWith IDs
  const additionalTeachers = teachers?.filter((teacher) =>
    sharedWithIds.includes(teacher.id)
  );

  // Add these additional teachers to the classPersonnelArray, avoiding duplicates
  additionalTeachers.forEach((teacher) => {
    if (!classPersonnelArray.find((person) => person.id === teacher.id)) {
      classPersonnelArray.push(teacher);
    }
  });

  // Function to determine and return if the person is a creator or owner
  const getPosition = (person) => {
    if (person === classCreator && person === classOwner) {
      return "Class Creator, Class Owner"; // Case when the same person is both
    } else if (person === classCreator) {
      return "Class Creator";
    } else if (person === classOwner) {
      return "Class Owner";
    }
    return "";
  };

  classPersonnelArray.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <Row>
      <Col>
        <div>
          <h3 className="class-overview-table-header">
            Class Personnel ({classPersonnelArray.length})
          </h3>
          <div className="tableContainer-ClassPersonnel">
            <Table className="custom-table">
              <thead className="table-header">
                <tr>
                  <th>Name</th>
                  <th className="">Role(s)</th>
                  <th className="header-th"> </th>
                </tr>
              </thead>
              <tbody>
                {classPersonnelArray?.map((classPersonnel, index) => (
                  <tr key={index} className="table-row">
                    <td className="table-data">{classPersonnel?.name}</td>
                    <td className="table-data">
                      {classPersonnel?.additionalRoles?.toString()}
                    </td>
                    <td className="table-data">
                      {getPosition(classPersonnel)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ClassPersonnel;
