import React from "react";
import "./ScoreToggleButton.css";

function ScoreToggleButton(props) {
  function handleToggleChange(e) {
    let checked = e.target.checked;
    props.setScoreType(checked);
  }

  return (
    <>
      <div className="toggle-container">
        <h5 className="page-description-header">Toggle between Percentage and Percentile </h5>
        <label className="switch">
          <input
            type="checkbox"
            onChange={handleToggleChange}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
}

export default ScoreToggleButton;
