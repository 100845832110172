import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import GradeCard from "./GradeCard";

import { getStudents } from "../services/APIClient";


import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import "./AlpacaInfoGesture.css";

function AlpacaInfoGesture(props) {

  // console.log(props)
  let speechText = props.speechText


  return (
    <Col className="alpaca-message">
      <img
        src="./assets/imgs/Alpaca-front-01.png"
        className="alpaca-message-icon"
      ></img>
      <div className="speech-bubble-text-controller">
        <img
          src="./assets/imgs/Speech-bubble-01.png"
          className="alpaca-message-bubble"
        ></img>
        <p className="speech-bubble-text">{speechText}</p>
      </div>
    </Col>
  );
}

export default AlpacaInfoGesture;
