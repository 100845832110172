import React from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// import button from 'react-bootstrap/button';

import "./AdditionalColleaguesButton.css";

function ReferFriendsButton(props) {

  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let teacherid = props.teacherid;
  let name = props.name;

  console.log(props)

  return (
    <div className="additional-colleague-button">
      <Link
        className="ass-heading-link "
        to={`/referfriends`}
        state={{
          rollNumber: { rollNumber },
          teacherEmail: { teacherEmail },
          teacherid: { teacherid },
          name: { name },
        }}
      >
        <img
          src="../assets/imgs/referFriend.png"
          className="assessment-reminder-card-img"
          alt="Colleague Icon"
        />
        <p className="class-card-text">Refer-A-Friend</p>
        <img
          src="../assets/imgs/referFriend.png"
          className="assessment-reminder-card-img"
          alt="Colleague Icon"
        />
        {/* <img
          src="../assets/imgs/spring-icon.png"
          className="assessment-reminder-card-img"
          alt="Colleague Icon"
        /> */}
      </Link>
      {/* <div className="additional-colleague-content">
        <img
          src="../assets/imgs/mycolleagues-icon.png"
          className="additional-colleague-card-img"
          alt="Colleague Icon"
        />
      </div> */}
  </div >
  );
}

export default ReferFriendsButton;
