import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the data labels plugin

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels); // Register the plugin

const StudentPerformancePrintViewScore = ({
  assessmentResultsByStudent,
  thresholds,
}) => {
  const getUniqueTasks = () => {
    const tasks = new Set();
    assessmentResultsByStudent.forEach((student) => {
      student.tasks.forEach((task) => tasks.add(task.name));
    });
    return Array.from(tasks);
  };

  const availableTasks = getUniqueTasks();

  const getTaskData = () => {
    const labels = [];
    const data = [];
    const backgroundColors = [];
    const borderColors = [];

    availableTasks.forEach((taskName, index) => {
      const threshold = thresholds[taskName]?.student;

      if (threshold !== undefined) {
        const studentsBelowThresholdForTask =
          assessmentResultsByStudent.flatMap((student) =>
            student.tasks
              .filter(
                (task) => task.name === taskName && task.score * 100 < threshold
              )
              .map((task) => ({
                ...student.student,
                score: task.score * 100,
              }))
          );

        const percentageBelowThreshold =
          (studentsBelowThresholdForTask.length /
            assessmentResultsByStudent.length) *
          100;

        labels.push(taskName);
        data.push(
          percentageBelowThreshold > 0 ? percentageBelowThreshold : null
        );
        backgroundColors.push(`hsl(${index * 60}, 70%, 70%)`);
        borderColors.push(`hsl(${index * 60}, 70%, 50%)`);
      }
    });

    return {
      labels,
      datasets: [
        {
          data: data,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };
  };

  const data = getTaskData();

  const renderStudentsForAllTasks = () => {
    return availableTasks.map((taskName, index) => {
      const threshold = thresholds[taskName]?.student;

      if (threshold !== undefined) {
        const studentsBelowThresholdForTask =
          assessmentResultsByStudent.flatMap((student) =>
            student.tasks
              .filter(
                (task) => task.name === taskName && task.score * 100 < threshold
              )
              .map((task) => ({
                ...student.student,
                score: task.score * 100,
              }))
          );

        // Sort students by score (lowest to highest)
        studentsBelowThresholdForTask.sort((a, b) => a.score - b.score);

        return (
          <div key={index} style={{ marginBottom: "20px" }}>
            <h5>Students who may require extra help with {taskName}:</h5>
            <ul>
              {studentsBelowThresholdForTask.length > 0 ? (
                studentsBelowThresholdForTask.map((student, idx) => (
                  <li key={idx} style={{ listStyleType: "none" }}>
                    {student.name} (Score: {student.score.toFixed(2)}%)
                  </li>
                ))
              ) : (
                <li style={{ listStyleType: "none" }}>
                  No students below the threshold for this task.
                </li>
              )}
            </ul>
          </div>
        );
      }
      return null;
    });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          filter: (legendItem) => {
            return true; // Show all tasks in the legend, even if they have no data
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${
              context.raw ? context.raw.toFixed(2) + "%" : "No data"
            }`;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Background color of the tooltip
        bodyColor: "white", // Text color
        titleColor: "white", // Title text color
        borderColor: "white", // Border color of the tooltip
        borderWidth: 1, // Border width
        font: {
          size: 12, // Font size for the tooltip text
        },
      },
      datalabels: {
        color: "white", // Text color for data labels inside slices
        font: {
          size: 12, // Font size
          weight: "bold", // Font weight
        },
        formatter: (value) => {
          return value ? value.toFixed(2) + "%" : ""; // Display percentage with 2 decimal places
        },
        anchor: "center",
        align: "center",
        padding: 5, // Padding around text
      },
    },
  };

  const chartStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%", // Increase the width as needed
    maxWidth: "800px", // Optional: Set a maximum width
    height: "500px", // Set the height as needed
    margin: "0 auto",
  };

  return (
    <div>
      <h5 style={{ textAlign: "center" }}>
        Students Flagged for Low Performance by Competency (Print View -
        Alphabetical Order)
      </h5>
      <div className="chart-container" style={chartStyle}>
        <Pie data={data} options={options} />
      </div>
      <hr />
      <div style={{ padding: "20px" }}>{renderStudentsForAllTasks()}</div>
    </div>
  );
};

export default StudentPerformancePrintViewScore;
