import React, { useRef, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactToPrint from "react-to-print";
import ExpandedSencoReportView from "./ExpandedSencoReportView";
import "./PrintClassResults.css";

function PrintSencoReports(props) {
  const {
    classDetails,
    classAssessments,
    gradeLevel,
    teachers,
    assessment,
    students,
    studentsReadingScore,
    showAtRiskOnly,
    onSortData,
    showSortedData
  } = props;

  console.log("showSortedData", showSortedData)

  const componentRef = useRef();

  return (
    <>
      <SencoReportPrintable
        ref={componentRef}
        classDetails={classDetails}
        gradeLevel={gradeLevel}
        teachers={teachers}
        assessment={assessment}
        students={students}
        studentsReadingScore={studentsReadingScore}
        showAtRiskOnly={showAtRiskOnly}
        onSortData={onSortData}
        showSortedData={showSortedData}
      />
      <ReactToPrint
        content={() => componentRef.current}
        trigger={() => <button className="blue-button">Print</button>}
      />
    </>
  );
}

const SencoReportPrintable = React.forwardRef((props, ref) => {  
  return (
    <div className="senco-hide-div senco-landscape-print" ref={ref}>
      <Row className="mt-5 text-center">
        <Col className="printout-logo col-2" ></Col>
        <Col className="col-4"><h3 className="printout-page-description-header">{props.classDetails.name}</h3></Col>
        <Col className="col-4"><h3 className="printout-page-description-header">{props.assessment.name}</h3></Col>
        <Col className="col-2"><h3 className="printout-page-description-header">{new Date().toLocaleString("default", { month: "long" })} {new Date().getFullYear()}</h3></Col>
      </Row>
      <Row>
        <ExpandedSencoReportView
          students={props.students}
          classDetails={props.classDetails}
          classid={props.classDetails.id}
          gradeLevel={props.gradeLevel}
          teachers={props.teachers}
          assessment={props.assessment}
          studentsReadingScore={props.studentsReadingScore}
          showSortedData={props.showSortedData}
          showAtRiskOnly={props.showAtRiskOnly}
          onSortData={props.onSortData}
        />
      </Row>
    </div>
  );
});

export default PrintSencoReports;
