import React, { useEffect, useState } from "react";
import {
  whoami,
  getTeachers,
  getClasses,
  getSchools,
} from "../services/APIClient";
import HeadingBanner from "../components/HeadingBanner";
import SandboxCard from "../components/SandboxCard";
import FolderCard from "../components/FolderCard";
import LeftPanel from "../components/LeftPanel";
import { Container, Row, Col } from "react-bootstrap";
import "../components/ClassCard.css";
import "../components/MasterDashCards.css";
import "../components/SandBox.css";
import {
  sandboxcontent,
  additionalResourcesESL,
} from "../components/sandboxData";
import {
  sandboxcontentUK,
  additionalResourcesESLUK,
} from "../components/sandboxDataUK";
import OrientationPrompt from "../components/OrientationPrompt";

import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";

function Sandbox() {
  const [schoolid, setSchoolid] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedAdditionalFolder, setSelectedAdditionalFolder] =
    useState(null);
  const [displayFolders, setDisplayFolders] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [showAdditionalFolderContents, setShowAdditionalFolderContents] =
    useState(false);
  const [
    showAdditionalFolderContentInMainRow,
    setShowAdditionalFolderContentInMainRow,
  ] = useState(false);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const englandId = "74bb2897-dbbe-41e3-b547-caee866545cf";

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    whoami().then((response) => {
      setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
    });
  }, []);

  useEffect(() => {
    getTeachers(schoolid).then((response) => {
      setTeachers(response);
    });
  }, [schoolid]);

  useEffect(() => {
    getSchools()
      .then((response) => {
        const matchingSchool = response.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const countryid = matchingSchool.countryid;

          setCountryId(countryid); // Set country ID
        }
      })
      .catch((error) => {
        console.error("Error fetching schools:", error);
      });
  }, [schoolid]);

  const toggleDisplay = () => {
    setSelectedFolder(null);
    setSelectedAdditionalFolder(null);
    setShowAdditionalFolderContentInMainRow(false);
    setDisplayFolders(!displayFolders);
    setShowButton(!displayFolders);
  };

  const hideButton = () => {
    setShowButton(false);
  };

  const handleFolderClick = (folderIndex, isAdditionalResources) => {
    if (isAdditionalResources) {
      setSelectedAdditionalFolder(
        selectedAdditionalFolder === folderIndex ? null : folderIndex
      );
      setShowAdditionalFolderContentInMainRow(true); // Show additional folder contents in main row
    } else {
      setSelectedFolder(selectedFolder === folderIndex ? null : folderIndex);
      setSelectedAdditionalFolder(null);
      setShowAdditionalFolderContentInMainRow(false); // Hide additional folder contents in main row
    }
    setDisplayFolders(false);
    setShowButton(true);
  };

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  // Determine which content to display based on the countryId
  const selectedSandboxContent =
    countryId === englandId ? sandboxcontentUK : sandboxcontent;
  const selectedAdditionalResources =
    countryId === englandId ? additionalResourcesESLUK : additionalResourcesESL;

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={name + "'s" + " " + "Sandbox"}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
        />

        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            <Row className="sandbox-row-position">
              {showButton && (
                <Button
                  className="sandbox-button"
                  onClick={() => {
                    toggleDisplay();
                    hideButton();
                  }}
                >
                  Back
                </Button>
              )}
            </Row>
            <Row className="sandbox-row-position">
              {displayFolders
                ? selectedSandboxContent.map((folder, folderIndex) => (
                    <FolderCard
                      key={folderIndex}
                      folderName={folder.folderName}
                      onClick={() => handleFolderClick(folderIndex, false)}
                    />
                  ))
                : null}
              {selectedFolder !== null &&
                selectedSandboxContent[selectedFolder] &&
                selectedSandboxContent[selectedFolder].cards.map(
                  (card, cardIndex) => (
                    <SandboxCard
                      key={cardIndex}
                      title={card.title}
                      iframe={card.iframe}
                      documentUrl={card.documentUrl}
                    />
                  )
                )}
            </Row>
            {showAdditionalFolderContentInMainRow && (
              <Row className="additional-row-position custom-no-padding-top">
                {selectedAdditionalFolder !== null &&
                  selectedAdditionalResources[selectedAdditionalFolder] &&
                  selectedAdditionalResources[
                    selectedAdditionalFolder
                  ].cards.map((card, cardIndex) => (
                    <SandboxCard
                      key={cardIndex}
                      title={card.title}
                      iframe={card.iframe}
                      documentUrl={card.documentUrl}
                    />
                  ))}
              </Row>
            )}
            <div className="additional-resources-container">
              {displayFolders
                ? selectedAdditionalResources.map((folder, folderIndex) => (
                    <FolderCard
                      key={folderIndex}
                      folderName={folder.folderName}
                      onClick={() => handleFolderClick(folderIndex, true)}
                      isSmaller={true}
                    />
                  ))
                : null}
              {selectedAdditionalFolder !== null &&
                !showAdditionalFolderContentInMainRow &&
                selectedAdditionalResources[selectedAdditionalFolder] &&
                selectedAdditionalResources[selectedAdditionalFolder].cards.map(
                  (card, cardIndex) => (
                    <SandboxCard
                      key={cardIndex}
                      title={card.title}
                      iframe={card.iframe}
                      documentUrl={card.documentUrl}
                    />
                  )
                )}
            </div>
          </Col>
        </Row>
        {isPortrait && <OrientationPrompt />}
      </Container>
    </div>
  );
}

export default Sandbox;
