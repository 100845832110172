import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ReactToPrint from "react-to-print";
import AllResultsHistory from "./AllResultsHistory";
import "./PrintStudentResults.css";

function PrintAllStudentResults(props) {
  const {
    individualStudentResult,
    assessmentResultsByStudent,
    student,
    classDetails,
    detailedView,
    buttonName,
    studentResults,
    students,
    schoolid,
    teacherid,
    toggleScoreType,
  } = props;

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const currentYear = currentDate.getFullYear();

  const componentRef = useRef();

  return (
    <>
      <StudentPrintable
        individualStudentResult={individualStudentResult}
        assessmentResultsByStudent={assessmentResultsByStudent}
        student={student}
        classDetails={classDetails}
        currentDay={currentDay}
        currentMonth={currentMonth}
        currentYear={currentYear}
        detailedView={detailedView}
        ref={componentRef}
        studentResults={studentResults}
        toggleScoreType={toggleScoreType}
        isPrintOut={true}
        teacherid={teacherid}
        schoolid={schoolid}
        students={students}
      />

      <ReactToPrint
        content={() => componentRef.current}
        trigger={() => (
          <button className="blue-button" style={{ borderRadius: "8px" }}>
            Print Detailed
          </button>
        )}
      />
    </>
  );
}

const StudentPrintable = React.forwardRef((props, ref) => (
  <div className="hide-div landscape-print">
    <div ref={ref}>
      <Row className="mt-5 text-center">
        <Col className="printout-logo col-2"></Col>
        <Col className="col-4">
          <h3 className="printout-page-description-header">
            {/* {props.student.name} */}
          </h3>
        </Col>
        <Col className="col-4">
          <h3 className="printout-page-description-header">
            {props.classDetails.name}
          </h3>
        </Col>
        <Col className="col-2">
          <h3 className="printout-page-description-header">
            {props.currentMonth} {props.currentYear}
          </h3>
        </Col>
      </Row>
      <Row>
        <AllResultsHistory
          studentResults={props.studentResults}
          toggleScoreType={props.toggleScoreType}
          assessmentResultsByStudent={props.assessmentResultsByStudent}
          isPrintOut={true} // Changed to true for print
          detailedView={true}
          teacherid={props.teacherid}
          schoolid={props.schoolid}
          students={props.students}
        />
      </Row>
    </div>
  </div>
));

export default PrintAllStudentResults;
