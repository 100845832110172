import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./AdditionalNeedsModal.css";
import {
  getJiSiReportByStudentId,
  upsertJiSiReport,
} from "../services/APIClient";

const additionalNeedsOptions = [
  { text: "Autism", color: "blue" },
  { text: "Developmental Coordination Disorder (DCD)", color: "blue" },
  { text: "Speech and Language difficulty", color: "blue" },
  { text: "ADHD", color: "blue" },
  { text: "Dyslexia", color: "blue" },
];

const AdditionalNeedsModal = ({
  show,
  handleClose,
  studentName,
  currentStudentId,
  teacherid,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [additionalNeeds, setAdditionalNeeds] = useState([]);

  useEffect(() => {
    if (show && currentStudentId) {
      // Fetch additional needs when the modal is shown and currentStudentId is available
      getJiSiReportByStudentId(currentStudentId)
        .then((response) => {
          if (response && response.additionalneeds) {
            setAdditionalNeeds(response.additionalneeds);
            setSelectedOptions(response.additionalneeds); // Pre-select the options if available
          } else {
            setAdditionalNeeds([]);
            setSelectedOptions([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching additional needs:", error);
          setAdditionalNeeds([]);
          setSelectedOptions([]);
        });
    }
  }, [show, currentStudentId, setAdditionalNeeds]);

  const handleOptionClick = (text) => {
    const updatedOptions = selectedOptions.includes(text)
      ? selectedOptions.filter((option) => option !== text)
      : [...selectedOptions, text];
    setSelectedOptions(updatedOptions);
    setAdditionalNeeds(updatedOptions);
    console.log(`Selected options for ${studentName}:`, updatedOptions);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
  };

  const handleModalClose = () => {
    setSelectedOptions([]); // Reset selected options
    handleClose();
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      additionalneeds: additionalNeeds,
    };

    if (currentStudentId) {
      upsertJiSiReport(reportData)
        .then((response) => {
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Additional Needs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="options-container">
          {additionalNeedsOptions.map((option) => (
            <div
              key={option.text}
              className={`option-additional ${option.color} ${
                (selectedOptions || []).includes(option.text) ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.text)}
            >
              {option.text}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="primary"
          onClick={saveChanges}
          className="modal-button yes-button"
        >
          Confirm
        </button>
        <button
          variant="secondary"
          onClick={handleModalClose}
          className="modal-button no-button"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdditionalNeedsModal;
