import React from "react";
import "./StudentPerformanceExplanationTable.css";

const StudentPermanceExplanationTable = () => {
  const tableData = [
    {
      title: "Junior Infants Baseline",
      rows: [
        {
          category: "Rhyming",
          teamTeaching: "≥25% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Initial Phoneme Matching",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Phoneme Blending",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Letter Name Knowledge",
          teamTeaching: "≥15% of class getting less than 20% correct",
          individualTeaching: "<5% of class getting 20% correct",
        },
        {
          category: "Phoneme Deletion",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
      ],
    },
    {
      title: "Junior Infants Midpoint",
      rows: [
        {
          category: "Initial Phoneme Matching",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Phoneme Blending",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Letter Name Knowledge",
          teamTeaching: "≥15% of class getting less than 20% correct",
          individualTeaching: "<5% of class getting 20% correct",
        },
        {
          category: "Letter Sound Knowledge",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Phoneme Deletion",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
      ],
    },
    {
      title: "Junior Infants End of Year",
      rows: [
        {
          category: "Initial Phoneme Matching",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Letter Sound Knowledge",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Word Recognition",
          teamTeaching: "≥15% of class getting less than 10% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Phoneme Deletion",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Non-Word Recognition",
          teamTeaching: "≥15% of class getting less than 10% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
      ],
    },
    {
      title: "Senior Infants Baseline",
      rows: [
        {
          category: "Initial Phoneme Matching",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Letter Sound Knowledge",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Word Recognition",
          teamTeaching: "≥15% of class getting less than 10% correct",
          individualTeaching: "<5% of class getting 10% correct",
        },
        {
          category: "Phoneme Deletion",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Non-Word Recognition",
          teamTeaching: "≥15% of class getting less than 10% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
      ],
    },
    {
      title: "Senior Infants Midpoint",
      rows: [
        {
          category: "Letter Sound Knowledge",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Phoneme Manipulation",
          teamTeaching: "≥25% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Word Reading/Non-Word Reading",
          teamTeaching: "≥15% of class getting less than 10% correct",
          individualTeaching: "<5% of class getting 10% correct",
        },
        {
          category: "Spelling",
          teamTeaching: "≥25% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category:
            "Listening and Reading Comprehension and Expressive Vocabulary",
          teamTeaching: "≥25% of class getting less than 20% correct",
          individualTeaching: "<15% of class getting 20% correct",
        },
      ],
    },
    {
      title: "Senior Infants End of Year",
      rows: [
        {
          category: "Letter Sound Knowledge",
          teamTeaching: "≥20% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Phoneme Manipulation",
          teamTeaching: "≥25% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category: "Word Reading/Non-Word Reading",
          teamTeaching: "≥15% of class getting less than 10% correct",
          individualTeaching: "<5% of class getting 10% correct",
        },
        {
          category: "Spelling",
          teamTeaching: "≥25% of class getting less than 20% correct",
          individualTeaching: "<10% of class getting 20% correct",
        },
        {
          category:
            "Listening and Reading Comprehension and Expressive Vocabulary",
          teamTeaching: "≥25% of class getting less than 20% correct",
          individualTeaching: "<15% of class getting 20% correct",
        },
      ],
    },
  ];

  return (
    <div className="table-container">
      {tableData.map((section, index) => (
        <div key={index} className="section">
          <h3 style={{ textAlign: "center" }}>{section.title}</h3>
          <table className="performance-table">
            <thead>
              <tr>
                <th>Subtest</th>
                <th>Team Teaching in Groups</th>
                <th>Individual Teaching</th>
              </tr>
            </thead>
            <tbody>
              {section.rows.map((row, idx) => (
                <tr key={idx}>
                  <td>{row.category}</td>
                  <td>{row.teamTeaching}</td>
                  <td>{row.individualTeaching}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default StudentPermanceExplanationTable;
