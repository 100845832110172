import { React, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  CardGroup,
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import GradeCard from "../components/GradeCard";
import OrientationPrompt from "../components/OrientationPrompt";

import { getAllTaskRecommendations } from "../services/APIClient";

import "../components/Recommendations.css";

function Recommendations({ gradeLevel = "Placeholder" }) {
  const [expandedTask, _setExpandedTask] = useState(null);
  const setExpandedTask = (taskid) =>
    _setExpandedTask(taskid === expandedTask ? null : taskid);
  const [tasks, setTasks] = useState(null);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const rec = getAllTaskRecommendations();

    setTasks(rec.sort((a, b) => a.id - b.id));
  }, []);

  const recommendationCard = (level, url) => {
    // console.log( url );
    return (
      <Card index={level}>
        <Card.Header>{level} Results</Card.Header>
        <Card.Body>
          <a href={url}>
            <img src="/assets/imgs/pdf.jpg" alt="View recommendation" />
          </a>
          <Card.Link href={url}>Link</Card.Link>
        </Card.Body>
      </Card>
    );
  };

  // console.log(tasks)

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={"Recommendations"}
        isLoggedIn={true}
        cloud={true}
        // teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <div className="class-overview-container">
            <Container className="container-shape">
              <Row className="page-description-bar">
                <Col className="col-8">
                  <h2 className="page-description-header">Recommendations</h2>
                  <h6>
                    Read the General Considerations and then access the PDF
                    guides for each task.
                  </h6>
                </Col>
                <Col className="col-4">
                  <Row>
                    {/* <Col className="col-6">
                                            <GradeCard gradeLevel={gradeLevel} />
                                        </Col>  */}
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="col-12">
                  <p>
                    <b>General Considerations</b>
                  </p>
                  <p>
                    Explicit, systematic and intensive teaching is required
                    particularly for children who are scoring just above, at or
                    below the 20th percentile.
                  </p>
                  <br />
                  <p>
                    <b>Explicit teaching</b> - Teaching is direct, intentional,
                    interactive and multisensory
                  </p>
                  <p>
                    <b>Systematic teaching</b> - Content to be taught is
                    introducted in an ordered, logical and sequential manner
                  </p>
                  <p>
                    <b>Intensive teaching</b> - Repetition and reinforcement of
                    content occurs very regularly
                  </p>
                  <br />
                  <p>
                    <b>Screening Considerations</b> - To find out more about why
                    it is important to screen for early literacy skills, see our
                    guidance document below:
                  </p>
                  <tbody className="recommendationsBody">
                    <tr>
                      <td>
                        <img
                          src="/assets/imgs/pdf.jpg"
                          alt="View recommendation"
                        />
                        <a
                          href="./docs/ALPACA_Guide_to_Screening.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Guide to Screening Early Literacy Skills
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <br />
                  <p>
                    Download the PDFs below to see specific recommendations for
                    each task
                  </p>
                </Col>
              </Row>
              <Row>
                <Accordion
                  defaultActiveKey={["0", "1", "2", "3", "4", "5", "6"]}
                  alwaysOpen={true}
                >
                  {tasks?.map((t, index) => (
                    <>
                      <Accordion.Item
                        eventKey={index.toString()}
                        style={{ border: "white" }}
                      >
                        <Accordion.Header>
                          {`Task ${t.id} - ${t.name}`}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            {!!t.description && (
                              <div className="description">{t.description}</div>
                            )}
                          </Row>
                          <Row>
                            {!!t.video && (
                              <div className="video">
                                <ReactPlayer controls url={t.video} />
                              </div>
                            )}
                          </Row>
                          <Row>
                            <CardGroup>
                              {!!t.files &&
                                // console.log( Object.keys(t.files) )
                                Object.keys(t.files).forEach((key) => {
                                  return (
                                    <Card>
                                      recommendationCard( key, t.files[key] );
                                    </Card>
                                  );
                                })}
                            </CardGroup>
                          </Row>
                          {!!t.files && (
                            <table className="files">
                              <thead>
                                <tr>
                                  {t.files.all && <td></td>}
                                  {t.files.low && (
                                    <td>
                                      <h4>Low Results</h4>
                                      <div className="interval low">
                                        0 to 25%
                                      </div>
                                    </td>
                                  )}
                                  {t.files.medium && (
                                    <td>
                                      <h4>Medium Results</h4>
                                      <div className="interval medium">
                                        25 to 75%
                                      </div>
                                    </td>
                                  )}
                                  {t.files.high && (
                                    <td>
                                      <h4>High Results</h4>
                                      <div className="interval high">
                                        25 to 100%
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              </thead>
                              <tbody className="recommendationsBody">
                                <tr>
                                  {t.files.all && (
                                    <td>
                                      <img
                                        src="/assets/imgs/pdf.jpg"
                                        alt="View recommendation"
                                      />
                                      <a href={t.files.all} target="_blank" rel="noreferrer">
                                        {t.name} Recommendations PDF
                                      </a>
                                    </td>
                                  )}
                                  {t.files.low && (
                                    <td>
                                      <img
                                        src="/assets/imgs/pdf.jpg"
                                        alt="View recommendation"
                                      />
                                      <br />
                                      <a href={t.files.low} target="_blank" rel="noreferrer">
                                        View Recommendation (PDF)
                                      </a>
                                    </td>
                                  )}
                                  {t.files.medium && (
                                    <td>
                                      <img
                                        src="/assets/imgs/pdf.jpg"
                                        alt="View recommendation"
                                      />
                                      <br />
                                      <a href={t.files.medium} target="_blank" rel="noreferrer">
                                        View Recommendation (PDF)
                                      </a>
                                    </td>
                                  )}
                                  {t.files.high && (
                                    <td>
                                      <img
                                        src="/assets/imgs/pdf.jpg"
                                        alt="View recommendation"
                                      />
                                      <br />
                                      <a href={t.files.high} target="_blank" rel="noreferrer">
                                        View Recommendation (PDF)
                                      </a>
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </>
                  ))}
                </Accordion>

                {/* { tasks?.map(t => <>
                                <div className="rec-header d-flex flex-row justify-content-between" onClick={() => setExpandedTask(t.id)}>
                                    <div className="text">{`Task ${t.id} - ${t.name}`}</div>
                                    <div className="expand d-flex flex-row">
                                        <div className="text-link">Recommendations</div>
                                        <div className="rec-circle">{expandedTask === t.id ? '-' : '+'}</div>
                                    </div>
                                </div>
                                { t.id === expandedTask && <div className='rec-body d-flex flex-column align-items-center'>
                                    { !!t.description && <div className="description">{t.description}</div>}
                                    { !!t.video && <div className="video"><ReactPlayer controls url={t.video} /></div>}
                                    { !!t.files && <table className="files">
                                        <thead>
                                            <tr>
                                                <td>
                                                    <h4>Low Results</h4>
                                                    <div className="interval low">0 to 25%</div>
                                                </td>
                                                <td>
                                                    <h4>Medium Results</h4>
                                                    <div className="interval medium">25 to 75%</div>
                                                </td>
                                                <td>
                                                    <h4>High Results</h4>
                                                    <div className="interval high">25 to 100%</div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img src="/assets/imgs/pdf.jpg" alt="View recommendation" /><br />
                                                    <a href={t.files.low} target='_blank'>View Recommendation (PDF)</a></td>
                                                <td>
                                                    <img src="/assets/imgs/pdf.jpg" alt="View recommendation" /><br />
                                                    <a href={t.files.medium} target='_blank'>View Recommendation (PDF)</a></td>
                                                <td>
                                                    <img src="/assets/imgs/pdf.jpg" alt="View recommendation" /><br />
                                                    <a href={t.files.high} target='_blank'>View Recommendation (PDF)</a></td>
                                            </tr>
                                        </tbody>
                                    </table>}
                                </div>}
                                </>)} */}
              </Row>
              <Row>
                <Col className="results-buttons">
                  <button
                    type="button"
                    className="float-right blue-button"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default Recommendations;
