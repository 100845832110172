import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";


import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import TeacherProfileForm from "../components/TeacherProfileForm";
import OrientationPrompt from "../components/OrientationPrompt";

function TeacherProfile(props) {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  // console.log(props)

  const location = useLocation();
  console.log(location)

  let profilePageRedirect = location.state.profilePageRedirect;


  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  let teacherid = props.teacherid;

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh()
  }, []);

  console.log(props.profilePageRedirect)


  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={"Teacher Profile"}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <TeacherProfileForm
          profilePageRedirect={profilePageRedirect}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default TeacherProfile;
