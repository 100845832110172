import React from "react";
import { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./ClassOverview.css";

import AssessmentStatus from "./AssessmentStatus";
import ShareClassButton from "./ShareClassButton";
import AddStudentsButton from "./AddStudentsButton";
import ClassPersonnel from "./ClassPersonnel";
import ClassStudents from "./ClassStudents";
import SimpleNavigationButton from "./SimpleNavigationButton";
import AssignAssessmentButton from "./AssignAssessmentButton";
import GradeCard from "./GradeCard";
import ViewLogins from "./ViewLogins";

import {
  getClassAssessments,
} from "../services/APIClient";

function AssessmentOverview(props) {
  // Class Personnel
  let classDetails = props.classDetails;
  let classid = classDetails.id;
  let gradeLevel = props.gradeLevel;
  let name = props.name;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let students = props.students;
  let qrCodeDetails = props.qrCodeDetails;

  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  const [classAssessments, setClassAssessments] = useState([]);

  const classAssessmentsValues = Object.values(classAssessments);

  // console.log(classAssessments);

  useEffect(() => {
    getClassAssessments(classid).then((response) => {
      setClassAssessments(response);
    });
  }, [classid]);

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-7">
            <Row className="inline-block">
              <Col className="col-6">
                <h2 className="page-description-header">Class Overview</h2>
              </Col>
              <Col className="col-3">
                <GradeCard 
                  gradeLevel={gradeLevel} 
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                  />
              </Col>
            </Row>
            {/* <p className="paragraph-font-size">
              See the class students, class personnel and what assessments are
              active in the class.
            </p> */}
          </Col>
          <Col className="col-1">
          </Col>
          <Col className="col-4">
            <Row>
              <Col className="col-4">
                {/* <RecommendationsButton 
                  onResultsPage={false}
                  /> */}
              </Col>
              <Col className="col-8">
                <ViewLogins
                  name={name}
                  label="View/Print QR Codes"
                  gradeLevel={gradeLevel}
                  qrCodeDetails={qrCodeDetails}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="col-6 vertical-border">
            <ClassStudents
              students={students}
              classDetails={classDetails}
              teachers={teachers}
              qrCodeDetails={qrCodeDetails}
            />
          </Col>
          <Col className="col-6 right-side-container">
            <AssessmentStatus
              classAssessmentsValues={classAssessmentsValues}
              classDetails={classDetails}
              teachers={teachers}
            />
            <br></br>
            <Col>
              <SimpleNavigationButton
                path="/assessmentsdashboard"
                label="Back"
                colour="white"
              />
              <AssignAssessmentButton
                students={students}
                classDetails={classDetails}
                teachers={teachers}
                sharedWith={sharedWith}
                name={name}
                gradeLevel={gradeLevel}
              />
          </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AssessmentOverview;
