import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AssessmentTable.css";

function TaskTable(props) {
  const { assessments, selectAssessmentId, setSelectAssessmentId, setSelectAssessmentName, classDetails } = props;

  const [activeTasks, setActiveTasks] = useState([]);
  const [sortedAssessments, setSortedAssessments] = useState([]);

  const gradeLevel1 = classDetails.grade_level1;
  const gradeLevel2 = classDetails.grade_level2;

  console.log(props)

  let taskOrder;

  let baselineTasks
  let midpointTasks
  let endOfYearTasks

  if (gradeLevel1){

    taskOrder = [
      "Rhyming",
      "Initial Phoneme",
      "Phoneme Blending",
      "Letter Name",
      "Letter Sound",
      "Word Recognition",
      "Deletion",
      "Nonword Recognition"
    ];

     baselineTasks = ["Rhyming", "Initial Phoneme", "Phoneme Blending", "Letter Name", "Deletion"];
     midpointTasks = ["Phoneme Blending", "Letter Name", "Initial Phoneme", "Letter Sound", "Deletion"];
     endOfYearTasks = ["Initial Phoneme", "Letter Sound", "Word Recognition", "Deletion", "Nonword Recognition"];
  } else {
    taskOrder = [
      "Alphabetic Knowledge (a)",
     "Phonological/Phonemic Awareness (a)",
     "Phonological/Phonemic Awareness (b)",
     "Spelling (a)",
     "Spelling (b)",
     "Word Reading (a)",
     "Word Reading (b)",
     "Listening & Reading Comprehension (a)",
     "Listening & Reading Comprehension (b)",
     "Listening & Reading Comprehension (c)",
     "Listening & Reading Comprehension (d)",
     ];

    baselineTasks = [
      "Alphabetic Knowledge (a)",
     "Phonological/Phonemic Awareness (a)",
     "Phonological/Phonemic Awareness (b)",
     "Spelling (a)",
     "Spelling (b)",
     "Word Reading (a)",
     "Word Reading (b)",
     "Listening & Reading Comprehension (a)",
     "Listening & Reading Comprehension (b)",
     "Listening & Reading Comprehension (c)",
     "Listening & Reading Comprehension (d)",
     ];
     midpointTasks = [
       "Alphabetic Knowledge (a)",
       "Phonological/Phonemic Awareness (a)",
       "Phonological/Phonemic Awareness (b)",
       "Spelling (a)",
       "Spelling (b)",
       "Word Reading (a)",
       "Word Reading (b)",
       "Listening & Reading Comprehension (a)",
       "Listening & Reading Comprehension (b)",
       "Listening & Reading Comprehension (c)",
       "Listening & Reading Comprehension (d)",
     ];
     endOfYearTasks = [
       "Alphabetic Knowledge (a)",
       "Phonological/Phonemic Awareness (a)",
       "Phonological/Phonemic Awareness (b)",
       "Spelling (a)",
       "Spelling (b)",
       "Word Reading (a)",
       "Word Reading (b)",
       "Listening & Reading Comprehension (a)",
       "Listening & Reading Comprehension (b)",
       "Listening & Reading Comprehension (c)",
       "Listening & Reading Comprehension (d)",
     ];
  }
  

  const allTasks = [
    ...new Set([...baselineTasks, ...midpointTasks, ...endOfYearTasks])
  ];

  useEffect(() => {
    setActiveTasks(allTasks);
  }, []);

  useEffect(() => {
    const updatedSortedAssessments = assessments
      .filter(assessment => 
        (gradeLevel1 && assessment.grade_level1) || 
        (gradeLevel2 && assessment.grade_level2)
      )
      .filter(assessment => activeTasks.includes(assessment.name))
      .sort((a, b) => taskOrder.indexOf(a.name) - taskOrder.indexOf(b.name));

    setSortedAssessments(updatedSortedAssessments);

    if (!selectAssessmentId && updatedSortedAssessments.length > 0) {
      const currentTask = updatedSortedAssessments[0];
      if (currentTask) {
        setSelectAssessmentId(currentTask.id);
        setSelectAssessmentName(currentTask.name);
      }
    }
  }, [activeTasks, assessments, gradeLevel1, gradeLevel2, selectAssessmentId, setSelectAssessmentId, setSelectAssessmentName]);

  return (
    <Row className="margin-left">
      <Col>
        <div>
          {sortedAssessments.map((assessment, index) => {
            const isDisabled = !activeTasks.includes(assessment.name);

            return (
              <button
                key={index}
                className={`light-blue-button assessment-button ${selectAssessmentId === assessment.id ? "selected" : ""} ${isDisabled ? "disabled" : ""}`}
                onClick={() => {
                  if (!isDisabled) {
                    setSelectAssessmentId(assessment.id);
                    setSelectAssessmentName(assessment.name);
                  }
                }}
              >
                <div className="button-task-content">
                  <span>{assessment.name}</span>
                  <img
                    src={`/assets/imgs/${assessment.name.split(" ").slice(0, 2).join("").toLowerCase()}-icon.png`}
                    className="task-icon"
                    alt={assessment.name}
                  />
                </div>
              </button>
            );
          })}
        </div>
      </Col>
    </Row>
  );
}

export default TaskTable;



// import React, { useState, useEffect } from "react";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import "./AssessmentTable.css";

// function TaskTable(props) {
//   const { assessments, selectAssessmentId, setSelectAssessmentId, setSelectAssessmentName, classDetails } = props;

//   const [activeTasks, setActiveTasks] = useState([]);
//   const [sortedAssessments, setSortedAssessments] = useState([]);

//   const gradeLevel1 = classDetails.grade_level1;
//   const gradeLevel2 = classDetails.grade_level2;
  
//   const taskOrder = [
//     "Rhyming",
//     "Initial Phoneme",
//     "Phoneme Blending",
//     "Letter Name",
//     "Letter Sound",
//     "Word Recognition",
//     "Deletion",
//     "Nonword Recognition"
//   ];

//   const baselineTasks = ["Rhyming", "Initial Phoneme", "Phoneme Blending", "Letter Name", "Deletion"];
//   const midpointTasks = ["Phoneme Blending", "Letter Name", "Initial Phoneme", "Letter Sound", "Deletion"];
//   const endOfYearTasks = ["Initial Phoneme", "Letter Sound", "Word Recognition", "Deletion", "Nonword Recognition"];

//   const getCurrentDate = () => new Date('Thu Jun 02 2025 12:44:13 GMT+0100 (Irish Standard Time)');
//   // const getCurrentDate = () => new Date();
//   const getDateWithoutYear = (date) => new Date(date.getMonth() + 1 + "/" + date.getDate());

//   const isDateInRange = (date, start, end) => {
//     const currentDate = getDateWithoutYear(date);
//     const startDate = getDateWithoutYear(new Date(start));
//     const endDate = getDateWithoutYear(new Date(end));

//     return currentDate >= startDate && currentDate <= endDate;
//   };

//   console.log("Current Date:", getCurrentDate());

//   const isBaselinePeriod = isDateInRange(getCurrentDate(), "09/01", "12/31");
//   const isMidpointPeriod = isDateInRange(getCurrentDate(), "01/01", "04/30");
//   const isEndOfYearPeriod = isDateInRange(getCurrentDate(), "05/01", "06/30");

//   useEffect(() => {
//     let newActiveTasks = [];
//     if (isBaselinePeriod) {
//       newActiveTasks = baselineTasks;
//     } else if (isMidpointPeriod) {
//       newActiveTasks = [...baselineTasks, ...midpointTasks.filter(task => !baselineTasks.includes(task))];
//     } else if (isEndOfYearPeriod) {
//       newActiveTasks = [
//         ...baselineTasks,
//         ...midpointTasks.filter(task => !baselineTasks.includes(task)),
//         ...endOfYearTasks.filter(task => !baselineTasks.includes(task) && !midpointTasks.includes(task))
//       ];
//     }
//     setActiveTasks(newActiveTasks);
//   }, [isBaselinePeriod, isMidpointPeriod, isEndOfYearPeriod]);

//   useEffect(() => {
//     const updatedSortedAssessments = assessments
//       .filter(assessment => 
//         (gradeLevel1 && assessment.grade_level1) || 
//         (gradeLevel2 && assessment.grade_level2)
//       )
//       .filter(assessment => activeTasks.includes(assessment.name))
//       .sort((a, b) => taskOrder.indexOf(a.name) - taskOrder.indexOf(b.name));

//     console.log('Active Tasks:', activeTasks);
//     console.log("updatedSortedAssessments:", updatedSortedAssessments)
//     setSortedAssessments(updatedSortedAssessments);

//     if (!selectAssessmentId && updatedSortedAssessments.length > 0) {
//       const currentTask = updatedSortedAssessments[0];
//       if (currentTask) {
//         setSelectAssessmentId(currentTask.id);
//         setSelectAssessmentName(currentTask.name);
//       }
//     }
//   }, [activeTasks, assessments, gradeLevel1, gradeLevel2, selectAssessmentId, setSelectAssessmentId, setSelectAssessmentName]);


//   console.log('Active Tasks:', activeTasks);
//   console.log('Sorted Assessments:', sortedAssessments);

//   return (
//     <Row className="margin-left">
//       <Col>
//         <div>
//           {assessments.map((assessment, index) => {
//             const isDisabled = !activeTasks.includes(assessment.name);

//             return (
//               <button
//                 key={index}
//                 className={`light-blue-button assessment-button ${selectAssessmentId === assessment.id ? "selected" : ""} ${isDisabled ? "disabled" : ""}`}
//                 onClick={() => {
//                   if (!isDisabled) {
//                     setSelectAssessmentId(assessment.id);
//                     setSelectAssessmentName(assessment.name);
//                   }
//                 }}
//               >
//                 <div className="button-task-content">
//                   <span>{assessment.name}</span>
//                   <img
//                     src={`/assets/imgs/${assessment.name.split(" ").slice(0, 2).join("").toLowerCase()}-icon.png`}
//                     className="task-icon"
//                     alt={assessment.name}
//                   />
//                 </div>
//               </button>
//             );
//           })}
//         </div>
//       </Col>
//     </Row>
//   );
// }

// export default TaskTable;




