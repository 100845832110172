import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import "./GradeCard.css";

function GradeCard(props) {
  // let classDetails = props.classDetails;
  let gradeLevel = props.gradeLevel;
  let gradeLevel1 = props.gradeLevel1;
  let gradeLevel2 = props.gradeLevel2;
  let trialClass = props.trialClass;
  console.log(props, "GRADE");

  return (
    <Row className="mt-2">
      {gradeLevel1 ? (
        <div className="grade1-card-detail">
          <p className="grade-text">{gradeLevel}</p>
        </div>
      ) : (
        <div className="grade2-card-detail">
          <p className="grade-text">{gradeLevel}</p>
        </div>
      )}
    </Row>
  );
}

export default GradeCard;
