import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { getClass, getStudents } from "../services/APIClient";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import ClassSettingsSpecialOverview from "../components/ClassSettingsSpecialOverview";
import OrientationPrompt from "../components/OrientationPrompt";

import { Container, Row, Col } from "react-bootstrap";

function ClassSettingsSpecial(props) {
  const location = useLocation();

  let name = "Class Settings";

  let classid = location.state.classDetails.classDetails.id;
  let teacherid = location.state.classDetails.classDetails.teacherId;
  let schoolid = location.state.schoolid;

  const [group, setGroup] = useState("");
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    getClass(classid).then((response) => {
      setGroup(response);
    });
  }, [classid]);

  let sharedWith = group.sharedWith;

  let [studentsResponse, setStudentsResponse] = useState("");

  useEffect(() => {
    getStudents(classid).then((response) => {
      setStudentsResponse(response);
    });
  }, [classid]);

  let students = studentsResponse.students;

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <ClassSettingsSpecialOverview
            classDetails={location.state.classDetails}
            teachers={location.state.teachers}
            sharedWith={sharedWith}
            teacherid={teacherid}
            gradeLevel={location.state.gradeLevel.gradeLevel}
            name={location.state.name.name}
            qrCodeDetails={location.state.qrCodeDetails.qrCodeDetails}
            classid={classid}
            schoolid={schoolid}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default ClassSettingsSpecial;
