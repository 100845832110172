import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import HeadingBanner from "../components/HeadingBanner";
import ClassCard from "../components/ClassCard";
import CreateClassCard from "../components/CreateClassCard";
import CreateTrialClassCard from "../components/CreateTrialClassCard";
import LeftPanel from "../components/LeftPanel";
import ClassFilter from "../components/ClassFilter";
import OrientationPrompt from "../components/OrientationPrompt";
import MyColleaguesOverview from "../components/MyColleaguesOverview";

import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
} from "../services/APIClient";

import "../components/ClassCard.css";

function MyColleagues() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [rollNumber, setRollNumber] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState(null);
  const [classes, setClasses] = useState([]);
  const [classid, setClassid] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [countryId, setCountryId] = useState([]);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    whoami().then((response) => {
      setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      setTeacherEmail(response.data.email);
    });
  }, []);

  useEffect(() => {
    // Fetch teachers, classes, and roll number when school ID changes
    async function fetchSchoolData() {
      try {
        const teachersResponse = await getTeachers(schoolid);
        setTeachers(teachersResponse);

        const classesResponse = await getClasses();
        classesResponse.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        );
        setClasses(classesResponse);

        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const rollNumber = matchingSchool.rollnumber;
          const countryid = matchingSchool.countryid;
          setCountryId(countryid)
          setRollNumber(rollNumber);

          getCountryGrades(countryid)
            .then((gradesResponse) => {
              setGradeLevelOptions([gradesResponse]);
            })
            .catch((error) => {
              console.error("Error fetching country grades:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    }

    if (schoolid) {
      fetchSchoolData().finally(() => {
        setLoading(false);
      });
    }
  }, [schoolid]);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={"My Colleagues"}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          {loading ? (
            <div className="spinner-container">
              <div className="big-ball-spinner"></div>
            </div>
          ) : (
            <>
              <div className="row-position">
                <MyColleaguesOverview
                  // classDetails={classDetails}
                  teachers={teachers}
                  schoolid={schoolid}
                  // sharedWith={group.sharedWith}
                  // students={studentsResponse.students}
                  // gradeLevel={gradeLevel}
                  name={name}
                  // qrCodeDetails={qrCodeDetails}
                  rollNumber={rollNumber}
                  teacherEmail={teacherEmail}
                  countryId={countryId}
                />
                {/* {classes.length > 0 && (
                    <ClassFilter
                      classes={classes}
                      setFilteredClasses={setFilteredClasses}
                      gradeLevelOptions={gradeLevelOptions}
                    />
                  )} */}
                {/* <CreateClassCard
                    schoolid={schoolid}
                    teacherid={teacherid}
                    name={name}
                    classes={classes}
                  />
                  <CreateTrialClassCard
                    schoolid={schoolid}
                    teacherid={teacherid}
                    name={name}
                    classes={classes}
                  /> */}
              </div>
            </>
          )}
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default MyColleagues;
