import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  getSencoReportByStudentId,
  upsertSencoReport,
} from "../services/APIClient";
import "./ClassroomSupportPlanModal.css"; // Import CSS file for styling

const ClassroomSupportPlanModal = ({
  show,
  handleClose,
  studentName,
  currentStudentId,
  teacherid,
  callClassName,
  setName,
}) => {
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [classroomSupportPlan, setClassroomSupportPlan] = useState(null);

  useEffect(() => {
    if (show && currentStudentId) {
      getSencoReportByStudentId(currentStudentId)
        .then((response) => {
          if (response && response.classroomsupportplan !== undefined) {
            setClassroomSupportPlan(response.classroomsupportplan);
          } else {
            setClassroomSupportPlan(null);
          }
        })
        .catch((error) => {
          console.error("Error fetching classroom support plan:", error);
          setClassroomSupportPlan(null);
        });
    }
  }, [show, currentStudentId]);

  const handleYesClick = () => {
    setClassroomSupportPlan(true);
    setConfirmChanges(true);
  };

  const handleNoClick = () => {
    setClassroomSupportPlan(false);
    setConfirmChanges(true);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
    setConfirmChanges(false);
  };

  const handleModalClose = () => {
    handleClose();
    setConfirmChanges(false);
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      classroomsupportplan: classroomSupportPlan,
      setname: setName,
      classname: callClassName,
    };

    if (currentStudentId) {
      upsertSencoReport(reportData)
        .then((response) => {
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Classroom Support Plan
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        {confirmChanges ? (
          <p>Are you sure you want to save changes for {studentName}?</p>
        ) : (
          <div className="button-group">
            <button
              onClick={handleYesClick}
              className={`modal-button no-button ${
                classroomSupportPlan === true ? "highlight" : ""
              }`}
            >
              Yes
            </button>
            <button
              onClick={handleNoClick}
              className={`modal-button no-button ${
                classroomSupportPlan === false ? "highlight" : ""
              }`}
            >
              No
            </button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {confirmChanges && (
          <div className="button-group">
            <button
              variant="primary"
              onClick={saveChanges}
              className="modal-button yes-button"
            >
              Confirm
            </button>
            <button
              variant="secondary"
              onClick={handleModalClose}
              className="modal-button no-button"
            >
              Cancel
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ClassroomSupportPlanModal;
