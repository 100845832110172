import React from 'react';
import { exportToExcel } from '../services/ExcelExporter';
import secondsToTime from '../services/secondsToTime';

function AssessmentExport({ schoolDetails, assessmentResultsByStudent, allAssessmentInstances, mergedData, assessmentInstanceMap }) {

    function sanitizeName(name) {
        return name
            .replace(/[:\\\/\?\*\[\]]/g, '')
            .slice(0, 31);
    }

    const filterDataByDate = (data) => {
        const cutoffDate = new Date('2023-09-01');
        return data.filter(item => {
            const itemDate = new Date(item.date || item.dateAssigned);
            return itemDate >= cutoffDate;
        });
    };

    // need to come up with a better way to order these in the exportbale - Cathal 01/02/2024
    const taskOrder = {
        "KG1 Baseline": ["Rhyming", "Initial Phoneme", "Phoneme Blending", "Letter Name", "Deletion"],
        "KG1 Midpoint": ["Phoneme Blending", "Letter Name", "Initial Phoneme", "Letter Sound", "Deletion"],
        "KG1 End of Year": ["Initial Phoneme", "Letter Sound", "Word Recognition", "Deletion", "Nonword Recognition"],
        "KG2 Baseline": ["Initial Phoneme", "Letter Sound", "Word Recognition", "Deletion", "Nonword Recognition"],
        "KG2 Midpoint": ["Initial Phoneme", "Letter Sound", "Word Recognition", "Deletion", "Nonword Recognition"],
    };

    // function transformDataForAssessmentExport(dataGroup, assessmentGrade) {
    //     const studentsData = [];
    //     const allTasks = new Set();
    //     const orderedTasks = taskOrder[assessmentGrade] || allTasks;

    //     // Collect task names for this specific data group (assessment)
    //     dataGroup.forEach(studentResult => {
    //         studentResult.tasks.forEach(task => {
    //             allTasks.add(task.name);
    //         });
    //     });

    //     const allTasksArray = Array.from(allTasks);

    //     dataGroup.forEach(studentResult => {
    //         const taskScores = studentResult.tasks.map(task => task.score);
    //         const readingScore = taskScores.reduce((a, b) => a + b, 0) / taskScores.length;
    //         const readingScoreFormatted = isNaN(readingScore)
    //             ? "Not Started"
    //             : `${(readingScore * 100).toFixed(2)}%`;

    //         const atRisk = readingScore <= 0.2 ? "Red Flag" : readingScore <= 0.4 ? "Yellow Flag" : "";
    //         const timeFormatted = isNaN(studentResult.time) ? "Not Started" : secondsToTime(studentResult.time);

    //         let studentEntry = {
    //             "Student Name": studentResult.student.name,
    //             "Reading Score": readingScoreFormatted,
    //             "At-Risk": atRisk,
    //         };

    //         orderedTasks.forEach(taskName => {
    //             const task = studentResult.tasks.find(t => t.name === taskName);
    //             studentEntry[taskName] = task ? `${(task.score * 100).toFixed(2)}%` : "Not Started";
    //         });

    //         studentEntry["Time"] = timeFormatted;
    //         studentEntry["Class"] = studentResult.className;

    //         studentsData.push(studentEntry);
    //     });

    //     studentsData.sort((a, b) => {
    //         // Compare class names first
    //         const classCompare = a["Class"].localeCompare(b["Class"]);
    //         if (classCompare !== 0) {
    //             return classCompare; 
    //         }

    //         // If in the same class, sort by reading score
    //         let readingScoreA = parseFloat(a["Reading Score"].replace('%', '')) || 0;
    //         let readingScoreB = parseFloat(b["Reading Score"].replace('%', '')) || 0;
    //         return readingScoreB - readingScoreA; 
    //     });

    //     return studentsData;
    // }

    function transformDataForAssessmentExport(dataGroup, assessmentName) {
        const studentsData = [];
        let orderedTasks;
    
        // Find the correct task order by checking if assessmentName includes any of the keys in taskOrder
        for (const [key, order] of Object.entries(taskOrder)) {
            console.log(assessmentName)
            if (assessmentName?.includes(key)) {
                orderedTasks = order;
                break;
            }
        }
    
        // Fallback to using tasks as they appear if no specific order is found
        if (!orderedTasks) {
            const allTasks = new Set();
            dataGroup.forEach(studentResult => {
                studentResult.tasks.forEach(task => {
                    allTasks.add(task.name);
                });
            });
            orderedTasks = Array.from(allTasks);
        } else {
            // Ensure that only tasks present in the data are included in the ordered list
            const allTasks = new Set(dataGroup.flatMap(studentResult => studentResult.tasks.map(task => task.name)));
            orderedTasks = orderedTasks.filter(task => allTasks.has(task));
        }
    
        // Process the data using 'orderedTasks' for ordering task columns
        dataGroup.forEach(studentResult => {
            const taskScores = studentResult.tasks.map(task => task.score);
            const readingScore = taskScores.reduce((a, b) => a + b, 0) / taskScores.length;
            const readingScoreFormatted = isNaN(readingScore) ? "Not Started" : `${(readingScore * 100).toFixed(2)}%`;
    
            const atRisk = readingScore <= 0.2 ? "Red Flag" : readingScore <= 0.4 ? "Yellow Flag" : "";
            const timeFormatted = isNaN(studentResult.time) ? "Not Started" : secondsToTime(studentResult.time);
    
            let studentEntry = {
                "Student Name": studentResult.student.name,
                "Reading Score": readingScoreFormatted,
                "At-Risk": atRisk,
            };
    
            orderedTasks.forEach(taskName => {
                const task = studentResult.tasks.find(t => t.name === taskName);
                studentEntry[taskName] = task ? `${(task.score * 100).toFixed(2)}%` : "Not Started";
            });
    
            studentEntry["Time"] = timeFormatted;
            studentEntry["Class"] = studentResult.className;
    
            studentsData.push(studentEntry);
        });
    
        // Sorting logic
        studentsData.sort((a, b) => {
            // Compare class names first
            const classCompare = a["Class"].localeCompare(b["Class"]);
            if (classCompare !== 0) {
                return classCompare; 
            }
    
            // If in the same class, sort by reading score
            let readingScoreA = parseFloat(a["Reading Score"].replace('%', '')) || 0;
            let readingScoreB = parseFloat(b["Reading Score"].replace('%', '')) || 0;
            return readingScoreB - readingScoreA; 
        });
    
        return studentsData;
    }
    

    const handleExportClickAssessment = () => {

        const filteredMergedData = filterDataByDate(mergedData);

        const groupedByAssessment = filteredMergedData.reduce((grouped, item) => {
            const assessmentName = sanitizeName(assessmentInstanceMap[item.assessmentInstance]?.name || "Unknown Assessment");
            grouped[assessmentName] = grouped[assessmentName] || [];
            grouped[assessmentName].push(item);
            return grouped;
        }, {});

        const sheetsData = Object.keys(groupedByAssessment).reduce((sheets, assessmentName) => {
            sheets[assessmentName] = transformDataForAssessmentExport(groupedByAssessment[assessmentName], assessmentName);
            return sheets;
        }, {});

        exportToExcel(sheetsData, `${schoolDetails.name} - Assessment view export`);
    };

    return (
        <div>
            {schoolDetails && (
                <div>
                    <button className="green-button" onClick={handleExportClickAssessment}>Export Data by Assessment</button>
                </div>
            )}
        </div>
    );
}

export default AssessmentExport;
