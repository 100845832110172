import React, { useState } from "react";
import { Container, Row, Col, ButtonGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./ClassOverview.css";

import GradeCard from "./GradeCard";
import StudentSettingsButton from "./StudentSettingsButton";
import ViewLogins from "./ViewLogins";
import PrintAllLoginsButton from "./PrintAllLoginsButton";
import ReturnToClassOverview from "./ReturnToClassOverview";
import StudentAssessmentHistory from "./StudentAssessmentHistory";
import ChangeClassModal from "./ChangeClassModal";

function StudentDetailsOverview(props) {
  const {
    classDetails,
    gradeLevel,
    teachers,
    teacherId,
    student,
    qrCodeDetails,
    students,
    onClassChange,
    schoolid,
  } = props;

  const oldClass = classDetails.id;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  const singleQR = qrCodeDetails.filter(
    (qrCodeDetail) => student.name === qrCodeDetail.name
  );

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleClassChange = (newClassDetails) => {
    onClassChange(newClassDetails);
    handleCloseModal();
    toast.success("Class changed successfully!");
  };

  return (
    <div className="class-overview-container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container className="container-shape">
        <Row className="page-description-bar d-flex justify-content-between align-items-center">
          <Col xs={12} md={6}>
            <h2 className="page-description-header">
              {classDetails.name} - {student.name}
            </h2>
            <p>Assessment history for individual student.</p>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-end align-items-center"
          >
            <div className="d-flex align-items-center">
              <GradeCard
                gradeLevel={gradeLevel}
                classDetails={classDetails}
                gradeLevel1={gradeLevel1}
                gradeLevel2={gradeLevel2}
                trialClass={trialClass}
              />
            </div>
            <ButtonGroup className="ms-3">
              <StudentSettingsButton
                classDetails={classDetails}
                student={student}
                schoolid={schoolid}
              />
              <button className="blue-button ms-2" onClick={handleOpenModal}>
                Change Class
              </button>
            </ButtonGroup>
          </Col>
        </Row>
        <hr />
        <Row className="button-container">
          <Col className="col-7 vertical-border">
            <StudentAssessmentHistory student={student} students={students} />
          </Col>
          <Col className="col-5 right-side-container">
            <h3>Student QR Code</h3>
            <br />
            <ViewLogins
              qrCodeDetails={singleQR}
              name={classDetails.name}
              label="View QR Code"
              gradeLevel={gradeLevel}
            />
            <PrintAllLoginsButton
              qrCodeDetails={singleQR}
              name={classDetails.name}
              label="Print QR Code"
            />
          </Col>
          <Col>
            <div className="button-placer">
              <ReturnToClassOverview
                classDetails={classDetails}
                teachers={teachers}
                name={classDetails.name}
                gradeLevel={gradeLevel}
                schoolid={schoolid}
                student={student}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <ChangeClassModal
        show={showModal}
        oldClass={oldClass}
        handleClose={handleCloseModal}
        studentId={student.id}
        teacherId={teacherId}
        onClassChange={handleClassChange}
      />
    </div>
  );
}

export default StudentDetailsOverview;
