import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/esm/Table";

import "./AssessmentStatus.css";

function AssessmentStatus(props) {

  // console.log(props)
  let classDetails = props.classDetails;
  let classAssessments = props.classAssessmentsValues;
  let teachers = props.teachers;

  let classAssessmentsArray = [];
  classAssessments.forEach((classAssessment) => {
    classAssessmentsArray.push(classAssessment);
  });

  const navigate = useNavigate();

  const toAssessmentResults = (assessment) => {
    navigate("/results", {
      state: {
        assessment: { assessment },
        classDetails: { classDetails },
        classAssessments: { classAssessments },
        classAssessmentsArray: { classAssessmentsArray },
        teachers: { teachers },
      },
    });
  };

  return (
    <Row className="margin-left">
      <Col>
        <div>
          <h3 className="assessment-status-header">Assessments</h3>
          <p>To find out more about assessments go to the Assessment Area</p>
          <h3 className="assessment-status-header">Assessment Pending</h3>
          <Table className="custom-table">
            <tbody>
              {classAssessmentsArray
                .filter((classAssessments) => classAssessments.pending >= 1)
                .map((classAssessments, index) => (
                  <tr
                    key={index}
                    className="table-data"
                    style={{
                      backgroundColor:
                        classAssessments.name === "Winter Instance"
                          ? "#e4f3f7"
                          : classAssessments.name === "Spring Instance"
                          ? "#e0f0ce"
                          : classAssessments.name === "Autumn Instance"
                          ? "#f8d4b6"
                          : "#eff0ef",
                    }}
                    onClick={() => {
                      toAssessmentResults(classAssessments);
                    }}
                  >
                    <td>
                      <img
                        src="/assets/imgs/assessment.svg"
                        className="assessment-icon"
                      />
                    </td>
                    <td className="text-left">{classAssessments.name}</td>
                    <td>
                      {classAssessments.completed} of{" "}
                      {classAssessments.completed + classAssessments.pending}{" "}
                      Students Complete
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <h3 className="assessment-status-header">Assessment History</h3>
          <Table className="custom-table">
            <tbody>
              {classAssessmentsArray
                .filter(
                  (classAssessments) =>
                    classAssessments.completed >= 1 &&
                    classAssessments.pending === 0
                )
                .map((classAssessments, index) => (
                  <tr
                    className="table-data"
                    style={{
                      backgroundColor:
                        classAssessments.name === "Winter Instance"
                          ? "#e4f3f7"
                          : classAssessments.name === "Spring Instance"
                          ? "#e0f0ce"
                          : classAssessments.name === "Autumn Instance"
                          ? "#f8d4b6"
                          : "#eff0ef",
                    }}
                    key={index}
                    onClick={() => {
                      toAssessmentResults(classAssessments);
                    }}
                  >
                    <td>
                      <img
                        src="/assets/imgs/assessment.svg"
                        className="assessment-icon"
                      />
                    </td>
                    <td className="text-left">{classAssessments.name}</td>
                    <td>
                      {classAssessments.completed} of{" "}
                      {classAssessments.completed + classAssessments.pending}{" "}
                      Students Complete
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
}

export default AssessmentStatus;
