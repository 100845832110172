import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SandboxModal from "./SandboxModal";
import "./SandboxCard.css";

function SandboxCard({ title, iframe, documentUrl }) {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Col className="col-3">
      {documentUrl ? (
        <a
          href={documentUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Card
            className="sandbox-card-detail"
            style={{ backgroundColor: "#f9b540" }}
          >
            <div>
              <img
                src="./assets/imgs/Alpaca-logo-white-outline.png"
                className="class-card-img"
                alt="alpaca-logo"
              />
              <p
                className="sandbox-card-text"
                style={{ color: "white", fontSize: "20px" }}
              >
                {title}
              </p>
            </div>
          </Card>
        </a>
      ) : (
        <Card
          className="sandbox-card-detail"
          onClick={iframe ? openModal : undefined}
          style={{ backgroundColor: "#f9b540" }}
        >
          <div>
            <img
              src="./assets/imgs/Alpaca-logo-white-outline.png"
              className="class-card-img"
              alt="alpaca-logo"
            />
            <p className="sandbox-card-text" style={{ color: "white" }}>
              {title}
            </p>
          </div>
        </Card>
      )}
      {iframe && (
        <SandboxModal
          title={title}
          iframe={iframe}
          showModal={showModal}
          closeModal={closeModal}
        />
      )}
    </Col>
  );
}

export default SandboxCard;