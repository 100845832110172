import { React, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./ShareClassOverview.css";

import ReturnToClassOverview from "./ReturnToClassOverview";
import ShareClassFunction from "./ShareClassFunction";
import SubmitShareButton from "./SubmitShareButton";
import AdditionalColleaguesButton from "./AdditionalColleaguesButton";
import GradeCard from "./GradeCard";

function ShareClassOverview(props) {
  let classDetails = props.classDetails.classDetails;
  let teacherid = props.classDetails.classDetails.teacherId;
  let gradeLevel = props.gradeLevel;
  let name = props.name;
  let teachers = props.teachers.teachers;
  let sharedWith = props.sharedWith.sharedWith;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trail_class;
  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let schoolid = props.schoolid;

  let teacherName = teachers.find((teacher) => teacher.id === teacherid)?.name;

  console.log(teacherName);

  let [sharedClassArray, setSharedClassArray] = useState([]);
  let [unSharedClassArray, setUnSharedClassArray] = useState([]);

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-8">
            <h2 className="page-description-header">Share Class</h2>
            <p>
              You can add users associated with this school to your class or
              invite users to join ALPACA using their email address.
            </p>
          </Col>
          <Col className="col-4">
            <Row>
              <Col className="col-6">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr></hr>
        <AdditionalColleaguesButton
          rollNumber={rollNumber}
          teacherEmail={teacherEmail}
        />
        <br></br>
        <Row>
          <Col>
            <p>
              The users below are associated with your school. You can share
              your class with them.
            </p>
            <p>
              This class can't be un-shared with {teacherName} as they created
              the classroom - please email info@alpaca-assessment.com if you
              need assistence with this.
            </p>
            <hr></hr>
            <ShareClassFunction
              classDetails={classDetails}
              teachers={teachers}
              sharedWith={sharedWith}
              setSharedClassList={setSharedClassArray}
              setUnSharedClassList={setUnSharedClassArray}
            />
          </Col>
          <div className="shareclass-button-placer">
            <ReturnToClassOverview
              classDetails={classDetails}
              teachers={teachers}
              name={name}
              gradeLevel={gradeLevel}
              rollNumber={rollNumber}
              teacherEmail={teacherEmail}
              schoolid={schoolid}
            />
            <SubmitShareButton
              classDetails={classDetails}
              teachers={teachers}
              name={name}
              gradeLevel={gradeLevel}
              schoolid={schoolid}
            />
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default ShareClassOverview;
