import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../components/FolderCard.css";

function FolderCard({ folderName, onClick, isActive, isSmaller }) {
  const cardClassName = `folder-card ${isSmaller ? "smaller-folder-card" : ""}`;
  const textClassName = `folder-card-text ${
    isSmaller ? "smaller-folder-card-text" : ""
  }`;

  return (
    <Col className={`col-3 ${isActive ? "hidden" : ""}`}>
      <Card
        className={cardClassName}
        onClick={onClick}
        style={{ backgroundColor: "#f9b540" }}
      >
        <div>
          <img
            src="./assets/imgs/Alpaca-logo-white-outline.png"
            className="class-card-img"
            alt="alpaca-logo"
          />
          <p className={textClassName} style={{ color: "white" }}>
            {folderName}
          </p>
        </div>
      </Card>
    </Col>
  );
}

export default FolderCard;
