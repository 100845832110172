import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import "./AdditionalColleaguesButton.css";

function AdditionalColleaguesButtonMD2(props) {
  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let classes = props.classes;
  let teachers = props.teachers;
  let name = props.name;

  return (
    <Col className="col-3">
      <Link
        // className="intro-vid-card-detail"
        style={{ textDecoration: "none", backgroundColor: "#f9b540"}}
        to={`/mycolleagues`}
        state={{
          classDetails: { classes },
          teachers: { teachers },
          name: { name },
          rollNumber: { rollNumber },
          teacherEmail: { teacherEmail },
        }}
      >
        <Card 
          className="intro-additional-coll-card-detail"
          style={{ pointerEvent: "On" }}>
          <div>
            <img
              src="../assets/imgs/mycolleagues-icon.png"
              className="class-card-img"
              alt="Colleague Icon"
            />
            <p className="sandbox-card-text" style={{ color: "#2c7693", fontSize: "20px" }}>
              Invite Colleagues
            </p>
          </div>
        </Card>
      </Link>
    </Col>
  );
}

export default AdditionalColleaguesButtonMD2;
