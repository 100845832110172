import { useState, useEffect } from "react";
import { Row, Col, Table, Form } from "react-bootstrap";
import PaginationComponent from "./PaginationComponent";
import "./AddedStudentsList.css";

function AddedStudentsList(props) {
  const [itemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGrades, setSelectedGrades] = useState({});
  const [localStudentDetails, setLocalStudentDetails] = useState([]);
  const [selectAll, setSelectAll] = useState({
    esl: false,
    sen: false,
    asd: false,
    gdd: false,
    send: false,
  });

  const isSpecialEdClass = props.isSpecialEdClass;
  const gradeLevelOptions = props.gradeLevelOptions || {};
  const countryId = props.countryId;

  const irelandId = "07d45b79-24b1-435f-b82a-93861b23a86e";
  const englandId = "74bb2897-dbbe-41e3-b547-caee866545cf";

  useEffect(() => {
    const updatedStudentDetails = (props.studentDetails || []).map(
      (student) => ({
        ...student,
        isSpecialEd: isSpecialEdClass ? true : student.isSpecialEd || false,
      })
    );
    setLocalStudentDetails(updatedStudentDetails);
    if (
      JSON.stringify(updatedStudentDetails) !==
      JSON.stringify(props.studentDetails)
    ) {
      props.setStudentDetails(updatedStudentDetails);
    }
  }, [props.studentDetails, isSpecialEdClass]);

  const handleCheckChange = (e, index, key) => {
    const isChecked = e.target.checked;

    setLocalStudentDetails((prevDetails) => {
      const newDetails = prevDetails.map((student) => {
        if (student.name === e.target.value) {
          return { ...student, [key]: isChecked };
        }
        return student;
      });
      props.setStudentDetails(newDetails);
      return newDetails;
    });
  };

  const handleSelectAllChange = (key) => {
    const isChecked = !selectAll[key];
    setSelectAll((prevState) => ({
      ...prevState,
      [key]: isChecked,
    }));

    setLocalStudentDetails((prevDetails) => {
      const newDetails = prevDetails.map((student) => ({
        ...student,
        [key]: isChecked,
      }));
      props.setStudentDetails(newDetails);
      return newDetails;
    });
  };

  const removeStudent = (e, index) => {
    setLocalStudentDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails.splice(index, 1);
      props.setStudentDetails(newDetails);
      return newDetails;
    });
  };

  const handleGradeChange = (e, index) => {
    const selectedGrade = e.target.value;
    setSelectedGrades((prevGrades) => ({
      ...prevGrades,
      [index]: selectedGrade,
    }));
    setLocalStudentDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails[index].currentGrade = selectedGrade;
      props.setStudentDetails(newDetails);
      return newDetails;
    });
  };

  if (!localStudentDetails || localStudentDetails.length === 0) {
    return (
      <Row>
        <Col>
          <div>
            <h3 className="class-overview-table-header">New Students</h3>
            <p>
              When you are happy with the list of new students, click the{" "}
              <b>Save</b> button to save your changes
            </p>
            <div className="students-list-table-container">
              <div className="text-centre">
                <p>
                  Type a student name or paste a list of student names in the
                  text box above and click add. Only new students you add will
                  appear on this page. A full list of students in your class
                  will be displayed in the "Class View"
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  const startItemIndex = (currentPage - 1) * itemsPerPage;
  const endItemIndex = startItemIndex + itemsPerPage;
  const currentItems =
    localStudentDetails?.slice(startItemIndex, endItemIndex) || [];

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const gradeLevels = [
    gradeLevelOptions.grade1,
    gradeLevelOptions.grade2,
    gradeLevelOptions.grade3,
    gradeLevelOptions.grade4,
  ];

  return (
    <Row>
      <Col>
        <div>
          <h3 className="class-overview-table-header">New Students</h3>
          <p>
            When you are happy with the list of new students, click the{" "}
            <b>Save</b> button to save your changes.
          </p>

          <p>
            To select <b>ALL</b> students for a particular additional property,
            click the checkbox underneath the column header.
          </p>
          <div className="students-list-table-container">
            <Table className="student-list-table">
              <thead className="student-list-table-head">
                <tr>
                  <th className="text-left">Name</th>
                  <th className="header-th">
                    ESL
                    <Form.Check
                      type="checkbox"
                      onChange={() => handleSelectAllChange("esl")}
                      checked={selectAll.esl}
                    />
                  </th>
                  <th className="header-th">
                    Speech & Language
                    <Form.Check
                      type="checkbox"
                      onChange={() => handleSelectAllChange("sen")}
                      checked={selectAll.sen}
                    />
                  </th>
                  {isSpecialEdClass && (
                    <th className="header-th">Current Grade</th>
                  )}
                  {(countryId === irelandId || countryId === englandId) && (
                    <th className="header-th">
                      ASD
                      <Form.Check
                        type="checkbox"
                        onChange={() => handleSelectAllChange("asd")}
                        checked={selectAll.asd}
                      />
                    </th>
                  )}
                  {(countryId === irelandId || countryId === englandId) && (
                    <th className="header-th">
                      GDD
                      <Form.Check
                        type="checkbox"
                        onChange={() => handleSelectAllChange("gdd")}
                        checked={selectAll.gdd}
                      />
                    </th>
                  )}
                  {countryId === englandId && (
                    <th className="header-th">
                      SEND
                      <Form.Check
                        type="checkbox"
                        onChange={() => handleSelectAllChange("send")}
                        checked={selectAll.send}
                      />
                    </th>
                  )}
                  <th className="header-th">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((student, index) => (
                  <tr className="student-list-table-row" key={index}>
                    <td className="text-left" id={index}>
                      {student.name}
                    </td>
                    <td>
                      <Form.Check
                        onChange={(e) => handleCheckChange(e, index, "esl")}
                        type="checkbox"
                        value={student.name}
                        id={student.name}
                        checked={student.esl || false}
                      />
                    </td>
                    <td>
                      <Form.Check
                        onChange={(e) => handleCheckChange(e, index, "sen")}
                        type="checkbox"
                        value={student.name}
                        id={student.name}
                        checked={student.sen || false}
                      />
                    </td>
                    {isSpecialEdClass && (
                      <td>
                        <Form.Control
                          as="select"
                          value={selectedGrades[index] || ""}
                          onChange={(e) => handleGradeChange(e, index)}
                        >
                          <option value="" disabled>
                            Select grade
                          </option>
                          {gradeLevels.map((grade, idx) => (
                            <option key={idx} value={grade}>
                              {grade}
                            </option>
                          ))}
                        </Form.Control>
                      </td>
                    )}
                    {(countryId === irelandId || countryId === englandId) && (
                      <td>
                        <Form.Check
                          onChange={(e) => handleCheckChange(e, index, "asd")}
                          type="checkbox"
                          value={student.name}
                          id={student.name}
                          checked={student.asd || false}
                        />
                      </td>
                    )}
                    {(countryId === irelandId || countryId === englandId) && (
                      <td>
                        <Form.Check
                          onChange={(e) => handleCheckChange(e, index, "gdd")}
                          type="checkbox"
                          value={student.name}
                          id={student.name}
                          checked={student.gdd || false}
                        />
                      </td>
                    )}
                    {countryId === englandId && (
                      <td>
                        <Form.Check
                          onChange={(e) => handleCheckChange(e, index, "send")}
                          type="checkbox"
                          value={student.name}
                          id={student.name}
                          checked={student.send || false}
                        />
                      </td>
                    )}
                    <td>
                      <button
                        className="action-button"
                        value={student.name}
                        id={index}
                        onClick={(e) => removeStudent(e, index)}
                      >
                        <img src="/assets/imgs/delete.svg" alt="Remove" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <br />
          <br />
        </div>
        <PaginationComponent
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={localStudentDetails.length}
          handlePageClick={handlePageClick}
        />
      </Col>
    </Row>
  );
}

export default AddedStudentsList;
