import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import HeadingBanner from "../components/HeadingBanner";
import RightPanel from "../components/RightPanel";
import SignUpForm from "../components/SignUpForm";

function SignUp() {
  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        isLoggedIn={false}
        cloud={true}
        name={"Assessing Letter & Phonemic Awareness Class Assistant"}
        headingText={"Teacher Dashboard"}
      />
      <Row className="h-100">
        <Col className="blue-background">
          <SignUpForm />
        </Col>
        {/* <RightPanel msg="Have an account?" label="Login Here" path="/login" /> */}
      </Row>
    </Container>
  );
}

export default SignUp;
