import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getStudents, getQR, getClass } from "../services/APIClient";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./ClassCard.css";

function ClassCard(props) {
  // Access props directly without destructuring
  let classDetails = props.classDetails;
  let filteredClassObjects = props.filteredClassObjects;
  let schoolid = props.schoolid;
  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let teachers = props.teachers;

  // Variables extracted from classDetails
  let name = classDetails.name;
  let gradeLevel = classDetails.grade_level;
  let classid = classDetails.id;
  let gradeLevel1 = classDetails.grade_level1;
  let trialClass = classDetails.trial_class;
  let specialEdClass = classDetails.specialed_class;

  // State for storing students data, QR code details, and group details
  let [studentsResponse, setStudentsResponse] = useState("");
  let [qrCodeDetails, setQRCodeDetails] = useState(""); // State for QR code details
  let [sharedWith, setSharedWith] = useState(""); // State for group details

  // Fetch students, QR code details, and group details
  useEffect(() => {
    getStudents(classid).then((response) => {
      setStudentsResponse(response);
    });
    getQR(classid).then((response) => {
      setQRCodeDetails(response);
    });
    getClass(classid).then((response) => {
      setSharedWith(response.sharedWith);
    });
  }, [classid]);

  // Check if the class name is in the filteredClassObjects, excluding names containing '24-'
  let isNameInFiltered = filteredClassObjects.some(
    (filteredClass) =>
      filteredClass.name === name && !filteredClass.name.includes("24-")
  );

  let students = studentsResponse.students;

  return (
    <Col className="col-4">
      <Card
        className={`${
          trialClass
            ? "trial-card-detail"
            : specialEdClass
            ? "specialed-card-detail"
            : "card-detail"
        }`}
      >
        {isNameInFiltered ? (
          <Link
            className="link"
            to={`/class/settings/special`}
            state={{
              classDetails: { classDetails },
              teachers: { teachers },
              sharedWith: { sharedWith },
              name: { name },
              gradeLevel: { gradeLevel },
              students: { students },
              qrCodeDetails: { qrCodeDetails },
              schoolid: { schoolid },
            }}
          >
            <img
              src="./assets/imgs/Asset-class.webp"
              className="class-card-img"
              alt="Class Image"
            />
            <div>
              <p
                className={`${
                  gradeLevel1 ? "grade-level-blue" : "grade-level-purple"
                }`}
              >
                {gradeLevel}
              </p>
              <p
                className={`${
                  specialEdClass
                    ? "specialed-class-card-text"
                    : "class-card-text"
                }`}
              >
                Assign New {gradeLevel} Classroom teacher
              </p>
            </div>
            <p
              className={`${
                trialClass
                  ? "trial-class-card-text"
                  : specialEdClass
                  ? "specialed-class-card-text"
                  : "class-card-text"
              }`}
            >
              {name}
            </p>
          </Link>
        ) : (
          <Link
            className="link"
            to={`/class/detail`}
            state={{
              classDetails: { classDetails },
              teachers: { teachers },
              name: { name },
              gradeLevel: { gradeLevel },
              rollNumber: { rollNumber },
              teacherEmail: { teacherEmail },
              schoolid: { schoolid },
            }}
          >
            <img
              src="./assets/imgs/Asset-class.webp"
              className="class-card-img"
              alt="Class Image"
            />
            <div>
              <p
                className={`${
                  gradeLevel1 ? "grade-level-blue" : "grade-level-purple"
                }`}
              >
                {gradeLevel}
              </p>
              {students?.length !== undefined ? (
                <p
                  className={`${
                    specialEdClass
                      ? "specialed-class-card-text"
                      : "class-card-text"
                  }`}
                >
                  <img
                    src="./assets/imgs/child.png"
                    className="class-card-img"
                    alt="Student Image"
                  />
                  x {students.length}
                </p>
              ) : (
                <>
                  <br />
                  <p
                    className={`${
                      specialEdClass
                        ? "specialed-class-card-text"
                        : "class-card-text"
                    }`}
                  >
                    Click here to add students to your class.
                  </p>
                </>
              )}
            </div>
            <p
              className={`${
                trialClass
                  ? "trial-class-card-text"
                  : specialEdClass
                  ? "specialed-class-card-text"
                  : "class-card-text"
              }`}
            >
              {name}
            </p>
          </Link>
        )}
      </Card>
    </Col>
  );
}

export default ClassCard;
