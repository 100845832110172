import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Function to generate bell curve data with adjustable scaling
const generateBellCurveData = (start, peak, end) => {
  const data = [];
  const mean = peak;
  const stdDev = (end - start) / 6; // Approximation for 3 standard deviations

  // Scaling factor to make the bell curve more pronounced
  const scaleFactor = 1000; // Adjust this value to increase/decrease the height of the curve

  for (let x = start; x <= end; x += 1) {
    const exponent = -((x - mean) ** 2) / (2 * stdDev ** 2);
    const y =
      scaleFactor *
      (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
      Math.exp(exponent);
    data.push([x, y]);
  }

  return data;
};

const StandardScoresGraph = ({ selectedChild }) => {
  const studentName = selectedChild?.student?.name;
  const start = 40;
  const peak = 100;
  const end = 160;

  const bellCurveData = generateBellCurveData(start, peak, end);

  // Extract the standard scores from each task and assign a color
  const standardScores =
    selectedChild?.tasks?.map((task, index) => ({
      score: task.standardScore, // Use the actual score from the task
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color; adjust if needed
      taskName: task.name || `Task ${index + 1}`, // Use the task name or a default value
    })) || [];

  const xAxisPlotLines = [55, 70, 85, 100, 115, 130, 145].map((point) => ({
    color: "black",
    width: 0.25,
    value: point,
    label: {
      align: "center",
      style: {
        color: "black",
        fontSize: "12px",
      },
    },
  }));

  const options = {
    chart: {
      type: "column",
      height: 400, // Increase height to accommodate the larger curve
      margin: [10, 10, 40, 40],
      events: {
        load: function () {
          const chart = this;
          const xAxis = chart.xAxis[0];
          const yAxis = chart.yAxis[0];

          // Define positions and properties for arrows and labels
          const positions = [
            {
              start: 40,
              end: 55,
              color: "red",
              offset: 0.4, // Lower position for new arrows
              label: "Significantly Below\nAverage",
            },
            {
              start: 55,
              end: 70,
              label: "Below Average",
              color: "orange",
              offset: 0.6,
            },
            {
              start: 70,
              end: 85,
              label: "Slightly Below\nAverage",
              color: "#03dbfc",
              offset: 0.8,
            },
            {
              start: 85,
              end: 115,
              label: "Within the\nAverage Range",
              color: "purple",
              offset: 1.05,
            },
            {
              start: 115,
              end: 130,
              label: "Above the\nAverage Range",
              color: "#03dbfc",
              offset: 0.8,
            },
            {
              start: 130,
              end: 145,
              label: "Significantly Above\nAverage",
              color: "orange",
              offset: 0.6,
            },
            {
              start: 145,
              end: 160,
              color: "red",
              offset: 0.4, // Lower position for new arrows
              label: "Significantly Above\nAverage",
            },
          ];

          positions.forEach((pos) => {
            const xStart = xAxis.toPixels(pos.start);
            const xEnd = xAxis.toPixels(pos.end);
            const yPosition = yAxis.toPixels(
              Math.max(...bellCurveData.map((point) => point[1])) * pos.offset
            );

            // Draw the arrow
            chart.renderer
              .path([
                "M",
                xStart,
                yPosition, // Move to start point
                "L",
                xEnd,
                yPosition, // Draw line to end point
                "M",
                xStart,
                yPosition, // Draw right arrowhead
                "L",
                xStart + 10,
                yPosition - 5,
                "M",
                xStart,
                yPosition, // Draw left arrowhead
                "L",
                xStart + 10,
                yPosition + 5,
                "M",
                xEnd,
                yPosition, // Draw left arrowhead at the end
                "L",
                xEnd - 10,
                yPosition - 5,
                "M",
                xEnd,
                yPosition, // Draw right arrowhead at the end
                "L",
                xEnd - 10,
                yPosition + 5,
              ])
              .attr({
                stroke: pos.color,
                "stroke-width": 2,
              })
              .add();

            if (pos.label) {
              // Add the label if defined
              const label = chart.renderer
                .text(pos.label, 0, yPosition - 15) // Adjust vertical position of the label
                .css({
                  color: pos.color,
                  fontSize: "12px",
                })
                .add();

              // Center the label
              const labelBBox = label.getBBox();
              const centeredXPosition =
                (xStart + xEnd) / 2 - labelBBox.width / 2;

              label.attr({
                x: centeredXPosition,
              });
            }
          });
        },
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [55, 70, 85, 100, 115, 130, 145], // Specific x-axis labels
      tickPositions: [55, 70, 85, 100, 115, 130, 145], // Ensure ticks are placed at these positions
      title: {
        text: "Score",
      },
      labels: {
        format: "{value}", // Display the specified score values
      },
      plotLines: xAxisPlotLines, // Add vertical lines at specific x positions
    },
    yAxis: {
      title: {
        text: "", // No title for y-axis
      },
      labels: {
        enabled: false, // Hide y-axis labels
      },
      lineWidth: 0, // Hide y-axis line
      gridLineWidth: 0, // Hide grid lines
      min: 0, // Ensure y-axis starts at 0
      max: Math.max(...bellCurveData.map((point) => point[1])) * 1.1, // Adjust max to fit the curve
    },
    series: [
      {
        name: "Scores",
        showInLegend: false,
        data: standardScores.map((s) => ({
          x: s.score,
          y: 1, // Same height for all bars
          color: s.color,
          taskName: s.taskName, // Pass taskName to data
        })),
        type: "column",
        pointPadding: 0, // No space between bars
        groupPadding: 0, // No space between groups
        pointWidth: 20, // Width of the bars
        dataLabels: {
          enabled: false, // Hide data labels on bars
        },
        point: {
          events: {
            click: function () {
              // You can also handle click events if needed
            },
          },
        },
      },
      {
        name: "Bell Curve",
        showInLegend: false, // Hide the "Bell Curve" label from the legend
        data: bellCurveData,
        type: "line",
        color: "black", // Color for the bell curve
        lineWidth: 2,
        marker: {
          enabled: false, // Disable markers on the line
        },
        zIndex: 1, // Ensure the line is above the bars
      },
    ],
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false, // Hide data labels
        },
        enableMouseTracking: true, // Enable mouse tracking
      },
      line: {
        enableMouseTracking: true, // Enable mouse tracking for line
      },
    },
    tooltip: {
      formatter: function () {
        if (this.series.name === "Scores") {
          // Show score and task name for the clicked bar
          return `Score: ${this.x.toFixed(2)}<br>Task: ${this.point.taskName}`;
        }
      },
    },
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>
        Understanding {studentName ? studentName + "'s" : "Your Child's"} Scores
      </h3>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default StandardScoresGraph;
