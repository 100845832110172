import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./ClassOverview.css";
import "./MyColleaguesOverview.css";

import InviteColleaguesButton from "./InviteColleaguesButton";
import SimpleNavigatioButton from "./SimpleNavigationButton";
import AlpacaLeadChangeModal from "./AlpacaLeadChangeModal"; // Import the modal component

import {
  getClassAssessments,
  getStudentResults,
  getPendingTeacherBySchoolId,
  resendTeacherInvite,
  addAlpacaLeadRole,
  removeAlpacaLeadRole,
} from "../services/APIClient";

function MyColleaguesOverview(props) {
  let classDetails = props.classDetails;
  let gradeLevel = props.gradeLevel;
  let name = props.name;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let qrCodeDetails = props.qrCodeDetails;
  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let schoolid = props.schoolid;
  let countryId = props.countryId;

  console.log("countryId", countryId)

  console.log(props, "PProps");

  console.log(teacherEmail, "TTTD");

  const [classAssessments, setClassAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [combinedTeachers, setCombinedTeachers] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [isError, setIsError] = useState(false);
  const [currentAlpacaLead, setCurrentAlpacaLead] = useState(null); // State for current Alpaca Lead
  const [selectedTeacher, setSelectedTeacher] = useState(""); // State for selected teacher in dropdown

  console.log(combinedTeachers, "CT");

  const notifySuccess = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const canResendInvite = (lastInviteSent) => {
    const now = new Date();
    const lastSent = new Date(lastInviteSent);

    return !lastInviteSent || now.getTime() - lastSent.getTime() > 600000; // 600000 ms = 10 minutes
  };

  const handleResendInvite = async (teacher) => {
    const now = new Date();
    if (!canResendInvite(teacher.lastinvitesent)) {
      toast.info("Please wait before resending the invite.");
      return;
    }

    try {
      await resendTeacherInvite(teacher.id);
      setCombinedTeachers((prevTeachers) =>
        prevTeachers.map((t) =>
          t.id === teacher.id ? { ...t, lastinvitesent: now.toISOString() } : t
        )
      );
      notifySuccess(`Invite resent successfully to ${teacher.name}!`);
    } catch (error) {
      console.error("Error resending invite:", error);
      notifyError("Failed to resend invite. Please try again.");
    }
  };

  const activeTeachersWithStatus = teachers.map((teacher) => ({
    ...teacher,
    status: "Account Created",
  }));

  useEffect(() => {
    const loadPendingTeachers = async (schoolid) => {
      try {
        const pendingTeachers = await getPendingTeacherBySchoolId(schoolid);

        // Sort pending teachers alphabetically by name
        const pendingTeachersWithStatus = pendingTeachers
          .map((teacher) => ({
            ...teacher,
            status: "Email Not Opened",
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        // Sort active teachers alphabetically by name
        const sortedActiveTeachers = activeTeachersWithStatus.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        // Combine sorted active and pending teachers
        const combinedList = [
          ...pendingTeachersWithStatus,
          ...sortedActiveTeachers,
        ];

        setCombinedTeachers(combinedList);
      } catch (error) {
        console.error("Failed to load pending teachers:", error);
        // Fallback: Only sort active teachers if fetching pending teachers fails
        const sortedActiveTeachersFallback = activeTeachersWithStatus.sort(
          (a, b) => a.name.localeCompare(b.name)
        );
        setCombinedTeachers(sortedActiveTeachersFallback);
      }
      setLoading(false);
    };

    loadPendingTeachers(schoolid);
  }, [schoolid, teachers]);

  useEffect(() => {
    const alpacaLead = teachers.find((teacher) =>
      teacher.additionalRoles?.includes("Alpaca Lead")
    );
    setCurrentAlpacaLead(alpacaLead);
  }, [teachers]);

  const handleAlpacaLeadChange = (newAlpacaLeadId) => {
    const newAlpacaLead = teachers.find(
      (teacher) => teacher.id === newAlpacaLeadId
    );
    setCurrentAlpacaLead(newAlpacaLead);
  };

  function formatDate(dateString) {
    return format(new Date(dateString), "MMMM d, yyyy h:mm aa");
  }

  return (
    <div className="colleagues-overview-container">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container className="container-shape">
        <Row>
          <Col className="col-7">
            <Row className="mt-4">
              <Col className="col-7">
                <h2 className="page-description-header">My Colleagues</h2>
              </Col>
            </Row>
            <p className="paragraph-font-size">
              These are colleagues who are in your school and who are registered
              as users on the ALPACA system
            </p>
            <div>
              <p>
                <strong>Current ALPACA Lead:</strong>{" "}
                {currentAlpacaLead ? currentAlpacaLead.name : "None"}
              </p>
              <div>
                <AlpacaLeadChangeModal
                  teachers={teachers}
                  currentAlpacaLead={currentAlpacaLead}
                  onAlpacaLeadChange={handleAlpacaLeadChange}
                  removeAlpacaLeadRole={removeAlpacaLeadRole}
                  addAlpacaLeadRole={addAlpacaLeadRole}
                />
              </div>
            </div>
          </Col>
          <Col className="col-4">
            <Row className="mt-4">
              <Col className="col-5">
                <SimpleNavigatioButton
                  path="/admindashboard"
                  label="Back"
                  colour="blue"
                />
              </Col>
              <Col className="col-7">
                <InviteColleaguesButton
                  classDetails={classDetails}
                  teachers={teachers}
                  name={name}
                  qrCodeDetails={qrCodeDetails}
                  rollNumber={rollNumber}
                  teacherEmail={teacherEmail}
                  schoolid={schoolid}
                  countryId={countryId}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Table bordered hover className="my-colleagues-table">
              <thead>
                <tr>
                  <th className="name-col">Name</th>
                  <th className="role-col">Role</th>
                  <th className="status-col">Status</th>
                  {/* <th className="date-created-col">Date Invited</th> */}
                  <th className="last-invite-sent-col">Invite Sent</th>
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              <tbody>
                {combinedTeachers?.map((teacher, index) => (
                  <tr
                    key={index}
                    className={
                      teacher.status === "Account Created"
                        ? "active-teacher"
                        : "pending-teacher"
                    }
                  >
                    <td className="name-col">{teacher?.name}</td>
                    <td className="role-col">
                      {teacher?.roles || teacher.additionalRoles?.join(", ")}
                    </td>
                    <td className="status-col">{teacher?.status}</td>
                    {/* <td className="date-created-col">
                      {teacher?.datecreated
                        ? formatDate(teacher.datecreated)
                        : "Active"}
                    </td> */}
                    <td className="last-invite-sent-col">
                      {teacher.status === "Account Created"
                        ? "Active"
                        : teacher?.lastinvitesent
                          ? formatDate(teacher.lastinvitesent)
                          : " "}
                    </td>
                    <td className="action-col">
                      {teacher.status === "Email Not Opened" && (
                        <Tippy
                          content={
                            canResendInvite(teacher.lastinvitesent)
                              ? "Click to resend invite"
                              : "Wait 10 minutes between sending invites"
                          }
                        >
                          <span style={{ display: "inline-block" }}>
                            <Button
                              variant="success"
                              disabled={
                                !canResendInvite(teacher.lastinvitesent)
                              }
                              onClick={() => handleResendInvite(teacher)}
                            >
                              Resend Invite
                            </Button>
                          </span>
                        </Tippy>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Row>
        {feedback && (
          <div style={{ color: isError ? "red" : "green", marginTop: "20px" }}>
            {feedback}
          </div>
        )}
      </Container>
    </div>
  );
}

export default MyColleaguesOverview;
