import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import ReactToPrint from "react-to-print";

import Row from "react-bootstrap/Row";

import QRCodeGenerator from "./QRCodeGenerator";

import "./PrintAllLoginsButton.css";

function PrintAllLoginsButton(props) {
  // let students = props.students;
  let name = props.name;
  let label = props.label;

  let qrCodeDetails = props.qrCodeDetails;

  let studentsQRDetails = [];
  if (qrCodeDetails) {
    qrCodeDetails.forEach((student) => {
      studentsQRDetails.push(student);
    });
  }

  studentsQRDetails.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  const componentRef = useRef();

  // console.log(studentsQRDetails);

  // below is a terrible solution and I will properly fix
  // once things cool down a bit (06/10/22), Cathal

  function createPageBreak(qrId) {
    return function () {
      let qrCodeContainer = document.querySelectorAll(".qr-code-container");
      let newDiv = document.createElement("div");
      newDiv.className = "pagebreak";

      qrCodeContainer.forEach((qrCode, index) => {
        let qrCodeId = qrCode.getAttribute("data-qrid");
        if (qrCodeId == qrId) {
          qrCode.after(newDiv);
        }
      });
    };
  }

  const addPageBreakFunctions = [
    createPageBreak(11),
    createPageBreak(23),
    createPageBreak(35),
    createPageBreak(47),
    createPageBreak(59),
    createPageBreak(71),
    createPageBreak(83),
    createPageBreak(95),
    createPageBreak(107),
    createPageBreak(119),
    createPageBreak(131),
    createPageBreak(143),
    createPageBreak(155),
    createPageBreak(167),
    createPageBreak(179),
    createPageBreak(191),
  ];

  useEffect(() => {
    addPageBreakFunctions.forEach((addPageBreakFn) => {
      addPageBreakFn();
    });
  }, []);

  return (
    <>
      <QRPrintable
        students={studentsQRDetails}
        name={name}
        ref={componentRef}
      />

      <ReactToPrint
        content={() => componentRef.current}
        trigger={() => (
          <button className="blue-button" style={{ borderRadius: "8px" }}>
            {props.label}
          </button>
        )}
      />
    </>
  );
}

const QRPrintable = React.forwardRef((props, ref) => (
  <div className="hide-div">
    <div ref={ref}>
      <Row>
        <h2 className="text-center printout-heading-fontsize">
          {props.name} Logins - alpaca-assessment.com
        </h2>
        {props.students.map((student, index) => (
          <QRCodeGenerator student={student} index={index} />
        ))}
      </Row>
    </div>
  </div>
));

export default PrintAllLoginsButton;
