import React from "react";
import { useNavigate } from "react-router-dom";
import "./SubmitShareButton.css";

function SubmitInviteButton({ onClick }) {
  return (
    <button onClick={onClick} className="green-button">
      Add Colleagues
    </button>
  );
}


export default SubmitInviteButton;
