import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./SupportPlanModal.css";
import {
  getSencoReportByStudentId,
  upsertSencoReport,
} from "../services/APIClient";

const supportOptions = [
  { text: "Classroom Support", color: "yellow" },
  { text: "School Support", color: "red" },
  { text: "School Support Plus", color: "green" },
];

const PlaceOnContinuumModal = ({
  show,
  handleClose,
  studentName,
  currentStudentId,
  teacherid,
  callClassName,
  setName,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (show && currentStudentId) {
      // Fetch literacy observations when the modal is shown and currentStudentId is available
      getSencoReportByStudentId(currentStudentId)
        .then((response) => {
          if (response && response.supportcontinuum !== undefined) {
            setSelectedOption(response.supportcontinuum[0] || null); // Pre-select the option if available
          } else {
            setSelectedOption(null);
          }
        })
        .catch((error) => {
          console.error("Error fetching literacy observations:", error);
          setSelectedOption(null);
        });
    }
  }, [show, currentStudentId]);

  const handleOptionClick = (text) => {
    setSelectedOption(text === selectedOption ? null : text);
    console.log(`Selected option for ${studentName}:`, text);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
  };

  const handleModalClose = () => {
    setSelectedOption(null); // Reset selected option to null
    handleClose();
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      supportcontinuum: selectedOption ? [selectedOption] : [],
      setname: setName,
      classname: callClassName,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      upsertSencoReport(reportData)
        .then((response) => {
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Place on Continuum
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="options-container">
          {supportOptions.map((option) => (
            <div
              key={option.text}
              className={`option ${option.color} ${
                selectedOption === option.text ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.text)}
            >
              {option.text}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="modal-button no-button" onClick={handleModalClose}>
          Cancel
        </button>
        <button className="modal-button yes-button" onClick={saveChanges}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlaceOnContinuumModal;
