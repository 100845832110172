import React from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// import button from 'react-bootstrap/button';

import "./AdditionalColleaguesButton.css";

function AdditionalColleaguesButtonMD(props) {
  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let classes = props.classes;
  let teachers = props.teachers;
  let name = props.name;
  // console.log(props)

  return (
    <div className="additional-colleague-button">
      <Link
        className="ass-heading-link"
        to={`/mycolleagues`}
        state={{
          classDetails: { classes },
          teachers: { teachers },
          name: { name },
          rollNumber: { rollNumber },
          teacherEmail: { teacherEmail },
        }}
      >
        <img
          src="../assets/imgs/mycolleagues-icon.png"
          className="assessment-reminder-card-img-ac"
          alt="Colleague Icon"
        />
        <p className="class-card-text">Invite Colleagues</p>
        <img
          src="../assets/imgs/mycolleagues-icon.png"
          className="assessment-reminder-card-img-ac"
          alt="Colleague Icon"
        />
      </Link>
      {/* <a
      className="additional-colleague-link"
      href={`https://26983596.hs-sites-eu1.com/other-colleagues/?roll_number_contact=${rollNumber}&email=${teacherEmail}`}
      target="_blank"
    >

      </a> */}
    </div>
  );
}

export default AdditionalColleaguesButtonMD;
