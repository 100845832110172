import { React, useState, useEffect, useRef, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import secondsToTime from "../services/secondsToTime";

import "./StudentsResultsTable.css";

function StudentsResultsTable(props) {
  const {
    classDetails,
    classAssessments,
    gradeLevel,
    teachers,
    assessment,
    assessmentResultsByStudent,
    toggleScoreType,
    isPrintOut,
    sortCriteria,
    showAtRiskOnly,
    setSortCriteria,
    setShowAtRiskOnly,
    schoolid,
    teacherid,
  } = props;

  console.log("teacherid:", teacherid);
  console.log("schoolid:", schoolid);

  let fullTaskNameArray = [];
  function taskNameRetriever() {
    for (let i = 0; i < assessmentResultsByStudent.length; i++) {
      let student = assessmentResultsByStudent[i];
      let studentTasks = student?.tasks;

      if (studentTasks) {
        studentTasks.sort(function (a, b) {
          return a.order - b.order;
        });
      }

      for (let j = 0; j < studentTasks?.length; j++) {
        let taskNames = studentTasks[j].name;
        fullTaskNameArray.push(taskNames);
      }
    }
  }
  taskNameRetriever();

  let taskNameArray = [...new Set(fullTaskNameArray)];

  let scoreArray = [];
  let allScores = [];
  let tasksArray = [];

  function scoreRetreiver() {
    for (let i = 0; i < assessmentResultsByStudent.length; i++) {
      let student = assessmentResultsByStudent[i];
      let studentName = student?.student.name;
      let studentID = student?.student.id;
      let studentTasks = student?.tasks;
      let studentTime = student?.time;
      let convertedTime = secondsToTime(student?.time);

      let totalScore = 0;
      allScores = [];

      studentTasks?.sort(function (a, b) {
        return a.order - b.order;
      });

      if (studentTasks.length === 0) {
        let notAssessedStudent = {
          name: studentName,
          id: studentID,
          time: 0,
          totalScore: -1,
          scores: null,
          taskScores: null,
        };
        scoreArray.push(notAssessedStudent);
      }

      tasksArray = [];
      for (let j = 0; j < studentTasks?.length; j++) {
        let taskName = studentTasks[j]?.name;
        let score;
        let tasks;

        totalScore += studentTasks[j]?.score;

        if (toggleScoreType) {
          // for gp
          score = studentTasks[j]?.groupPercentile;
          allScores.push(score?.toFixed());

          tasks = {
            [taskName]: score?.toFixed(),
          };
        } else {
          // for percentage score
          score = studentTasks[j].score;
          allScores.push(Math.round(score * 100));

          tasks = {
            [taskName]: Math.round(score * 100),
          };
        }

        tasksArray.push(tasks);

        scoreArray[i] = {
          name: studentName,
          id: studentID,
          time: convertedTime,
          sortableTime: studentTime,
          totalScore: totalScore,
          scores: allScores,
          taskScores: tasksArray,
          readingScore:
            totalScore > 0
              ? Math.round((totalScore / tasksArray.length) * 100)
              : 0,
        };
      }
    }
  }
  scoreRetreiver();

  let sortByTotalScore = scoreArray.sort(function (a, b) {
    return b.totalScore - a.totalScore;
  });

  function getReadingScoreClass(score) {
    if (score === 0) {
      return "low-color";
    } else if (score > 0 && score <= 20) {
      return "low-color";
    } else if (score > 20 && score <= 40) {
      return "med-color";
    } else if (score > 40 && score <= 100) {
      return "noRisk-color";
    } else {
      return "blah";
    }
  }

  const navigate = useNavigate();
  const toIndividualStudentResults = (student) => {
    console.log("Navigating with student:", student);
    navigate("/student/results", {
      state: {
        student: { student },
        classDetails: { classDetails },
        classAssessments: { classAssessments },
        gradeLevel: { gradeLevel },
        teachers: { teachers },
        assessment: { assessment },
        taskNameArray: { taskNameArray },
        assessmentResultsByStudent: { assessmentResultsByStudent },
        toggleScoreType: { toggleScoreType },
        schoolid: { schoolid },
        teacherid: { teacherid },
      },
    });
  };

  const sortData = (field) => {
    const sortField = field === "atRisk" ? "readingScore" : field;

    setSortCriteria((prev) => {
      const isAscending = prev.field === sortField && prev.order === "asc";
      return { field: sortField, order: isAscending ? "desc" : "asc" };
    });
  };

  const sortedScoreArray = useMemo(() => {
    const sortedData = [...scoreArray];
    if (sortCriteria && sortCriteria.field) {
      sortedData.sort((a, b) => {
        // Check if the sorting field is one of the task names
        if (taskNameArray.includes(sortCriteria.field)) {
          // Sorting by task score
          let scoreA = getTaskScore(a.taskScores, sortCriteria.field);
          let scoreB = getTaskScore(b.taskScores, sortCriteria.field);
          return sortCriteria.order === "asc"
            ? scoreA - scoreB
            : scoreB - scoreA;
        } else if (sortCriteria.field === "name") {
          // Sorting by name
          return sortCriteria.order === "asc"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        } else {
          // Sorting by other fields
          return sortCriteria.order === "asc"
            ? a[sortCriteria.field] - b[sortCriteria.field]
            : b[sortCriteria.field] - a[sortCriteria.field];
        }
      });
    }
    return sortedData;
  }, [scoreArray, sortCriteria, taskNameArray]);

  function getTaskScore(taskScores, taskName) {
    const task = taskScores?.find((t) => Object.keys(t)[0] === taskName);
    return task ? Number(task[taskName]) : -1;
  }

  function renderTaskScore(taskScores, taskName, toggleScoreType) {
    if (!taskScores) return "Not Started";

    const task = taskScores.find((t) => Object.keys(t)[0] === taskName);
    if (!task || task[taskName] === -1) return "Partial";

    const score = Number(task[taskName]);
    let scoreClass = "";
    let scoreDisplay = toggleScoreType ? score : `${score}%`;

    if (score === 0) {
      scoreClass = "low-color";
    } else if (score > 0 && score <= 20) {
      scoreClass = "low-color";
    } else if (score > 20 && score <= 40) {
      scoreClass = "med-color";
    } else if (score > 40 && score <= 80) {
      scoreClass = "noRisk-color";
    } else if (score > 80 && score <= 100) {
      scoreClass = "high-color";
    }

    return <span className={`task-score ${scoreClass}`}>{scoreDisplay}</span>;
  }

  const getSortIcon = (fieldName) => {
    if (sortCriteria && sortCriteria.field === fieldName) {
      return sortCriteria.order === "asc"
        ? process.env.PUBLIC_URL + "/assets/imgs/desc-icon-active.png"
        : process.env.PUBLIC_URL + "/assets/imgs/asc-icon-active.png";
    }
    return process.env.PUBLIC_URL + "/assets/imgs/asc-icon-default.png";
  };

  const handleAtRiskFilterChange = (e) => {
    setShowAtRiskOnly(e.target.checked);
  };

  console.log(sortedScoreArray, "SSA");

  return (
    <Row>
      <Col>
        <div>
          <Table className="custom-table">
            <thead className="table-header">
              <tr>
                <th
                  colSpan={taskNameArray.length + (isPrintOut ? 3 : 5)}
                  className="at-risk-filter-row"
                >
                  <div className="at-risk-filter">
                    <label htmlFor="atRiskFilter">Show only "At-Risk"</label>
                    <input
                      type="checkbox"
                      name="atRiskFilter"
                      id="atRiskFilter"
                      checked={showAtRiskOnly}
                      onChange={handleAtRiskFilterChange}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/imgs/0-20Bar.png"}
                      alt="0-20%"
                      className="risk-bar"
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/imgs/21-40Bar.png"}
                      alt="21-40%"
                      className="risk-bar"
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  className="header-th-left header-size-results"
                  onClick={() => sortData("name")}
                >
                  <div className="header-content">
                    Student Name
                    <img
                      className="sort-icon"
                      src={getSortIcon("name")}
                      alt="Sort"
                    />
                  </div>
                </th>
                <th
                  className="header-th header-size-results"
                  onClick={() => sortData("readingScore")}
                >
                  <div className="header-content">
                    Average Score across all subtests
                    <img
                      className="sort-icon"
                      src={getSortIcon("readingScore")}
                      alt="Sort"
                    />
                  </div>
                </th>
                <th
                  className="header-th header-size-results"
                  onClick={() => sortData("atRisk")}
                >
                  <div className="header-content">
                    At-Risk
                    <img
                      className="sort-icon"
                      src={getSortIcon("atRisk")}
                      alt="Sort"
                    />
                  </div>
                </th>
                {taskNameArray.map((taskName) => (
                  <th
                    className="header-th header-size-results"
                    key={taskName}
                    onClick={() => sortData(taskName)}
                  >
                    <div className="header-content">
                      {taskName} Raw Score
                      <img
                        className="sort-icon"
                        src={getSortIcon(taskName)}
                        alt="Sort"
                      />
                    </div>
                  </th>
                ))}
                {!isPrintOut ? (
                  <>
                    <th
                      className="header-th header-size-results"
                      onClick={() => sortData("sortableTime")}
                    >
                      <div className="header-content">
                        Time
                        <img
                          className="sort-icon"
                          src={getSortIcon("sortableTime")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    <th className="header-th header-size-results">
                      <div className="header-content">
                        View
                        <img
                          className="sort-icon hide-img"
                          src={getSortIcon("sortableTime")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                  </>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {sortedScoreArray
                .filter(
                  (student) =>
                    !showAtRiskOnly ||
                    (student.readingScore !== undefined &&
                      student.readingScore <= 40)
                )
                .map((student) => (
                  <tr
                    key={student.id} // Add unique key here
                    className="table-row student-results-table-row"
                    data-student-id={student.id}
                  >
                    <td className="centre-student-items align-left">
                      {student.name}
                    </td>
                    <td className="centre-student-items task-badge-parent">
                      {student.readingScore !== undefined &&
                      student.readingScore !== null ? (
                        <span
                          className={`reading-score ${getReadingScoreClass(
                            student.readingScore
                          )}`}
                        >
                          {student.readingScore}%
                        </span>
                      ) : null}
                    </td>
                    <td>
                      {student.readingScore !== undefined &&
                      student.readingScore < 20 ? (
                        <img
                          src="./assets/imgs/risk-flag-red.png"
                          className="flag-size"
                          alt="Red Flag"
                        />
                      ) : student.readingScore >= 20 &&
                        student.readingScore <= 40 ? (
                        <img
                          src="./assets/imgs/risk-flag-yellow.png"
                          className="flag-size"
                          alt="Yellow Flag"
                        />
                      ) : null}
                    </td>
                    {taskNameArray.map((taskName) => (
                      <td
                        key={`${student.id}-${taskName}`} // Add unique key here
                        className="student-results-cell centre-student-items task-badge-parent"
                        data-value={taskName.replace(/\s+/g, "-").toLowerCase()}
                      >
                        {renderTaskScore(
                          student.taskScores,
                          taskName,
                          toggleScoreType
                        )}
                      </td>
                    ))}
                    {!isPrintOut ? (
                      <>
                        <td className="centre-student-items">{student.time}</td>
                        <td className="centre-student-items">
                          <img
                            src="./assets/imgs/eyeCon.png"
                            onClick={() => {
                              toIndividualStudentResults(student);
                            }}
                          />
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
}

export default StudentsResultsTable;
