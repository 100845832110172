import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/esm/Table";

import "./StudentAssessmentHistory.css";

function StudentAssessmentHistory(props) {
  let classDetails = props.classDetails;
  let teachers = props.teachers;

  let student = props.student;
  let students = props.students;

  let pendingAssessments = [];
  let completedAssessments = [];
  let failedAssessments = [];

  if (student) {
    for (let i = 0; i < students.length; i++) {
      if (student.id === students[i].id) {
        let currentStudent = students[i];
        let assessments = currentStudent.assessments;
        if (assessments) {
          for (let j = 0; j < assessments.length; j++) {
            if (assessments[j].completed === false) {
              pendingAssessments.push(assessments[j]);
            } else if (assessments[j].completed === true) {
              completedAssessments.push(assessments[j]);
            }
          }
        }
      }
    }
  }

  return (
    <Row>
      <Col>
        <h3>Assessment History</h3>
        <div className="tables-container">
          <br></br>
          <PendingAssessments pendingAssessments={pendingAssessments} />
          <br></br>
          <CompletedAssessments completedAssessments={completedAssessments} />
          <br></br>
          {/* <FailedAssessments failedAssessments={failedAssessments} /> */}
        </div>
      </Col>
    </Row>
  );
}

function PendingAssessments(props) {
  let pendingAssessments = props.pendingAssessments;

  return (
    <>
      <h5>Pending ({pendingAssessments.length})</h5>
      <Table className="custom-table">
        <thead className="pending-table-header">
          <tr>
            <th className="header-th-left">Assessment Name</th>
            <th className="header-th">
              <span className="hide-text">Score</span>
            </th>
            <th className="header-th"></th>
          </tr>
        </thead>
        <tbody>
          {pendingAssessments.map((assessment, index) => (
            <tr key={index} className="pending-table-row">
              <td className="table-data">{assessment.name}</td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

function CompletedAssessments(props) {
  let completedAssessments = props.completedAssessments;

  return (
    <>
      <h5>Completed Assessments ({completedAssessments.length})</h5>
      <Table className="custom-table">
        <thead className="completed-table-header">
          <tr>
            <th className="header-th-left">Assessment Name</th>
            <th className="header-th"></th>
            <th className="header-th"></th>
          </tr>
        </thead>
        <tbody>
          {completedAssessments.map((assessment, index) => (
            <tr key={index} className="completed-table-row">
              <td className="table-data">{assessment.name}</td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

function FailedAssessments(props) {
  let failedAssessments = props.failedAssessments;

  return (
    <>
      <h5>Failed Assessments ({failedAssessments.length})</h5>
      <Table className="custom-table">
        <thead className="failed-table-header">
          <tr>
            <th className="header-th-left">Assessment Name</th>
            <th className="header-th">Score</th>
            <th className="header-th">View</th>
          </tr>
        </thead>
        <tbody>
          {failedAssessments.map((assessment, index) => (
            <tr key={index} className="failed-table-row">
              <td className="table-data">{assessment.name}</td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default StudentAssessmentHistory;
