import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { deleteClass } from "../services/APIClient";
import { useNavigate } from "react-router";
import "./EditClassDetails.css";

function EditClassDetailsSpecial(props) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  // State for tracking if the name has been updated

  const deleteClassHandler = () => {
    deleteClass(props.classDetails.id);
    navigate("/classadmindashboard");
  };

  let classDetails = props.classDetails;
  let name = props.name;
  let gradeLevel = props.gradeLevel;
  let dateCreated = classDetails.date_created;
  let dateUpdated = classDetails.date_updated;
  let setIsNameUpdated = props.setIsNameUpdated;
  let isNameUpdated = props.isNameUpdated;

  let yearCreated;
  if (dateCreated) {
    yearCreated = dateCreated.substring(0, 4);
  }

  let yearUpdated;
  if (dateUpdated) {
    yearUpdated = dateUpdated.substring(0, 4);
  }

  // Initial state for updated name and grade level
  let [updatedName, setUpdatedName] = useState(`24-`);
  let [updatedGradeLevel, setUpdatedGradeLevel] = useState(gradeLevel);

  function nameUpdate(e) {
    let value = e.target.value;

    // Remove '23-' from the beginning of the value if present
    if (value.startsWith("23-")) {
      value = value.replace("23-", "");
    }

    // Ensure the new class name always starts with '24-'
    if (!value.startsWith("24-")) {
      setUpdatedName(`24-${value}`);
    } else {
      setUpdatedName(value);
    }

    // Check if the user has made any updates to the class name beyond the "24-" prefix
    const hasUpdated = value.replace("24-", "").length > 0;
    setIsNameUpdated(hasUpdated);

    // Reset to default if input is empty
    if (value.length === 0) {
      setUpdatedName(`24-${name.replace("23-", "")}`);
      setIsNameUpdated(false);
    }
  }

  // Log changes to the isNameUpdated state
  useEffect(() => {
    if (isNameUpdated) {
      console.log("Class name has been updated.");
    } else {
      console.log("Class name has not been updated.");
    }
  }, [isNameUpdated]);

  props.sendUpdateClassName(updatedName);

  function gradeUpdate(e) {
    setUpdatedGradeLevel(e.target.value);

    if (e.target.value.length === 0) {
      setUpdatedGradeLevel(gradeLevel);
    }
  }

  props.sendUpdateGrade(updatedGradeLevel);

  // Inline styles for the text box

  return (
    <>
      <Form className="form">
        <Form.Group as={Row} className="mb-3" controlId="class-name">
          <Form.Label className="label" column sm="4">
            <p>New Class Name</p>
          </Form.Label>
          <Col sm="8">
            <Form.Control
              value={updatedName}
              size="md"
              type="text"
              onChange={nameUpdate}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="year">
          <Form.Label className="label" column sm="4">
            <p>School Year</p>
          </Form.Label>
          <Col sm="8">
            <Form.Control
              placeholder={yearUpdated}
              size="md"
              type="text"
              disabled
            />
          </Col>
        </Form.Group>
      </Form>
    </>
  );
}

export default EditClassDetailsSpecial;
