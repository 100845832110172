import React from "react";

// Define standard score ranges and descriptors
const ranges = {
  profoundDifficulty: { min: 0, max: 24 },
  severeDifficulty: { min: 25, max: 39 },
  moderateDifficulty: { min: 40, max: 54 },
  mildDifficulty: { min: 55, max: 69 },
  belowAverage: { min: 70, max: 85 },
  average: { min: 86, max: 115 },
  aboveAverage: { min: 116, max: 130 },
  significantlyAboveAverage: { min: 131, max: Infinity },
};

// Define styles for the recommendations container
const groupSectionStyle = {
  marginTop: "20px",
  padding: "20px",
  fontSize: "16px",
  borderRadius: "8px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  lineHeight: "1.5",
  maxWidth: "600px",
  margin: "20px auto",
  textAlign: "center", // Center the text
  display: "flex", // Use flexbox
  flexDirection: "column", // Arrange content vertically
  alignItems: "center", // Center content horizontally
};

const ScoreStatements = ({ selectedChild }) => {
  if (!selectedChild || !selectedChild.tasks) {
    return <div>No data available</div>;
  }

  const studentName = selectedChild.student.name;
  const tasks = selectedChild.tasks;
  console.log(tasks, "TASKS");

  // Classify tasks based on their scores
  const classifiedTasks = tasks.map((task) => {
    const score = task.standardScore;
    const taskName = task.name;

    if (score <= ranges.profoundDifficulty.max) {
      return {
        range: "Significantly below average",
        descriptor: "profoundDifficulty",
        taskName,
      };
    } else if (score <= ranges.severeDifficulty.max) {
      return {
        range: "Significantly below average",
        descriptor: "severeDifficulty",
        taskName,
      };
    } else if (score <= ranges.moderateDifficulty.max) {
      return {
        range: "Significantly below average",
        descriptor: "moderateDifficulty",
        taskName,
      };
    } else if (score <= ranges.mildDifficulty.max) {
      return {
        range: "Significantly below average",
        descriptor: "mildDifficulty",
        taskName,
      };
    } else if (score <= ranges.belowAverage.max) {
      return { range: "Below average", descriptor: "belowAverage", taskName };
    } else if (score <= ranges.average.max) {
      return { range: "Average", descriptor: "average", taskName };
    } else if (score <= ranges.aboveAverage.max) {
      return { range: "Above average", descriptor: "aboveAverage", taskName };
    } else {
      return {
        range: "Significantly above average",
        descriptor: "significantlyAboveAverage",
        taskName,
      };
    }
  });

  const significantBelowAverageTasks = classifiedTasks.filter(
    (task) => task.range === "Significantly below average"
  );
  const belowAverageTasks = classifiedTasks.filter(
    (task) => task.range === "Below average"
  );
  const averageTasks = classifiedTasks.filter(
    (task) => task.range === "Average"
  );
  const aboveAverageTasks = classifiedTasks.filter(
    (task) => task.range === "Above average"
  );
  const significantlyAboveAverageTasks = classifiedTasks.filter(
    (task) => task.range === "Significantly above average"
  );

  // Create the appropriate statements
  const statements = [];

  if (significantBelowAverageTasks.length > 0) {
    const subtests = significantBelowAverageTasks
      .map((task) => task.taskName)
      .join(", ");
    statements.push(
      `${studentName} is in the significantly below average range (SS ≤ 69) in ${subtests} and will require extensive support in this area.`
    );
  }

  if (belowAverageTasks.length > 0) {
    const subtests = belowAverageTasks.map((task) => task.taskName).join(", ");
    statements.push(
      `${studentName} is in the below average range (SS 70 to 85) in ${subtests} and will require extra teaching in this area.`
    );
  }

  if (averageTasks.length === tasks.length) {
    statements.push(
      `${studentName} is in the average range (SS 86 to 115) in all subtests and does not require extra teaching at this time.`
    );
  } else if (averageTasks.length > 0) {
    const subtests = classifiedTasks
      .filter((task) => task.range !== "Average")
      .map((task) => task.taskName)
      .join(", ");

    if (subtests) {
      statements.push(
        `${studentName} is in the average range (SS 86 to 115) in all subtests except (${subtests}) and may require extra teaching in this area.`
      );
    } else {
      statements.push(
        `${studentName} is in the average range (SS 86 to 115) in all subtests and does not require extra teaching at this time.`
      );
    }
  }

  if (aboveAverageTasks.length > 0) {
    const subtests = aboveAverageTasks.map((task) => task.taskName).join(", ");
    statements.push(
      `${studentName} is in the above average range (SS 116 to 130) in ${subtests} and may require extension activities in this area.`
    );
  }

  if (significantlyAboveAverageTasks.length > 0) {
    const subtests = significantlyAboveAverageTasks
      .map((task) => task.taskName)
      .join(", ");
    statements.push(
      `${studentName} is in the significantly above average range (SS ≥ 131) in ${subtests} and will require extension activities in this area.`
    );
  }

  return (
    <div style={groupSectionStyle}>
      {statements.map((statement, index) => (
        <p key={index}>{statement}</p>
      ))}
    </div>
  );
};

export default ScoreStatements;
