import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import { useSearchParams } from "react-router-dom";

import HeadingBanner from "../components/HeadingBanner";
import OrientationPrompt from "../components/OrientationPrompt";
import SimpleNavigationButton from "../components/SimpleNavigationButton";

import "../components/Recovery.css";
import Button from "react-bootstrap/esm/Button";
import { requestPasswordReset, updatePassword } from "../services/APIClient";

import { Link } from "react-router-dom";

function Recovery() {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isPasswordSent, setIsPasswordSent] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const paramEmail = searchParams.get("email");

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (!email && !!paramEmail) {
      setEmail(paramEmail);
      console.log(`Email parameter set from URL: ${paramEmail}`);
    }
  }, [paramEmail, email]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const sendEmail = () => {
    setError(""); // Reset error
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    console.log(`Requesting password reset for email: ${email}`);
    requestPasswordReset(email);
    setIsEmailSent(true);
    console.log("Password reset email sent.");
  };

  const sendPassword = () => {
    setError(""); // Reset error
    console.log("Attempting to save new password.");
    if (password !== passwordConfirm) {
      setError("Passwords do not match! Please retry.");
      console.log("Error: Passwords do not match.");
    } else if (password.length < 8) {
      setError("Password must be at least 8 characters long. Please retry.");
      console.log("Error: Password length is less than 8 characters.");
    } else {
      console.log("password:", password);
      console.log("passwordConfirm:", passwordConfirm);
      console.log(token);

      updatePassword(password, token)
        .then((response) => {
          console.log("Password update successful:", response);
          setIsPasswordSent(true);
          setError("");
          console.log("New password has been updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating password:", error.response);
          setError("Failed to update password. Please try again.");
        });
    }
  };

  let content = "";

  if (isPasswordSent) {
    content = (
      <div className="recovery d-flex flex-column align-items-center">
        <div className="title">New Password Saved Successfully!</div>
        <Link to={"/"}>
          <button className="blue-button">Return to Login Screen</button>
        </Link>
      </div>
    );
  } else if (!token) {
    content = (
      <div className="recovery d-flex flex-column align-items-center text-center">
        <img
          src="/assets/imgs/forgotpw.png"
          alt="Forgot Password"
          className="recovery-image"
          style={{ width: "18%", height: "auto", marginTop: "5%" }}
        />
        <h2 className="title">
          Enter your Email Address to Receive a Password Reset Link
        </h2>
        {error && <div className="alert alert-danger mt-2">{error}</div>}
        {isEmailSent && !error && (
          <div className="message alert alert-success mt-2">
            <p>Email Sent! Please check your inbox.</p>
            <p>
              If you don't see our email within the next 5 minutes, kindly reach
              out to us at info@alpaca-assessment.com for further assistance.
            </p>
          </div>
        )}
        <input
          type="text"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          className="form-control my-2"
        />
        <br />
        <button className="green-button" onClick={sendEmail}>
          Send Link
        </button>
        <SimpleNavigationButton path="/" label="Cancel" colour="white" />
      </div>
    );
  } else {
    content = (
      <div className="recovery d-flex flex-column align-items-center mt-5 text-center">
        <div className="title">
          Please enter the new password for account: {email}
        </div>
        <div className="message">
          Passwords must be at least 8 characters long, and contain at least one
          number and one Capital Letter
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            className="form-control my-2"
          />
          <br />
        </div>
        <div>
          <label htmlFor="passwordConfirm">Confirm Password</label>
          <input
            type="password"
            id="passwordConfirm"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            className="form-control my-2"
          />
          <br />
        </div>
        <Button onClick={sendPassword} className="blue-button">
          Save Password
        </Button>
        {!!error && <div className="alert alert-danger mt-2">{error}</div>}
      </div>
    );
  }

  return (
    <Container fluid>
      <HeadingBanner
        isLoggedIn={false}
        cloud={true}
        name={"Assessing Letter Knowledge & Phonemic Awareness Class Assistant"}
      />
      <div id="signup">{content}</div>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default Recovery;
