import React from "react";

import { BsPlusCircle } from "react-icons/bs";

import { Link } from "react-router-dom";

import "./ClassCard.css";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

function CreateClassCard(props) {
  let name = props.name;
  let schoolid = props.schoolid;
  let teacherid = props.teacherid;
  let classes = props.classes;
  let rollNumber = props.rollNumber;
  let teacherRoles = props.teacherRoles;
  let countryId = props.countryId;

  // console.log(props)

  return (
    <Col className="col-4">
      <Card className="card-detail">
        <Link
          className="link"
          to="/createclass"
          state={{
            name: { name },
            schoolid: { schoolid },
            teacherid: { teacherid },
            classes: { classes },
            rollNumber: { rollNumber },
            teacherRoles: {teacherRoles},
            countryId: {countryId}
          }}
        >
          <BsPlusCircle size={65} className="class-card-img" />
          <p className="class-card-text">Create a New Class!</p>
        </Link>
      </Card>
    </Col>
  );
}

export default CreateClassCard;
