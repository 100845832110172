import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function AlreadyInvitedModal({ show, handleClose, alreadyInvitedColleagues }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Already Invited Colleagues</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The following colleagues have already been invited/are already a user:
        </p>
        <ul>
          {alreadyInvitedColleagues.map((colleague, index) => (
            <li key={index}>{colleague.email}</li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlreadyInvitedModal;
