import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SandboxModal from "./SandboxModal";
import "./SandboxCard.css";
import "./IntroVideoButton.css";

function IntroVideoButton({ title, documentUrl }) {
  const [showModal, setShowModal] = useState(false);
  // console.log("documentUrl:", documentUrl);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  let iframe = '<iframe width="672" height="378" src="https://www.youtube.com/embed/a8f3DAVaCl0?si=QkYSgRgab-8H1OE2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'

  return (
    <Col className="col-3">
      {documentUrl ? (
        <a
          href={documentUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Card className="card-detail" style={{ backgroundColor: "#f9b540", pointerEvent: "On" }}>
            <div>
              <img
                src="./assets/imgs/Alpaca-logo-white-outline.png"
                className="class-card-img"
                alt="alpaca-logo"
              />
              <p
                className="sandbox-card-text"
                style={{ color: "white", fontSize: "20px" }}
              >
                Getting Started with the Platform!!
              </p>
            </div>
          </Card>
        </a>
      ) : (
        <Card
          className="intro-vid-card-detail"
          onClick={iframe ? openModal : undefined}
          style={{ backgroundColor: "#f9b540", marginLeft: '70%' }}
        >
          <div>
            <img
              src="./assets/imgs/Alpaca-logo-white-outline.png"
              className="class-card-img"
              alt="alpaca-logo"
            />
            <p className="sandbox-card-text" style={{ color: "white" }}>
              Getting Started with the Platform!!
            </p>
          </div>
        </Card>
      )}
      {iframe && (
        <SandboxModal
          title={'Getting Started with the Platform'}
          iframe={iframe}
          showModal={showModal}
          closeModal={closeModal}
        />
      )}
    </Col>
  );
}

export default IntroVideoButton;
