import React from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { BsFillHandThumbsUpFill } from "react-icons/bs";

import "./RecommendationsButton.css";

function RecommendationsButton(props) {
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let name = props.name;
  let gradeLevel = props.gradeLevel;

  let onResultsPage = props.onResultsPage;

  return (
    <Link
      to={`/recommendations`}
      state={{
        classDetails: { classDetails },
        teachers: { teachers },
        sharedWith: { sharedWith },
        name: { name },
        gradeLevel: { gradeLevel },
      }}
    >
      {onResultsPage ?
        <button
          className="blue-button"
        >
          Recommendations
        </button> :
        <button
          className="blue-button recommedations-button-styling"
        > <BsFillHandThumbsUpFill size={30} />
          <p className="recommedations-button-font">
            Recommendations
          </p>
        </button>}
    </Link>
  );
}

export default RecommendationsButton;
