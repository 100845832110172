import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import "./ClassOverview.css";

import TaskOptions from "./TaskOptions";
import ReportsButton from "./ReportsButton";
import ClassStudents from "./ClassStudents";
import SimpleNavigationButton from "./SimpleNavigationButton";
import GradeCard from "./GradeCard";
import { getAssessmentTypes } from "../services/APIClient";

function RetestTaskDetailOverview(props) {
  // Class Personnel
  let classDetails = props.classDetails;
  let classid = classDetails.id;
  let gradeLevel = props.gradeLevel;
  let teachers = props.teachers;
  let teacherid = props.teacherid;
  let students = props.students;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;
  let schoolid = props.schoolid;
  let name = props.name

  const [gradeLevel1Assessments, setGradeLevel1Assessments] = useState([]);
  const [gradeLevel2Assessments, setGradeLevel2Assessments] = useState([]);
  const [error, setError] = useState(null);
  const [assessments, setAssessments] = useState([]);

  console.log(students, "overview");
  console.log(props);

  useEffect(() => {
    getAssessmentTypes()
      .then((response) => {
        const filteredAssessments = response.filter((assessment) => assessment.task === true);
        setAssessments(filteredAssessments);
      })
      .catch(() => setError("Failed to fetch assessments."));
  }, []);

  console.log(assessments);

  useEffect(() => {
    const gradeLevel1Arr = [];
    const gradeLevel2Arr = [];

    assessments.forEach((assessment) => {
      if (assessment.grade_level1) {
        gradeLevel1Arr.push(assessment);
      }
      if (assessment.grade_level2) {
        gradeLevel2Arr.push(assessment);
      }
    });

    setGradeLevel1Assessments(gradeLevel1Arr);
    setGradeLevel2Assessments(gradeLevel2Arr);
  }, [assessments]);

  console.log("gradeLevel1Assessments:", gradeLevel1Assessments);
  console.log("gradeLevel2Assessments:", gradeLevel2Assessments);

  const prefix = gradeLevel1Assessments[0]?.name.split(' ')[0];

  const getRelevantAssessments = () => {
    if (gradeLevel1) {
      return gradeLevel1Assessments;
    } else if (gradeLevel2) {
      return gradeLevel2Assessments;
    } else {
      return [];
    }
  };

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-7">
            <Row className="inline-block">
              <Col className="col-6">
                <h2 className="page-description-header">Retest Tasks Overview</h2>
              </Col>
              <Col className="col-3">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />
              </Col>
            </Row>
            <p className="paragraph-font-size">
              Select the screener you would like to use to retest the tasks.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <TaskOptions
              classAssessmentsValues={getRelevantAssessments()}
              classDetails={classDetails}
              teachers={teachers}
              schoolid={schoolid}
              teacherid={teacherid}
              name={name}
            />
            <br></br>
            <Col>
              <SimpleNavigationButton
                path="/retesttaskdashboard"
                label="Back"
                colour="blue"
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RetestTaskDetailOverview;
