import * as XLSX from 'xlsx';

export function exportToExcel(sheetsData, fileName) {
  const workbook = XLSX.utils.book_new();

  Object.entries(sheetsData).forEach(([sheetName, data]) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}


// export function exportToExcel(data, fileName) {
//   const workbook = XLSX.utils.book_new();
//   const worksheet = XLSX.utils.json_to_sheet(data);
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//   XLSX.writeFile(workbook, `${fileName}.xlsx`);
// }


// function getScoreColor(score) {
//     if (score < 0.2) return { fill: { fgColor: { rgb: "FFFF0000" } } }; // Red for scores < 0.2
//     if (score >= 0.2 && score < 0.4) return { fill: { fgColor: { rgb: "FFFFFF00" } } }; // Yellow for scores >= 0.2 and < 0.4
//     return {};
// }

// function createStyledWorksheet(data) {
//     const worksheet = XLSX.utils.json_to_sheet(data, {skipHeader: true});
//     const ref = XLSX.utils.decode_range(worksheet['!ref']);

//     for (let R = ref.s.r; R <= ref.e.r; ++R) {
//         for (let C = ref.s.c; C <= ref.e.c; ++C) {
//             const cell_address = { c: C, r: R };
//             const cell_ref = XLSX.utils.encode_cell(cell_address);

//             const cell = worksheet[cell_ref];
//             if (cell && R > 0) { // Skip header row
//                 const score = parseFloat(cell.v);
//                 if (!isNaN(score)) {
//                     cell.s = getScoreColor(score);
//                 }
//             }
//         }
//     }
//     return worksheet;
// }

// export function exportToExcel(sheetsData, fileName) {
//     const workbook = XLSX.utils.book_new();

//     Object.entries(sheetsData).forEach(([sheetName, data]) => {
//         const worksheet = createStyledWorksheet(data);
//         XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
//     });

//     XLSX.writeFile(workbook, `${fileName}.xlsx`);
// }
