import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "./ReturnToClassOverview.css";

function ReturnToClassOverview(props) {
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let name = props.name;
  let gradeLevel = props.gradeLevel;
  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let schoolid = props.schoolid;
  let student = props.student;

  return (
    <Link
      to={`/class/detail`}
      state={{
        classDetails: { classDetails },
        teachers: { teachers },
        name: { name },
        gradeLevel: { gradeLevel },
        rollNumber: { rollNumber },
        teacherEmail: { teacherEmail },
        schoolid: { schoolid },
        student: { student },
      }}
    >
      <button className="white-button">Back</button>
    </Link>
  );
}

export default ReturnToClassOverview;
