import React, { useState, useEffect } from "react";
import "./ReportTableRow.css";
import {
  upsertJiSiReport,
  getJiSiReportByStudentId,
} from "../services/APIClient";

function JISIReportTableRow({
  student,
  dummyHeaderArray,
  getReadingScoreClass,
  handleShowReadingScoreModal,
  handleShowLiteracyModal,
  handleShowAdditionalNeedsModal,
  handleShowSupportPlanModal,
  handleShowEalModal,
  handleShowSetModal,
  handleShowAttendanceAffectingScoresModal,
  handleShowConfirmationModalEAL,
  handleShowConfirmationModalSET,
  handleShowConfirmationModalAttendance,
  setSaveClicked,
  // currentStudentId,
  teacherid,
}) {
  const [readingScoreNotes, setReadingScoreNotes] = useState([]);
  const [literacyObservations, setLiteracyObservations] = useState("");
  const [additionalNeeds, setAdditionalNeeds] = useState([]);
  const [supportPlan, setSupportPlan] = useState([]);
  const [eal, setEal] = useState(false);
  const [set, setSet] = useState(false);
  const [attendanceAffectingScores, setAttendanceAffectingScores] =
    useState(false);

  const [readingScoreButtonClicked, setReadingScoreButtonClicked] =
    useState(false);
  const [literacyButtonClicked, setLiteracyButtonClicked] = useState(false);
  const [additionalNeedsButtonClicked, setAdditionalNeedsButtonClicked] =
    useState(false);
  const [supportPlanButtonClicked, setSupportPlanButtonClicked] =
    useState(false);
  const [ealModalButtonClicked, setEalModalButtonClicked] = useState(false);
  const [setModalButtonClicked, setSetModalButtonClicked] = useState(false);
  const [attendanceModalButtonClicked, setAttendanceModalButtonClicked] =
    useState(false);

  const [currentStudentId, setCurrentStudentId] = useState("");

  // UseEffect to log currentStudentId when it changes
  useEffect(() => {
    console.log(currentStudentId);
  }, [currentStudentId]);

  // Fetch JiSi report when student id changes
  useEffect(() => {
    if (student.id) {
      getJiSiReportByStudentId(student.id)
        .then((response) => {
          if (response) {
            setReadingScoreNotes(response.readingscorenotes || []);
            setLiteracyObservations(response.literacyobservations || "");
            setAdditionalNeeds(response.additionalneeds || []);
            setSupportPlan(response.supportplan || []);
            setEal(response.eal || false);
            setSet(response.set || false);
            setAttendanceAffectingScores(
              response.attendanceaffectingscores || false
            );
          }
        })
        .catch((error) => console.error("Error fetching JiSi report:", error));
    }
  }, [student.id]);

  useEffect(() => {
    const saveReport = () => {
      if (currentStudentId) {
        const reportData = {
          studentid: currentStudentId,
          teacherid: teacherid,
          eal: eal,
          set: set,
          attendanceaffectingscores: attendanceAffectingScores,
        };

        console.log("Saving report data:", reportData);

        upsertJiSiReport(reportData)
          .then((response) => {
            console.log("Upsert JISI Report Success:", response);
          })
          .catch((error) => console.error("Error:", error));
      } else {
        console.log("No student selected. Skipping save operation.");
      }
    };

    // Call the save function whenever eal, set, or attendanceAffectingScores change
    saveReport();
  }, [eal, set, attendanceAffectingScores, currentStudentId, teacherid]);

  const getButtonStyle = (buttonClicked, dataExists) => {
    return buttonClicked || dataExists ? { backgroundColor: "#ffffcc" } : {};
  };

  const getCircularButtonStyle = (
    buttonClicked,
    dataExists,
    borderColor = "black"
  ) => {
    const size = "40px";
    return {
      borderRadius: "50%",
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      margin: "auto",
      border: `2px solid ${borderColor}`, // Add border property
      backgroundColor: "white",
    };
  };

  return (
    <tr
      key={student.id}
      className="table-row student-results-table-row"
      data-student-id={student.id}
    >
      <td className="centre-student-items align-left">{student.name}</td>
      <td className="centre-student-items task-badge-parent">
        {student.readingScore !== undefined && student.readingScore !== null ? (
          student.readingScore === "No completed tasks" ? (
            <span className="reading-score">
              {student.readingScore}
            </span>
          ) : (
            <span
              className={`reading-score ${getReadingScoreClass(
                student.readingScore
              )}`}
            >
              {student.readingScore}%
            </span>
          )
        ) : null}
      </td>
      {dummyHeaderArray.map((header, index) => (
        <td key={index} className="centre-student-items">
          {header === "Reading Score Notes" ? (
            <button
              style={getButtonStyle(
                readingScoreButtonClicked,
                readingScoreNotes.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowReadingScoreModal(student.name, student.id);
                setReadingScoreButtonClicked(true);
              }}
            >
              {readingScoreButtonClicked || readingScoreNotes.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "General Literacy" ? (
            <button
              style={getButtonStyle(
                literacyButtonClicked,
                literacyObservations.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowLiteracyModal(student.name, student.id);
                setLiteracyButtonClicked(true);
              }}
            >
              {literacyButtonClicked || literacyObservations.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "Additional Needs" ? (
            <button
              style={getButtonStyle(
                additionalNeedsButtonClicked,
                additionalNeeds.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowAdditionalNeedsModal(student.name, student.id);
                setAdditionalNeedsButtonClicked(true);
              }}
            >
              {additionalNeedsButtonClicked || additionalNeeds.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "Support Plan" ? (
            <button
              style={getButtonStyle(
                supportPlanButtonClicked,
                supportPlan.length > 0
              )}
              className="white-button"
              onClick={() => {
                handleShowSupportPlanModal(student.name, student.id);
                setSupportPlanButtonClicked(true);
              }}
            >
              {supportPlanButtonClicked || supportPlan.length > 0 ? (
                <img
                  src="./assets/imgs/eyeIcon.svg"
                  alt="View"
                  style={{ width: "100%" }}
                />
              ) : (
                <i className="bi bi-pencil-square"></i>
              )}
            </button>
          ) : header === "EAL" ? (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <input
                type="checkbox"
                checked={eal}
                onChange={() => {
                  setCurrentStudentId(student.id);
                  setEal((prev) => !prev);
                  setEalModalButtonClicked(true);
                }}
                style={{
                  appearance: "none",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "2px solid #2c7693",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              />
              {eal && (
                <img
                  src="./assets/imgs/greenTick.svg"
                  alt="Checked"
                  style={{ width: "15px", position: "absolute" }}
                />
              )}
            </label>
          ) : header === "SET" ? (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <input
                type="checkbox"
                checked={set}
                onChange={() => {
                  setCurrentStudentId(student.id);
                  setSet((prev) => !prev);
                  setEalModalButtonClicked(true);
                }}
                style={{
                  appearance: "none",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "2px solid #2c7693",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              />
              {set && (
                <img
                  src="./assets/imgs/greenTick.svg"
                  alt="Checked"
                  style={{ width: "15px", position: "absolute" }}
                />
              )}
            </label>
          ) : header === "Attendance Affecting Scores" ? (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <input
                type="checkbox"
                checked={attendanceAffectingScores}
                onChange={() => {
                  setCurrentStudentId(student.id);
                  setAttendanceAffectingScores((prev) => !prev);
                  setEalModalButtonClicked(true);
                }}
                style={{
                  appearance: "none",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "2px solid #2c7693",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
              />
              {attendanceAffectingScores && (
                <img
                  src="./assets/imgs/greenTick.svg"
                  alt="Checked"
                  style={{ width: "15px", position: "absolute" }}
                />
              )}
            </label>
          ) : (
            <button className="white-button">
              <i className="bi bi-pencil-square"></i>
            </button>
          )}
        </td>
      ))}
    </tr>
  );
}

export default JISIReportTableRow;
