import React from "react";
import "./OrientationPrompt.scss";

const OrientationPrompt = () => {
  return (
    <div className="orientation-prompt">
      <div className="turning-animation">
        <img
          src="/assets/imgs/landscapeSpinner.png"
          alt="Icon"
          // className="icon"
        />
      </div>
      <img
        src="/assets/imgs/alpaca-sunglasses-side.png"
        alt="Alpaca"
        className="alpaca-image"
      />
      <div className="speech-bubble-container">
        <img
          src="/assets/imgs/Speech-bubble-05.png"
          alt="Speech Bubble"
          className="speech-bubble-image"
        />
        <p className="speech-bubble-text">
          Please rotate<br></br>your device to<br></br>landscape mode
        </p>
      </div>
    </div>
  );
};

export default OrientationPrompt;
