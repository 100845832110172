import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import EditClassDetails from "./EditClassDetails";
import SaveClassDetails from "./SaveClassDetails";
import EditClassTeacherDetails from "./EditClassTeacherDetails";
import BackButton from "./BackButton";
import DeleteClassButton from "./DeleteClassButton";
import "./ClassOverview.css";

function ClassSettingsOverview(props) {
  let group = props.group;
  let classDetails = props.classDetails.classDetails;
  let name = props.name;
  let teachers = props.teachers.teachers;
  let teacherid = props.teacherid;
  let classOwnerId = group.classOwnerId;

  let gradeLevel = props.gradeLevel;

  let [newClassName, setNewClassName] = useState(name || "");
  let [classTeacherName, setClassTeacherName] = useState("");
  const [newClassTeacherId, setNewClassTeacherId] = useState("");
  const [classOwnerName, setClassOwnerName] = useState("");

  // Update class name
  const updateClassName = (name) => {
    console.log("Updating Class Name:", name); // Log the name received from the child component
    // Avoid setting to empty string
    if (name.trim() !== "") {
      setNewClassName(name);
    }
  };

  useEffect(() => {
    if (teachers && teacherid) {
      const teacher = teachers.find((teacher) => teacher.id === teacherid);
      if (teacher) {
        setClassTeacherName(teacher.name);
      }
    }
  }, [teacherid, teachers]);
  useEffect(() => {
    const classOwner = teachers.find((teacher) => teacher.id === classOwnerId);
    if (classOwner) {
      setClassOwnerName(classOwner.name);
    }
  }, [classOwnerId]);

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col>
            <h2 className="page-description-header">{name}</h2>
            <p>Edit the details of your class.</p>
          </Col>
          <Col>
            <Row>
              {/* <Col>
                <DeleteClassButton classDetails={classDetails} />
              </Col> */}
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="button-container">
          <Col className="col-7 vertical-border">
            <EditClassDetails
              group={group}
              classDetails={classDetails}
              teachers={teachers}
              name={name}
              sendUpdateClassName={updateClassName}
            />
            <EditClassTeacherDetails
              currentTeacherName={classTeacherName}
              teachers={teachers}
              setNewClassTeacherId={setNewClassTeacherId}
              classOwnerName={classOwnerName}
            />
            <Row className="button-placer">
              <Col className="col-6">
                <BackButton />
              </Col>
              <Col className="col-6">
                <SaveClassDetails
                  newClassName={newClassName}
                  classDetails={classDetails}
                  teachers={teachers}
                  gradeLevel={gradeLevel}
                  className={group.name}
                  newClassTeacherId={newClassTeacherId}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ClassSettingsOverview;
