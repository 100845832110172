import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { upsertJiSiReport } from "../services/APIClient";

const SetSetNameModal = ({
  show,
  handleClose,
  studentName,
  setSetName,
  setName,
  currentStudentId,
  teacherid,
}) => {
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [set, setSet] = useState(false);
  console.log(setName, "id");

  const handleYesClick = () => {
    // setSet(setName);
    setConfirmChanges(true);
  };

  const handleNoClick = () => {
    handleClose();
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
    setConfirmChanges(false);
  };

  const handleModalClose = () => {
    handleClose();
    setConfirmChanges(false);
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      setname: setName,
    };

    console.log("Saving report data:", reportData);

    if (!currentStudentId || !teacherid) {
      console.error("Error: Missing required fields (studentid or teacherid).");
      return;
    }

    upsertJiSiReport(reportData)
      .then((response) => {
        console.log("Upsert JISI Report Success:", response);
        // Handle response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.response) {
          console.error("Server Response Data:", error.response.data);
        }
      });
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Select the SET Teacher
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {confirmChanges ? (
          <p>Are you sure you want to save changes for {studentName}?</p>
        ) : (
          <p>Do you want to set {setName} as SET?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {confirmChanges ? (
          <div className="button-group">
            <button
              variant="primary"
              onClick={saveChanges}
              className="modal-button yes-button"
            >
              Confirm
            </button>
            <button
              variant="secondary"
              onClick={handleModalClose}
              className="modal-button no-button"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="button-group">
            <button
              // variant="secondary"
              onClick={handleYesClick}
              className="modal-button yes-button"
            >
              Yes
            </button>
            <button
              // variant="secondary"
              onClick={handleNoClick}
              className="modal-button no-button"
            >
              No
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SetSetNameModal;
