import React from "react";

import { BsPlusCircle } from 'react-icons/bs';

import { Link } from "react-router-dom";

import './ClassCard.css'

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function CreateTrialClassCard(props) {

  let name = props.name;
  let schoolid = props.schoolid;
  let teacherid = props.teacherid;
  let classes = props.classes;

  // console.log(props)

  return (
    <Col className="col-4">
      <Card className="trial-card-detail">
        <Link
          className="link"
          to='/createtrialclass'
          state={{
            name: { name },
            schoolid: { schoolid },
            teacherid: { teacherid },
            classes: { classes }
          }}
        >
          <BsPlusCircle size={65} className="trial-class-card-img"/>
          <p className="trial-class-card-text">Create a Fake Class!</p>
        </Link>
      </Card>
    </Col>
  );
}

export default CreateTrialClassCard;
