import React from "react";
import Table from "react-bootstrap/esm/Table";
import { BsCheck2, BsX } from "react-icons/bs";

import "./TaskResultsTable2.css";

function TaskResultsTable2(props) {
  let taskAnswers = props.taskAnswers;
  // console.log(taskAnswers, "Task Answers");

  const correctAnswersTotal = taskAnswers
    .filter(({ correct }) => correct === true)
    .reduce((n, { correct }) => n + correct, 0);

  let incorrectAnswersTotal = taskAnswers.filter(
    ({ correct }) => correct === false
  ).length;

  return (
    <Table className="resultsTable">
      <th className="task-results-table-head">
        <td>{taskAnswers.length} Items</td>
        <td>
          <BsCheck2 size={45} style={{ fill: "#16971b" }} />
          Correct Answers
        </td>
        <td>
          <BsX size={45} style={{ fill: "#b91414" }} />
          Incorrect Answers
        </td>
      </th>
      <tbody>
        {taskAnswers.map((data, index) => (
          <tr key={index}>
            <td>
              {index + 1}. {data.correctAnswer}
            </td>
            {data.correct === true ? (
              <td>
                <BsCheck2 size={20} style={{ fill: "#16971b" }} />
              </td>
            ) : (
              <td>
                <BsX size={20} style={{ fill: "#b91414" }} />
              </td>
            )}
            {data.correct === false ? <td>{data.givenAnswer}</td> : <td></td>}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr style={{ borderColor: "white" }}>
          <td>Total</td>
          <td>{correctAnswersTotal}</td>
          <td>{incorrectAnswersTotal}</td>
        </tr>
      </tfoot>
    </Table>
  );
}

export default TaskResultsTable2;
