import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import PaginationComponent from "./PaginationComponent";

import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import QRCodeGenerator from "./QRCodeGenerator";
import PrintAllLoginsButton from "./PrintAllLoginsButton";

import { FaQrcode } from "react-icons/fa";

import "./ViewLogins.css";

function ViewLogins(props) {
  let name = props.name;
  let label = props.label;
  let qrCodeDetails = props.qrCodeDetails;

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <button
        className="blue-button qr-button-styling"
        onClick={() => setModalShow(true)}
      >
        <FaQrcode size={30} />
        <p className="qr-button-font">{label}</p>
      </button>

      <ViewLoginsModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        name={name}
        qrCodeDetails={qrCodeDetails}
      />
    </>
  );
}

function ViewLoginsModal(props) {
  let name = props.name;
  // let students = props.students;
  let qrCodeDetails = props.qrCodeDetails;

  let studentsQRDetails = [];
  if (qrCodeDetails) {
    qrCodeDetails.forEach((student) => {
      studentsQRDetails.push(student);
    });
  }

  studentsQRDetails.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  let [itemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const startItemIndex = (currentPage - 1) * itemsPerPage;
  const endItemIndex = startItemIndex + itemsPerPage;
  const currentItems = studentsQRDetails?.slice(startItemIndex, endItemIndex) || [];

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        {" "}
        {/* closebutton */}
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          {name} Student Logins
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {currentItems.map((student) => (
            <QRCodeGenerator student={student} />
          ))}
          <br></br>
          {studentsQRDetails.length <= itemsPerPage ? '' : (
            <PaginationComponent
              itemsPerPage={itemsPerPage}
              totalItems={studentsQRDetails}
              handlePageClick={handlePageClick}
            />
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <PrintAllLoginsButton
          name={name}
          label="Print Class QR Codes (using Portrait)"
          qrCodeDetails={qrCodeDetails}
        />
        <button className="white-button" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewLogins;
