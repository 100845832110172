import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import GradeCard from "./GradeCard";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

// import ViewQRDashLogins from "./ViewQRDashLogins"
import QRCodeGenerator from "./QRCodeGenerator";
import PrintAllLoginsButton from "./PrintAllLoginsButton";
import PaginationComponent from "./PaginationComponent";
import AlpacaInfoGesture from "../components/AlpacaInfoGesture";

import { getQR } from "../services/APIClient";

import "./QRClassCard.css";

function QRClassCard(props) {
  let classDetails = props.classDetails;
  let name = classDetails.name;
  let gradeLevel = classDetails.grade_level;
  let classid = classDetails.id;

  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  // console.log(props)
  const [speechText, setSpeechText] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  let [qrCodeDetails, setQRCodeDetails] = useState("");

  useEffect(() => {
    getQR(classid).then((response) => {
      setQRCodeDetails(response);
    });
    setSpeechText(
      `It looks like you still need to add students to your first classroom. Please go to "Admin" and into "My Classes" to do so.`
    );
  }, [classid]);

  // console.log(qrCodeDetails)

  return (
    // qrCodeDetails.length === 0 ? (
    //   <>
    //     <Col className="col-2">
    //     </Col>
    //     <Col className="alpaca-message-positioner">
    //       <AlpacaInfoGesture
    //         speechText={speechText}
    //       />
    //     </Col>
    //   </>
    // ) : (
    <>
      <Col className="col-4">
        <Card
          className={`${trialClass ? "trial-card-detail" : "card-detail"}`}
          onClick={() => setModalShow(true)}
        >
          <img
            src="./assets/imgs/Asset-class.webp"
            className="qr-class-card-img"
          ></img>
          <div>
            <p
              className={`${
                gradeLevel1 ? "grade-level-blue" : "grade-level-purple"
              }`}
            >
              {gradeLevel}
            </p>
            {qrCodeDetails?.length > 0 ? (
              <p className="class-card-text">
                <img
                  src="./assets/imgs/child.png"
                  className="class-card-img"
                  alt="Student Image"
                />
                x {qrCodeDetails.length}
              </p>
            ) : (
              <>
                <br></br>
                <p className="class-card-text">
                  Please add students to your class
                </p>
              </>
            )}
          </div>
          <p
            className={`${
              trialClass ? "trial-class-card-text" : "class-card-text"
            }`}
          >
            {name}
          </p>
        </Card>
        <ViewLoginsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          name={name}
          qrCodeDetails={qrCodeDetails}
        />
      </Col>
    </>
  );
  // );
}

function ViewLoginsModal(props) {
  let name = props.name;
  // let students = props.students;
  let qrCodeDetails = props.qrCodeDetails;

  let studentsQRDetails = [];
  if (qrCodeDetails) {
    qrCodeDetails.forEach((student) => {
      studentsQRDetails.push(student);
    });
  }

  studentsQRDetails.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  let [itemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const startItemIndex = (currentPage - 1) * itemsPerPage;
  const endItemIndex = startItemIndex + itemsPerPage;
  const currentItems =
    studentsQRDetails?.slice(startItemIndex, endItemIndex) || [];

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        {" "}
        {/* closebutton */}
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          {name} Student Logins
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {currentItems.length === 0 ? (
            <>
              <h4>There are currently no students in this classroom.</h4>
              <h4>
                Please go to "Admin" and then to "My Classes" in order to add
                students to this class.
              </h4>
            </>
          ) : (
            currentItems.map((student) => <QRCodeGenerator student={student} />)
          )}
          <br></br>
          {studentsQRDetails.length <= itemsPerPage ? (
            ""
          ) : (
            <PaginationComponent
              itemsPerPage={itemsPerPage}
              totalItems={studentsQRDetails.length}
              handlePageClick={handlePageClick}
            />
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {currentItems.length !== 0 && (
          <PrintAllLoginsButton
            name={name}
            label="Print Class QR Codes (using Portrait)"
            qrCodeDetails={qrCodeDetails}
          />
        )}
        <button className="white-button" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default QRClassCard;
