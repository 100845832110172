import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ScoreInterpretationTable.css"; // Ensure this file includes the styles

const ScoreInterpretationTable = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button
        onClick={() => setOpen(!open)}
        aria-controls="score-interpretation-table"
        aria-expanded={open}
        className="white-button"
      >
        {open
          ? "Hide score interpretation"
          : "Click here to see score interpretation"}
      </button>
      <Collapse in={open}>
        <div id="score-interpretation-table">
          <Table>
            <thead>
              <tr>
                <th>Score On Test</th>
                <th>Interpreted As</th>
              </tr>
            </thead>
            <tbody>
              <tr className="low-color">
                <td>
                  <span data-tooltip-id="tooltip0-20">0-20</span>
                </td>
                <td data-tooltip-id="tooltip0-20">Poor Performance</td>
              </tr>
              <tr className="med-color">
                <td>
                  <span data-tooltip-id="tooltip20-40">20-40</span>
                </td>
                <td data-tooltip-id="tooltip20-40">Fair Performance</td>
              </tr>
              <tr className="noRisk-color">
                <td>
                  <span data-tooltip-id="tooltip40-60">40-60</span>
                </td>
                <td data-tooltip-id="tooltip40-60">Good Performance</td>
              </tr>
              <tr className="noRisk-color">
                <td>
                  <span data-tooltip-id="tooltip60-80">60-80</span>
                </td>
                <td data-tooltip-id="tooltip60-80">Very Good Performance</td>
              </tr>
              <tr className="high-color">
                <td>
                  <span data-tooltip-id="tooltip80-100">80-100</span>
                </td>
                <td data-tooltip-id="tooltip80-100">Excellent Performance</td>
              </tr>
            </tbody>
          </Table>
          <ReactTooltip id="tooltip0-20" place="top" effect="solid">
            Child is having significant difficulty with this subtest
          </ReactTooltip>
          <ReactTooltip id="tooltip20-40" place="top" effect="solid">
            Child may have a difficulty with this subtest
          </ReactTooltip>
          <ReactTooltip id="tooltip40-60" place="top" effect="solid">
            Child had a good performance in this subtest
          </ReactTooltip>
          <ReactTooltip id="tooltip60-80" place="top" effect="solid">
            Child had a very good performance in this subtest
          </ReactTooltip>
          <ReactTooltip id="tooltip80-100" place="top" effect="solid">
            Child is proficient in this subtest
          </ReactTooltip>
        </div>
      </Collapse>
    </div>
  );
};

export default ScoreInterpretationTable;
