import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";
// import button from 'react-bootstrap/button';

import "./SubmitShareButton.css";

function SubmitShareButton(props) {
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let name = props.name;
  let gradeLevel = props.gradeLevel;
  let schoolid = props.schoolid;

  return (
    <Link
      to={`/class/detail`}
      state={{
        classDetails: { classDetails },
        teachers: { teachers },
        name: { name },
        gradeLevel: { gradeLevel },
        schoolid: { schoolid },
      }}
    >
      <button
        className="green-button"
        style={{
          border: "1px solid #16971b",
        }}
      >
        Share
      </button>
    </Link>
  );
}

export default SubmitShareButton;
