import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import TaskResultsTable2 from "./TaskResultsTable2.js";
import secondsToTime from "../services/secondsToTime";
import DeleteAnswerModal from "./DeleteAnswerModal.js";
import "./ResultsHistory.css";

function AllReportCardsHistory(props) {
  const {
    studentResults,
    isPrintOut,
    detailedView,
    teacherid,
    schoolid,
    students,
  } = props;

  const [assessmentResults, setAssessmentResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTask, setModalTask] = useState(null);
  const [modalAssessment, setModalAssessment] = useState(null);
  const [answerId, setAnswerId] = useState(null);
  const [sortedAssessmentResults, setSortedAssessmentResults] = useState([]);
  const [studentIds, setStudentIds] = useState([]);

  useEffect(() => {
    // Map over the studentResults to retain its structure
    const updatedStudentResults = Object.keys(studentResults).reduce(
      (acc, studentId) => {
        // Find the matching student from the students prop
        const matchingStudent = students?.find(
          (student) => student.id === studentId
        );

        // Add the student name to each assessment
        const assessmentsWithStudentName = studentResults[studentId].map(
          (assessment) => ({
            ...assessment,
            studentName: matchingStudent ? matchingStudent.name : "Unknown",
          })
        );

        // Keep the structure the same
        acc[studentId] = assessmentsWithStudentName;
        return acc;
      },
      {}
    );

    // Flatten the updatedStudentResults to get an array of all assessments
    const allAssessments = Object.values(updatedStudentResults).flat();

    // Log the final flattened result to see the changes
    console.log("All Assessments with Student Names:", allAssessments);

    setAssessmentResults(allAssessments);
    setSortedAssessmentResults(allAssessments); // Initial sorting
  }, [studentResults, students]);

  const [visibleAccordions, setVisibleAccordions] = useState(new Set());

  let fullTasksArray = [];
  let taskNames = [];

  assessmentResults.forEach((assessment) => {
    let assessmentTasks = assessment.tasks || [];

    assessmentTasks.forEach((task) => {
      fullTasksArray.push(task.order + task.name);
    });
  });

  // Order tasks based on their name
  function taskNameOrder() {
    let reducedTaskNameArray = [...new Set(fullTasksArray)];
    reducedTaskNameArray.sort((a, b) => a.localeCompare(b));
    taskNames = reducedTaskNameArray.map((name) => name.replace(/[0-9]/g, ""));
  }
  taskNameOrder();

  function getReadingScoreClass(score) {
    if (score === 0) {
      return "low-color";
    } else if (score > 0 && score <= 20) {
      return "low-color";
    } else if (score > 20 && score <= 40) {
      return "med-color";
    } else if (score > 40 && score <= 100) {
      return "noRisk-color";
    } else {
      return "blah"; // Return an empty string for scores outside the specified ranges
    }
  }

  function renderTaskScore(tasks, taskName) {
    const task = tasks.find((t) => t.name === taskName);

    if (!task) return "";

    const score = Math.round(task.score * 100);
    let scoreClass = "";

    if (score === 0) {
      scoreClass = "low-color";
    } else if (score > 0 && score <= 20) {
      scoreClass = "low-color";
    } else if (score > 20 && score < 40) {
      scoreClass = "med-color";
    } else if (score >= 40 && score <= 100) {
      scoreClass = "noRisk-color";
    }

    return <span className={`task-score ${scoreClass}`}>{score}%</span>;
  }

  // Function to calculate reading score
  function calculateReadingScore(assessment) {
    if (!assessment || !assessment.tasks || assessment.tasks.length === 0)
      return 0;
    const totalScore = assessment.tasks.reduce(
      (acc, task) => acc + task.score,
      0
    );
    return Math.round((totalScore / assessment.tasks.length) * 100);
  }

  function calculateTotalTime(assessment) {
    if (!assessment || !assessment.tasks || assessment.tasks.length === 0)
      return 0;
    return assessment.tasks.reduce((acc, task) => acc + task.duration, 0);
  }

  // Add readingScore and totalTime to each assessment
  assessmentResults.forEach((assessment) => {
    assessment.readingScore = calculateReadingScore(assessment);
    assessment.totalTime = calculateTotalTime(assessment); // Calculate total time
  });

  const showNestedData = (assessmentIndex) => {
    setVisibleAccordions((prevVisible) => {
      const newVisible = new Set(prevVisible);
      newVisible.has(assessmentIndex)
        ? newVisible.delete(assessmentIndex)
        : newVisible.add(assessmentIndex);
      return newVisible;
    });
  };

  const handleDeleteTask = (event, assessmentIndex, taskIndex) => {
    event.stopPropagation();
    event.preventDefault();

    const assessment = assessmentResults[assessmentIndex];
    const task = assessment.tasks[taskIndex];
    setModalTask(task?.name);
    setModalAssessment(assessment?.name);
    setAnswerId(task?.answerid);
    setShowModal(true);
  };

  const handleModalClose = () => setShowModal(false);

  const handleDelete = (taskName) => {
    setAssessmentResults((prevResults) =>
      prevResults.map((assessment) => ({
        ...assessment,
        tasks: assessment.tasks.filter((task) => task.name !== taskName),
      }))
    );
  };

  const getSortIcon = (field) => {
    // Implement sorting icon based on the sorting state
    return process.env.PUBLIC_URL + "/assets/imgs/sort-icon.png"; // Replace with the actual logic
  };

  const sortData = (field) => {
    const sortedData = [...sortedAssessmentResults].sort((a, b) => {
      if (field === "name") {
        return a.name.localeCompare(b.name);
      }
      if (field === "readingScore") {
        return b.readingScore - a.readingScore;
      }
    });
    setSortedAssessmentResults(sortedData);
  };

  return (
    <>
      {students?.map((student) => (
        <div className="student-report-card" key={student.id}>
          <Row>
            <Col>
              <h3 className="student-name">{student.name}</h3>
              <div className="results-history-container">
                <div className="table-responsive-wrapper">
                  <Table className="custom-table results-history-table">
                    <thead className="table-header print-thead">
                      <tr>
                        <th onClick={() => sortData("name")}>Assessment</th>
                        <th onClick={() => sortData("readingScore")}>
                          Reading Score
                        </th>
                        <th onClick={() => sortData("atRisk")}>At-Risk</th>
                        {taskNames.map((taskName) => (
                          <th key={taskName} onClick={() => sortData(taskName)}>
                            {taskName}
                          </th>
                        ))}
                        <th onClick={() => sortData("totalTime")}>Time</th>
                      </tr>
                    </thead>
                    {sortedAssessmentResults
                      .filter(
                        (assessment) => assessment.studentName === student.name
                      )
                      .map((assessment, assessmentIndex) => (
                        <tbody key={assessmentIndex}>
                          <tr className="results-history-table-row centre-item">
                            <td className="align-left">
                              {assessment.name || ""}
                            </td>
                            <td>
                              <span
                                className={`reading-score ${getReadingScoreClass(
                                  assessment.readingScore
                                )}`}
                              >
                                {assessment.readingScore}%
                              </span>
                            </td>
                            <td>
                              {assessment.readingScore !== undefined &&
                              assessment.readingScore < 20 ? (
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/imgs/risk-flag-red.png"
                                  }
                                  className="flag-size-results"
                                  alt="Red Flag"
                                />
                              ) : assessment.readingScore >= 20 &&
                                assessment.readingScore <= 40 ? (
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/imgs/risk-flag-yellow.png"
                                  }
                                  className="flag-size-results"
                                  alt="Yellow Flag"
                                />
                              ) : null}
                            </td>
                            {taskNames.map((taskName) => (
                              <td key={`${assessmentIndex}-${taskName}`}>
                                {renderTaskScore(assessment.tasks, taskName)}
                              </td>
                            ))}
                            <td>{secondsToTime(assessment.totalTime)}</td>
                          </tr>
                        </tbody>
                      ))}
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}

      <DeleteAnswerModal
        show={showModal}
        handleClose={handleModalClose}
        taskName={modalTask}
        studentName={modalAssessment}
        assessmentName={modalAssessment}
        handleDelete={handleDelete}
        answerId={answerId}
        teacherid={teacherid}
      />
    </>
  );
}

export default AllReportCardsHistory;
