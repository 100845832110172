import React from "react";

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom";

import './RightPanel.css';

function RightPanel({msg, label, path}) {
    return(

        <Col sm={4}  className="right-panel d-flex flex-column align-items-center justify-content-center">
            <Row className="">
                <div className="signup-msg">
                    <div id="right-panel-msg">{msg}</div>
                    <br />
                    <a href={path}><button className="text-uppercase button-padding blue-button">{label}</button></a>
                </div>
            </Row>
        </Col>
    )
}

export default RightPanel;