import React from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import "./BulkImportStudents.css";

function BulkImportStudents(props) {
  return (
    <Row className="margin-left">
      <Col>
        <div>
          <h3>Importing Students</h3>
          <p>Type the names of your students into the box on the left.</p>
          <p>Use a comma to separate the names of each student.</p>
          <p>
            You can also copy/paste a list of comma separated student names from
            another source.
          </p>
          <p>
            You can then set the ESL and/or Speech and Language status of each
            student in the table below.
          </p>
          <Table></Table>
        </div>
      </Col>
    </Row>
  );
}

export default BulkImportStudents;
