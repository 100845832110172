import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./SupportPlanModal.css";
import {
  getJiSiReportByStudentId,
  upsertJiSiReport,
} from "../services/APIClient";

const supportOptions = [
  { text: "Reading", color: "purple" },
  { text: "Writing", color: "purple" },
  { text: "Spelling", color: "purple" },
  { text: "Oral Language", color: "purple" },
  { text: "Numeracy", color: "purple" },
  { text: "Social Skills", color: "purple" },
  { text: "Attention and Concentration", color: "purple" },
  { text: "Fluency and Decoding", color: "purple" },
];

const SupportPlanModal = ({
  show,
  handleClose,
  studentName,
  currentStudentId,
  teacherid,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [supportPlan, setSupportPlan] = useState([]);

  useEffect(() => {
    if (show && currentStudentId) {
      // Fetch support plan when the modal is shown and currentStudentId is available
      getJiSiReportByStudentId(currentStudentId)
        .then((response) => {
          if (response && response.supportplan !== undefined) {
            setSupportPlan(response.supportplan);
            setSelectedOptions(response.supportplan || []); // Pre-select the options if available
          } else {
            setSupportPlan([]);
            setSelectedOptions([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching support plan:", error);
          setSupportPlan([]);
          setSelectedOptions([]);
        });
    }
  }, [show, currentStudentId, setSupportPlan]);

  const handleOptionClick = (text) => {
    const updatedOptions = selectedOptions.includes(text)
      ? selectedOptions.filter((option) => option !== text)
      : [...selectedOptions, text];
    setSelectedOptions(updatedOptions);
    setSupportPlan(updatedOptions);
    console.log(`Selected options for ${studentName}:`, updatedOptions);
  };

  const saveChanges = () => {
    handleSaveChanges();
    handleClose();
  };

  const handleModalClose = () => {
    setSelectedOptions([]); // Reset selected options to an empty array
    handleClose();
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      supportplan: supportPlan,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      upsertJiSiReport(reportData)
        .then((response) => {
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered size="lg" className="support-plan-modal">
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          Classroom Support Plan
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="options-container">
          {supportOptions.map((option) => (
            <div
              key={option.text}
              className={`option ${option.color} ${
                selectedOptions.includes(option.text) ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.text)}
            >
              {option.text}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="modal-button no-button" onClick={handleModalClose}>
          Cancel
        </button>
        <button className="modal-button yes-button" onClick={saveChanges}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SupportPlanModal;
