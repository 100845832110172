import React from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./AssessmentStatus.css";

function ResultsStatus(props) {
  let classDetails = props.classDetails;
  let classAssessments = props.classAssessmentsValues;
  let teachers = props.teachers;

  let gradeLevel = classDetails.grade_level;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  let schoolid = props.schoolid;
  let teacherid = props.teacherid;

  const assessmentOrder = {
    baseline: 1,
    midpoint: 2,
    "end of year": 3,
  };

  const sortAssessments = (a, b) => {
    const getSortKey = (name) => {
      name = name.toLowerCase();
      if (name.includes("baseline") || name.includes("autumn"))
        return assessmentOrder["baseline"];
      if (name.includes("midpoint") || name.includes("spring"))
        return assessmentOrder["midpoint"];
      if (name.includes("end of year") || name.includes("summer"))
        return assessmentOrder["end of year"];
      return 4; // Default sort key
    };

    let aOrder = getSortKey(a.name);
    let bOrder = getSortKey(b.name);

    return aOrder - bOrder;
  };

  const getIconFileName = (assessmentName) => {
    let name = assessmentName.toLowerCase();

    // Season-based logic
    if (
      name.includes("baseline") ||
      name.includes("point 1") ||
      name.includes("autumn")
    ) {
      return "autumn";
    } else if (
      name.includes("midpoint") ||
      name.includes("point 2") ||
      name.includes("spring")
    ) {
      return "spring";
    } else if (
      name.includes("end of year") ||
      name.includes("point 3") ||
      name.includes("summer")
    ) {
      return "summer";
    }

    // Remove "Instance" if it exists in the name
    name = name.replace(" instance", "");

    // Task-based logic for other assessments
    const words = name.split(" ");
    let imageName = words.slice(0, 2).join(""); // Combine the first two words

    // Ensure the generated name is appropriate
    if (words.length === 1) {
      imageName = words[0]; // If only one word, use it as the image name
    }

    console.log(`Assessment Name: ${assessmentName}, Image Name: ${imageName}`);
    return imageName;
  };

  let classAssessmentsArray = classAssessments.map((classAssessment) => {
    return {
      ...classAssessment,
      imageName: getIconFileName(classAssessment.name),
    };
  });

  classAssessmentsArray.sort(sortAssessments);
  console.log(classAssessmentsArray);

  const navigate = useNavigate();

  const toAssessmentResults = (assessment) => {
    navigate("/studentsresults", {
      state: {
        assessment: { assessment },
        classDetails: { classDetails },
        classAssessments: { classAssessments },
        classAssessmentsArray: { classAssessmentsArray },
        teachers: { teachers },
        gradeLevel: { gradeLevel },
        schoolid: { schoolid },
        teacherid: { teacherid },
      },
    });
  };

  return (
    <Row>
      {classAssessmentsArray.length === 0 ? (
        <Col>
          <Alert variant="info" className="text-center">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="3x"
              className="mb-3"
            />
            <h4>No Results Available</h4>
            <p>
              Once your students begin screening, the results will start to
              populate here.
            </p>
          </Alert>
        </Col>
      ) : (
        classAssessmentsArray.map((classAssessment, index) => (
          <Col className="col-4" key={index}>
            <button
              className={`light-blue-button assessment-button ${
                props.selectAssessmentId === classAssessment.id
                  ? "selected"
                  : ""
              }`}
              onClick={() => {
                toAssessmentResults(classAssessment);
              }}
            >
              {classAssessment.name.replace(" Instance", "")} <br />
              <img
                src={`/assets/imgs/${classAssessment.imageName}-icon.png`}
                className="season-assessment-icon"
                alt={classAssessment.name}
              />
            </button>
          </Col>
        ))
      )}
    </Row>
  );
}

export default ResultsStatus;
