import { useState, useEffect } from "react";
import Logout from '../components/Logout';
import NavigationButton from './NavigationButton';

import {withRouter} from '../utils/withRouter.js';

import { whoami } from "../services/APIClient";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './HeadingBanner.scss';

function HeadingBanner(props) {

    const colour = useState('grey');

    // const headerStyles = "header " + colour;

    const cloudContent = <div>
                            <div className="leftPosition"><NavigationButton img='/assets/imgs/home_icon.svg' alt='home' route='/masterdashboard' isLoggedIn={props.isLoggedIn} teacherid={props.teacherid}/></div>
                            <div className="centrePosition"><NavigationButton img='/assets/imgs/avatar.svg' alt='profile' route='/profile' isLoggedIn={props.isLoggedIn} teacherid={props.teacherid}/></div>
                            <div className="rightPosition"><Logout isLoggedIn={props.isLoggedIn}/></div>
                        </div>

    return(
        <div className="headerContainer">
            <ColourHeader colour={props.colour} text={props.headingText} isLoggedIn={props.isLoggedIn}/>
            <MainBanner name={props.name} cloud={props.cloud} control={cloudContent}/>
        </div>
    );
}


/**
 * Simple banner across the top of the screen.
 * Colour can be customised depending on the parameterisation to one of the predefined values
 * 
 * @param {} colour
 * @param {} text
 * @param {} isloggedIn
 * @returns 
 */
function ColourHeader({colour = 'blue', text = 'Teacher Dashboard'}) {
    return (
        <Row className={`header ${colour}`}>
            <Col sm={2}></Col>
            <Col className="d-flex justify-content-center"><h1>{text}</h1></Col>
            <Col sm={2}></Col>
        </Row>
    );
}

function MainBanner(props) {    

    let name = props.name;
    let cloud = props.cloud;
    let control = props.control;

    return (
        <Row className="subheader">
            <Col className="d-flex">
                <div className="logo col-2"></div>
                <h2 className="subheading-text col-7">{name}</h2>
                <div className={`${cloud ? 'cloud' : 'circle' }`}>
                    {control}
                </div>
            </Col>
        </Row>
    );
}

export default withRouter(HeadingBanner);
