import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/esm/Col.js";
import Row from "react-bootstrap/esm/Row.js";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { withRouter } from "../utils/withRouter.js";
import { login } from "../services/APIClient.js";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./Login.css";

class Login extends Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.state = {
      username: "",
      password: "",
      error: false,
      errorMessage: "", // State to store custom error messages
      showPassword: false, // State to toggle password visibility
    };
  }

  setUsername(username) {
    this.setState({ username: username.toLowerCase() });
  }

  setPassword(password) {
    this.setState({ password: password });
  }

  togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleSubmit(event) {
    event.preventDefault();

    // Check if username or password is empty
    if (!this.state.username || !this.state.password) {
      this.setState({
        error: true,
        errorMessage: "Both username and password are required.",
      });
      return;
    }

    // If fields are filled, proceed with login
    login(this.state.username, this.state.password)
      .then((data) => {
        if (data) {
          localStorage.setItem("token", data.token);
          this.props.router.navigate("/masterdashboard");
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: "", // Clear the specific error message
        });
        this.setState({
          errorMessage: `Having trouble logging in? Please verify that you are using the
                correct email address for ALPACA.
                If the issue persists, contact us at info@alpaca-assessment.com
                for assistance.`,
          password: "",
        });
        event.target.querySelector("input[type=password]").value = "";
      });
  }

  render() {
    return (
      <div className="align-self-center">
        <Form className="login-form" onSubmit={this.handleSubmit}>
          <Form.Label><strong>Log in to your Account</strong></Form.Label>
          <Form.Group as={Row} className="mb-3" size="lg" controlId="email">
            <Form.Label className="label" column sm="2">
              <strong>Email</strong>
            </Form.Label>
            <Col sm="10">
              <Form.Control
                autoFocus
                size="md"
                type="email"
                value={this.state.username}
                onChange={(e) => this.setUsername(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" size="lg" controlId="password">
            <Form.Label className="label" column sm="2">
              <strong>Password</strong>
            </Form.Label>
            <Col sm="10">
              <InputGroup className="custom-input-group">
                <Form.Control
                  size="md"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.password}
                  onChange={(e) => this.setPassword(e.target.value)}
                />
                <InputGroup.Text onClick={this.togglePasswordVisibility}>
                  {this.state.showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Form.Group>
          <Link to={`/recovery`} className="set-right">
            Forgot Password?
          </Link>
          <Col sm={{ span: 10, offset: 2 }}>
            <div className="d-grid gap-2">
              <button
                block="true"
                size="lg"
                type="submit"
                className="blue-button custom-button-width"
                id="login-button"
              >
                Login
              </button>
            </div>
          </Col>
          {this.state.errorMessage && (
            <div className="alert alert-danger mt-3">
              <p>{this.state.errorMessage}</p>
            </div>
          )}
          {this.state.error && !this.state.errorMessage && (
            <div className="alert alert-danger mt-3">
              <p>
                Having trouble logging in? Please verify that you are using the
                correct email address for ALPACA.
              </p>
              <p>
                If the issue persists, contact us at info@alpaca-assessment.com
                for assistance.
              </p>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export default withRouter(Login);
