import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  editClass,
  updateClassOwnerId,
  shareClass,
} from "../services/APIClient";

import "./SaveClassDetails.css";

function SaveClassDetailsSpecial(props) {
  const {
    classDetails,
    teachers,
    newClassName,
    newGrade,
    newClassTeacherId,
    schoolid,
    isNameUpdated, // Add isNameUpdated to props
  } = props;
  const classid = classDetails.id;
  const navigate = useNavigate();

  // State to control the modal visibility
  const [showModal, setShowModal] = useState(false);

  const handleClassEdit = async (e) => {
    e.preventDefault();

    if (!isNameUpdated) {
      setShowModal(true);
      return;
    }

    try {
      // Update class details
      await editClass(classid, newClassName, newGrade);

      // Update class owner ID if a new teacher is selected
      if (newClassTeacherId) {
        await updateClassOwnerId(classid, newClassTeacherId);
        await shareClass(classid, newClassTeacherId);
      }

      // Redirect to ClassDetail after saving, with a unique key to force re-fetching
      navigate(`/class/detail/`, {
        state: {
          // Pass all required state properties
          classDetails: { classDetails },
          teachers: { teachers },
          name: { newClassName },
          gradeLevel: { newGrade },
          schoolid: { schoolid },
          // Adding a unique timestamp as a key to force the component to re-fetch data
          forceRefreshKey: Date.now(),
        },
      });
    } catch (error) {
      console.error("Error saving class details:", error);
    }
  };

  return (
    <>
      <button
        onClick={handleClassEdit}
        className="green-button"
        style={{
          border: "1px solid #16971b",
        }}
      >
        Save
      </button>

      {/* Modal to show when the class name hasn't been updated */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please update the class name before saving.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SaveClassDetailsSpecial;
