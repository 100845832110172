import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";

import CreateTrialClassForm from "../components/CreateTrialClassForm";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import OrientationPrompt from "../components/OrientationPrompt";

import "../components/CreateClass.css";
import { useLocation } from "react-router";

function CreateTrialClass(props) {
  let location = useLocation();

  let name = location.state.name.name;
  let schoolid = location.state.schoolid.schoolid;
  let teacherid = location.state.teacherid.teacherid;
  let classes = location.state.classes.classes;

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={"Create a Trial Class! "}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <CreateTrialClassForm />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default CreateTrialClass;
