import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
  
  /**
   * The withRouter HOC is deprecated in v6 of react-router-dom. 
   * We need this functionality if we want to use class based components instead of function based components.
   * 
   * This implements a HOC (Higher Order Component) to provide the necessary withRouter functionality.
   * See https://reactrouter.com/docs/en/v6/faq
   * @param {} Component 
   * @returns 
   */
  export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }