import { React, useEffect, useState } from "react";

import { shareClass, unShareClass } from "../services/APIClient";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

import "./ShareClassFunction.css";

function ShareClassFunction(props) {
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let classid = classDetails.id;

  let secondaryTeachers = [];
  teachers.forEach((teacher) => {
    if (classDetails.teacherId !== teacher.id) {
      secondaryTeachers.push(teacher);
    }
  });

  // Sort the secondary teachers by name in alphabetical order
  secondaryTeachers.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  function shareClassAction(e) {
    let teacherid = e.target.value;
    let isShared = e.target.checked;

    // VERY IMPORTANT!!! DO NOT DELETE
    if (isShared === true) {
      shareClass(classid, teacherid);
    } else {
      unShareClass(classid, teacherid);
    }
  }

  function checkboxChecked() {
    let targetedCheckboxes = document.querySelectorAll("input");
    if (targetedCheckboxes) {
      for (let i = 0; i < targetedCheckboxes.length; i++) {
        let targetedCheckbox = targetedCheckboxes[i];
        let targetedCheckboxValue = targetedCheckbox.value;

        if (sharedWith) {
          sharedWith.forEach((shared) => {
            if (shared.id === targetedCheckboxValue) {
              targetedCheckbox.checked = true;
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    checkboxChecked();
  }, []);

  return (
    <Row>
      <Col>
        <div>
          {secondaryTeachers.length === 0 ? (
            <Row className="roles">
              <h5 className="page-description-header">
                There are currently no students in your class.
              </h5>
              <h5 className="page-description-header">
                Click into "Invite Colleagues" to send out invites to your
                colleagues
              </h5>
            </Row>
          ) : (
            <Table className="custom-table">
              <thead className="table-header">
                <tr>
                  <th>Name</th>
                  <th></th>
                  <th>Role(s)</th>
                  <th className="header-th">Add</th>
                </tr>
              </thead>
              <tbody>
                {secondaryTeachers.map((teacher) => (
                  <tr id={teacher.id} className="table-row" key={teacher.id}>
                    <td className="table-data">{teacher.name}</td>
                    <td> </td>
                    <td className="table-data">
                      {teacher.additionalRoles?.toString()}
                    </td>
                    <td>
                      <Form.Check
                        onChange={shareClassAction}
                        type="checkbox"
                        value={teacher.id}
                        id="shared-input"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default ShareClassFunction;
