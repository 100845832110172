import React from "react";
import { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./ClassOverview.css";

import ResultsStatus from "./ResultsStatus";
import ReportsButton from "./ReportsButton";
import ClassStudents from "./ClassStudents";
import ReturnToResults from "./ReturnToResults";
import GradeCard from "./GradeCard";

import SencoReportsButton from "./SencoReportsButton";
import JiSiReportsButton from "./JiSiReportsButton";

import {
  getStudentsForClass,
  getClassAssessments,
  getStudentResults,
  getResultsByStudent
} from "../services/APIClient";

function ReportsOverview(props) {
  // Class Personnel
  let assessment = props.assessment;
  // let classAssessments = props.classAssessments;
  let classDetails = props.classDetails;
  let classid = classDetails.id;
  let gradeLevel = props.gradeLevel;
  let name = props.name;
  let teachers = props.teachers;
  let teacherid = props.teacherid;
  let schoolid = props.schoolid;
  let students = props.students;
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  const [classAssessments, setClassAssessments] = useState([]);
  const [assessmentResultsByStudent, setAssessmentResultsByStudent] = useState([]);
  const [studentsReadingScore, setStudentsReadingScore] = useState([]);

  const classAssessmentsValues = Object.values(classAssessments);

  useEffect(() => {
    getClassAssessments(classid).then((response) => {
      setClassAssessments(response);
    });
  }, [classid]);


  useEffect(() => {
    const fetchAllAssessmentResults = () => {
      if (students && students.length > 0) {
        const assessmentInstanceIds = students.reduce((acc, student) => {
          // console.log(student.name)
          // console.log(student)
          const ids = student.assessments ? student.assessments.map(assessment => assessment.id) : [];
          // console.log([...acc, ...ids])
          return [...acc, ...ids];
        }, []);

        console.log(assessmentInstanceIds);

        if (assessmentInstanceIds.length > 0) {
          getResultsByStudent(assessmentInstanceIds).then((response) => {
            setAssessmentResultsByStudent(response);
          }).catch(error => {
            console.error('Error fetching assessment results:', error);
          });
        } else {
          console.log('No assessment IDs found');
        }
      }
    };

    fetchAllAssessmentResults();
  }, [students]);

  const groupByStudentId = (results) => {
    return results.reduce((acc, result) => {
      const studentId = result.student.id;
      const studentName = result.student.name;
      if (!acc[studentId]) {
        acc[studentId] = {
          name: studentName,
          assessments: []
        };
      }
      acc[studentId].assessments.push(result);
      return acc;
    }, {});
  };

  const calculateReadingScores = (groupedResults) => {
    const scores = [];
    Object.keys(groupedResults).forEach(studentId => {
      const { name, assessments } = groupedResults[studentId];
      let totalScore = 0;
      let taskCount = 0;

      assessments.forEach(assessment => {
        assessment.tasks.forEach(task => {
          if (task.score !== undefined) {
            totalScore += task.score;
            taskCount++;
          }
        });
      });

      if (taskCount > 0) {
        scores.push({
          studentName: name,
          studentId: studentId,
          readingScore: Math.round((totalScore / taskCount) * 100)
        });
      } else {
        scores.push({
          studentName: name,
          studentId: studentId,
          readingScore: "No completed tasks"
        });
      }
    });
    return scores;
  };

  const mergeStudentsWithScores = (students, scores) => {
    const scoresMap = scores.reduce((map, score) => {
      map[score.studentId] = score.readingScore;
      return map;
    }, {});

    return students.map(student => ({
      ...student,
      readingScore: scoresMap[student.id] || null
    }));
  };


  useEffect(() => {
    if (assessmentResultsByStudent.length > 0) {
      const groupedResults = groupByStudentId(assessmentResultsByStudent);
      const readingScores = calculateReadingScores(groupedResults);
      console.log(readingScores)

      const mergedStudents = mergeStudentsWithScores(students, readingScores);
      console.log("Merged students with scores:", mergedStudents);
      setStudentsReadingScore(mergedStudents)
      console.log(mergedStudents);
    }
  }, [assessmentResultsByStudent, students]);

  console.log(studentsReadingScore)

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-7">
            <Row className="inline-block">
              <Col className="col-6">
                <h2 className="page-description-header">Reports</h2>
              </Col>
              <Col className="col-3">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />
              </Col>
            </Row>
            <p className="paragraph-font-size">
              Select the type of report you would like to see.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          {studentsReadingScore.length > 0 ? (
            <>
              <SencoReportsButton
                students={students}
                assessment={assessment}
                classAssessmentsValues={classAssessmentsValues}
                classDetails={classDetails}
                teachers={teachers}
                teacherid={teacherid}
                studentsReadingScore={studentsReadingScore}
                schoolid={schoolid}
              />
              {gradeLevel1 && (
                <JiSiReportsButton
                  students={students}
                  assessment={assessment}
                  classAssessmentsValues={classAssessmentsValues}
                  classDetails={classDetails}
                  teachers={teachers}
                  teacherid={teacherid}
                  studentsReadingScore={studentsReadingScore}
                  schoolid={schoolid}
                />
              )}
            </>
          ) : (
            <div>Loading Reports...</div>
          )}
        </Row>

        <br></br>
        <Row>
          <Col>
            <ReturnToResults
              classDetails={classDetails}
              teachers={teachers}
              classAssessments={classAssessments}
              schoolid={schoolid}
              teacherid={teacherid}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ReportsOverview;
