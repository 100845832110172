import React from "react";

import { useNavigate } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";

import "./AssessmentDropdown.css";

function AssessmentDropdown(props) {
  let classAssessments = props.classAssessments;
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let assessmentName = props.assessmentName;
  let name = classDetails.name;
  let gradeLevel = classDetails.grade_level;
  let assessment = props.assessment;
  // let assessmentResultsByStudent = props.assessmentResultsByStudent;

  let classAssessmentsArray = [];
  classAssessments.forEach((classAssessment) => {
    classAssessmentsArray.push(classAssessment);
  });

  console.log(classAssessments.instances, "class assessments");

  const navigate = useNavigate();

  const assessmentOrder = {
    baseline: 1,
    midpoint: 2,
    "end of year": 3,
  };

  const sortAssessments = (a, b) => {
    const getSortKey = (name) => {
      name = name.toLowerCase();
      if (name.includes("baseline") || name.includes("autumn"))
        return assessmentOrder["baseline"];
      if (name.includes("midpoint") || name.includes("spring"))
        return assessmentOrder["midpoint"];
      if (name.includes("end of year") || name.includes("summer"))
        return assessmentOrder["end of year"];
      return 4;
    };

    let aOrder = getSortKey(a.name);
    let bOrder = getSortKey(b.name);
    return aOrder - bOrder;
  };

  classAssessments.sort(sortAssessments);

  const toAssessmentResults = (assessment) => {
    navigate("/studentsresults", {
      state: {
        assessment: { assessment },
        classDetails: { classDetails },
        classAssessments: { classAssessments },
        classAssessmentsArray: { classAssessmentsArray },
        teachers: { teachers },
        gradeLevel: { gradeLevel },
        name: { name },
      },
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        // className="dropdown"
        style={{
          backgroundColor: "white",
          color: "black",
          border: "solid 1px #2c7693",
          textAlign: "center",
        }}
      >
        {assessmentName}
      </Dropdown.Toggle>

      <Dropdown.Menu variant="dark">
        {classAssessments.map((classAssessment, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => {
              toAssessmentResults(classAssessment);
            }}
          >
            {classAssessment.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default AssessmentDropdown;
