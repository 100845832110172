import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const recommendationsStyle = {
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  fontSize: "16px",
  maxWidth: "600px",
  textAlign: "center",
  margin: "20px auto",
  lineHeight: "1.5",
};

const TaskScoresComparisonChart = ({ assessmentResultsByStudent }) => {
  console.log(assessmentResultsByStudent, "STANSCORE");

  // Extract task names from the first student's assessment data
  const tasks =
    assessmentResultsByStudent[0]?.tasks.map((task) => task.name) || [];

  // Calculate class average scores for each task
  const classAverageScores = tasks.reduce((acc, task) => {
    const taskScores = assessmentResultsByStudent
      .flatMap((student) => student.tasks)
      .filter((t) => t.name === task);

    const averageScore =
      taskScores.reduce((sum, t) => sum + t.score * 100, 0) / taskScores.length;

    acc[task] = averageScore.toFixed(2);
    return acc;
  }, {});

  // Extract the actual standardized scores for the tasks
  const standardScores = tasks.reduce((acc, task) => {
    const scores = assessmentResultsByStudent
      .flatMap((student) => student.tasks)
      .filter((t) => t.name === task)
      .map((t) => t.standardScore);

    // Calculating the average of standardized scores for consistency in representation
    const averageStandardScore =
      scores.reduce((sum, score) => sum + score, 0) / scores.length;
    acc[task] = averageStandardScore.toFixed(2);
    return acc;
  }, {});

  const data = {
    labels: tasks,
    datasets: [
      {
        label: "Class Average",
        data: tasks.map((task) => parseFloat(classAverageScores[task] || "0")),
        backgroundColor: "rgba(144, 238, 144, 0.6)", // Light green
        borderColor: "rgba(144, 238, 144, 1)",
        borderWidth: 1,
      },
      {
        label: "Standardized Score",
        data: tasks.map((task) => parseFloat(standardScores[task] || "0")),
        backgroundColor: "rgba(255, 165, 0, 0.6)", // Light orange
        borderColor: "rgba(255, 165, 0, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Tasks",
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Scores (%)",
          font: {
            size: 16,
          },
        },
        ticks: {
          callback: (value) => `${value.toFixed(2)}%`,
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.raw.toFixed(2)}%`;
          },
        },
      },
    },
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Class Average vs. Standardized Score
      </h2>
      <Bar data={data} options={options} />
      <div style={recommendationsStyle}>
        <h3 style={{ textAlign: "center" }}>
          Class Performance Percentile vs Standardized Score
        </h3>
        <p>
          This graph compares the class's performance percentiles with
          Standardized scores as percentiles. Percentiles show how the class’s
          performance compares to others, like a ranking, to see if they are
          above or below national standards. Blue bars represent the class’s
          percentiles, while orange bars show national benchmarks. The
          horizontal base of the graph lists the screened areas, and its
          vertical left side shows percentiles. This comparison reveals how the
          class’s performance aligns with national standards.
        </p>
      </div>
    </div>
  );
};

export default TaskScoresComparisonChart;
