import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./ReportClassTable.css";
import LiteracyObservationsModal from "./LiteracyObservationsModal";
import ReadingScoreNotesModal from "./ReadingScoreNotesModal";
import AdditionalNeedsModal from "./AdditionalNeedsModal";
import SupportPlanModal from "./SupportPlanModal";
import ConfirmationModal from "./ConfirmationModal";
import SetEalModal from "./SetEalModal";
import SetSetModal from "./SetSetModal";
import SetAttendanceAffectingScoresModal from "./SetAttendanceAffectingScoresModal";

import JISIReportTableRow from "./JISIReportTableRow";
import {
  upsertJiSiReport,
  getJiSiReportByStudentId,
} from "../services/APIClient";

function JISIReportClassTable(props) {
  const {
    students,
    showAtRiskOnly,
    setShowAtRiskOnly,
    teachers,
    teacherid,
    studentsReadingScore,
    setShowSortedData,
  } = props;

  const dummyHeaderArray = [
    "Reading Score Notes",
    "General Literacy",
    "Additional Needs",
    "Support Plan",
    "EAL",
    "SET",
    "Attendance Affecting Scores",
  ];

  const [showLiteracyModal, setShowLiteracyModal] = useState(false);
  const [showReadingScoreModal, setShowReadingScoreModal] = useState(false);
  const [showAdditionalNeedsModal, setShowAdditionalNeedsModal] =
    useState(false);
  const [showSupportPlanModal, setShowSupportPlanModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEalModal, setShowEalModal] = useState(false);
  const [showSetModal, setShowSetModal] = useState(false);
  const [
    showAttendanceAffectingScoresModal,
    setShowAttendanceAffectingScoresModal,
  ] = useState(false);
  const [currentStudentName, setCurrentStudentName] = useState("");
  const [currentStudentId, setCurrentStudentId] = useState("");

  // State to hold selections
  const [readingScoreNotes, setReadingScoreNotes] = useState([]);
  const [literacyObservations, setLiteracyObservations] = useState("");
  const [additionalNeeds, setAdditionalNeeds] = useState([]);
  const [supportPlan, setSupportPlan] = useState([]);
  const [eal, setEal] = useState(false);
  const [set, setSet] = useState(false);
  const [attendanceAffectingScores, setAttendanceAffectingScores] =
    useState(false);

  const [newEalState, setNewEalState] = useState(false);
  const [newSetState, setNewSetState] = useState(false);
  const [newAttendanceState, setNewAttendanceState] = useState(false);

  const [saveClicked, setSaveClicked] = useState(false);

  const [sortCriteria, setSortCriteria] = useState({
    field: "name",
    order: "asc",
  });
  const [sortedData, setSortedData] = useState([]);

  const handleShowLiteracyModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowLiteracyModal(true);
  };

  const handleShowReadingScoreModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);

    setShowReadingScoreModal(true);
  };

  const handleShowAdditionalNeedsModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowAdditionalNeedsModal(true);
  };

  const handleShowSupportPlanModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowSupportPlanModal(true);
  };

  const handleShowAttendanceAffectingScoresModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowAttendanceAffectingScoresModal(true);
  };

  const handleShowESetModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowSetModal(true);
  };

  const handleShowEalModal = (studentName, studentId) => {
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setShowEalModal(true);
  };

  const handleShowConfirmationModalEAL = (studentName, studentId, newEal) => {
    console.log(
      `Showing confirmation modal for EAL: ${studentName}, ID: ${studentId} - ${newEal}`
    );
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setNewEalState(newEal); // Set the new state here
    setShowConfirmationModal(true);
  };

  const handleShowConfirmationModalSET = (studentName, studentId, newSet) => {
    console.log(
      `Showing confirmation modal for SET: ${studentName}, ID: ${studentId} - ${newSet}`
    );
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setNewSetState(newSet);
    setShowConfirmationModal(true);
  };

  const handleShowConfirmationModalAttendance = (
    studentName,
    studentId,
    newAttendance
  ) => {
    console.log(
      `Showing confirmation modal for Attendance: ${studentName}, ID: ${studentId} - ${newAttendance}`
    );
    setCurrentStudentName(studentName);
    setCurrentStudentId(studentId);
    setNewAttendanceState(newAttendance);
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    console.log("Current eal state:", eal);
  }, [eal]);

  const handleCloseLiteracyModal = () => setShowLiteracyModal(false);
  const handleCloseReadingScoreModal = () => setShowReadingScoreModal(false);
  const handleCloseAdditionalNeedsModal = () =>
    setShowAdditionalNeedsModal(false);
  const handleCloseSupportPlanModal = () => setShowSupportPlanModal(false);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleCloseEalModal = () => setShowEalModal(false);
  const handleCloseSetModal = () => setShowSetModal(false);
  const handleCloseAttendanceAffectingScoresModal = () =>
    setShowAttendanceAffectingScoresModal(false);

  const [lastSavedStudentId, setLastSavedStudentId] = useState();

  useEffect(() => {
    console.log(lastSavedStudentId, "LAST STUDENT");
  }, [lastSavedStudentId]);

  const initialState = {
    readingScoreNotes: [],
    literacyObservations: "",
    additionalNeeds: [],
    supportPlan: [],
    eal: false,
    set: false,
    attendanceAffectingScores: false,
  };

  const resetStateVariables = () => {
    setReadingScoreNotes(initialState.readingScoreNotes);
    setLiteracyObservations(initialState.literacyObservations);
    setAdditionalNeeds(initialState.additionalNeeds);
    setSupportPlan(initialState.supportPlan);
    setEal(initialState.eal);
    setSet(initialState.set);
    setAttendanceAffectingScores(initialState.attendanceAffectingScores);
  };

  const handleSaveChanges = () => {
    const reportData = {
      studentid: currentStudentId,
      teacherid: teacherid,
      readingscorenotes: readingScoreNotes,
      literacyobservations: literacyObservations,
      additionalneeds: additionalNeeds,
      supportplan: supportPlan,
      eal: eal,
      set: set,
      attendanceaffectingscores: attendanceAffectingScores,
    };

    console.log("Saving report data:", reportData);

    if (currentStudentId) {
      setLastSavedStudentId(currentStudentId); // Update last saved student ID
      // resetStateVariables();
      upsertJiSiReport(reportData)
        .then((response) => {
          // Reset only if saving for a different student
          console.log("Upsert JISI Report Success:", response);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      console.error("Error: No student selected.");
    }
  };

  const handleAtRiskFilterChange = (e) => {
    setShowAtRiskOnly(e.target.checked);
  };

  const handleConfirmChange = () => {
    console.log("Confirmation received, saving changes");
    handleSaveChanges();
    handleCloseConfirmationModal();
  };

  // Fetch JiSi Report when currentStudentId changes
  useEffect(() => {
    if (currentStudentId) {
      setSaveClicked(false);
      getJiSiReportByStudentId(currentStudentId)
        .then((response) => {
          console.log("JiSi Report Data:", response);
          if (response && response.readingscorenotes !== undefined) {
            setReadingScoreNotes(response.readingscorenotes);
          }
          if (response && response.literacyobservations !== undefined) {
            setLiteracyObservations(response.literacyobservations);
          }
          if (response && response.additionalneeds !== undefined) {
            setAdditionalNeeds(response.additionalneeds);
          }
          if (response && response.supportplan !== undefined) {
            setSupportPlan(response.supportplan);
          }
          if (response && response.eal !== undefined) {
            setEal(response.eal);
          }
          if (response && response.set !== undefined) {
            setSet(response.set);
          }
          if (response && response.attendanceaffectingscores !== undefined) {
            setAttendanceAffectingScores(response.attendanceaffectingscores);
          }
        })
        .catch((error) => console.error("Error fetching JiSi report:", error));
    }
  }, [currentStudentId, saveClicked]);

  useEffect(() => {
    const sortedStudents = sortStudents(studentsReadingScore, sortCriteria);
    setSortedData(sortedStudents);
    // setShowSortedData(sortedStudents);
  }, [studentsReadingScore, sortCriteria]);

  const sortStudents = (students, criteria) => {
    return [...students].sort((a, b) => {
      if (criteria.field === "name") {
        return criteria.order === "asc"
          ? a.name?.localeCompare(b.name)
          : b.name?.localeCompare(a.name);
      } else if (criteria.field === "readingScore") {
        let scoreA =
          a.readingScore === "No completed tasks" || a.readingScore === "NA"
            ? -1
            : Number(a.readingScore);
        let scoreB =
          b.readingScore === "No completed tasks" || b.readingScore === "NA"
            ? -1
            : Number(b.readingScore);
        return criteria.order === "asc" ? scoreA - scoreB : scoreB - scoreA;
      }
    });
  };

  const handleSortChange = (field) => {
    setSortCriteria((prev) => ({
      field,
      order: prev.field === field && prev.order === "asc" ? "desc" : "asc",
    }));
  };

  const getSortIcon = (field) => {
    if (sortCriteria.field === field) {
      return sortCriteria.order === "asc"
        ? process.env.PUBLIC_URL + "/assets/imgs/desc-icon-active.png"
        : process.env.PUBLIC_URL + "/assets/imgs/asc-icon-active.png";
    }
    return process.env.PUBLIC_URL + "/assets/imgs/asc-icon-default.png";
  };

  const getReadingScoreClass = (score) => {
    if (score === 0) {
      return "low-color";
    } else if (score > 0 && score <= 20) {
      return "low-color";
    } else if (score > 20 && score <= 40) {
      return "med-color";
    } else if (score > 40 && score <= 100) {
      return "noRisk-color";
    } else {
      return "blah";
    }
  };

  return (
    <Row>
      <Col>
        <div>
          <Table className="custom-table">
            <thead className="table-header">
              <tr>
                <th colSpan={12} className="at-risk-filter-row">
                  <div className="at-risk-filter">
                    <label htmlFor="atRiskFilter">Show only "At-Risk"</label>
                    <input
                      type="checkbox"
                      name="atRiskFilter"
                      id="atRiskFilter"
                      checked={showAtRiskOnly}
                      onChange={handleAtRiskFilterChange}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/imgs/0-20Bar.png"}
                      alt="0-20%"
                      className="risk-bar"
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/imgs/21-40Bar.png"}
                      alt="21-40%"
                      className="risk-bar"
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  className="header-th-left header-size-results"
                  onClick={() => handleSortChange("name")}
                >
                  <div className="header-content">
                    Student Name
                    <img
                      className="sort-icon"
                      src={getSortIcon("name")}
                      alt="Sort"
                    />
                  </div>
                </th>
                <th
                  className="header-th header-size-results"
                  onClick={() => handleSortChange("readingScore")}
                >
                  <div className="header-content">
                    Reading Score
                    <img
                      className="sort-icon"
                      src={getSortIcon("readingScore")}
                      alt="Sort"
                    />
                  </div>
                </th>
                {dummyHeaderArray.map((header, index) => (
                  <th key={index} className="header-th header-size-results">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData
                .filter(
                  (student) =>
                    !showAtRiskOnly ||
                    (student.readingScore !== undefined &&
                      student.readingScore !== null &&
                      student.readingScore <= 40)
                )
                .map((student) => (
                  <JISIReportTableRow
                    key={student.id}
                    student={student}
                    teacherid={teacherid}
                    currentStudentId={currentStudentId}
                    setCurrentStudentId={setCurrentStudentId}
                    dummyHeaderArray={dummyHeaderArray}
                    saveClicked={saveClicked}
                    getReadingScoreClass={getReadingScoreClass}
                    handleSaveChanges={handleSaveChanges}
                    handleShowLiteracyModal={handleShowLiteracyModal}
                    handleShowReadingScoreModal={handleShowReadingScoreModal}
                    handleShowEalModal={handleShowEalModal}
                    handleShowSetModal={handleShowESetModal}
                    handleShowAttendanceAffectingScoresModal={
                      handleShowAttendanceAffectingScoresModal
                    }
                    handleShowAdditionalNeedsModal={
                      handleShowAdditionalNeedsModal
                    }
                    handleShowSupportPlanModal={handleShowSupportPlanModal}
                    handleShowConfirmationModalEAL={
                      handleShowConfirmationModalEAL
                    }
                    handleShowConfirmationModalSET={
                      handleShowConfirmationModalSET
                    }
                    handleShowConfirmationModalAttendance={
                      handleShowConfirmationModalAttendance
                    }
                    readingScoreNotes={readingScoreNotes} // Pass state as props
                    setReadingScoreNotes={setReadingScoreNotes}
                    literacyObservations={literacyObservations} // Pass state as props
                    additionalNeeds={additionalNeeds} // Pass state as props
                    supportPlan={supportPlan} // Pass state as props
                    eal={eal} // Pass state as props
                    set={set} // Pass state as props
                    attendanceAffectingScores={attendanceAffectingScores} // Pass state as props
                  />
                ))}
            </tbody>

            {/* // .filter(
                //   (student) =>
                //     !showAtRiskOnly ||
                //     (student.readingScore !== undefined &&
                //       student.readingScore <= 40)
                // )
                // .map((student) => (
                //   
                //     key={student.id}
                //     className="table-row student-results-table-row"
                //     data-student-id={student.id}
                //   >
                //     <td className="centre-student-items align-left">
                //       {student.name}
                //     </td>
                //     <td className="centre-student-items task-badge-parent">
                //       {student.readingScore !== undefined && */}
            {/* //       student.readingScore !== null ? ( */}
            {/* //         <span */}
            {/* //           className={`reading-score ${getReadingScoreClass( */}
            {/* //             student.readingScore
                //           )}`}
                //         >
                //           {student.readingScore}%
                //         </span>
                //       ) : null}
                //     </td>
                //     {dummyHeaderArray.map((header, index) => ( */}
            {/* //       <td key={index} className="centre-student-items">
                //         {header === "Reading Score Notes" ? ( */}
            {/* //           <button
                //             className="white-button"
                //             onClick={() =>
                //               handleShowReadingScoreModal(
                //                 student.name,
                //                 student.id
                //               )
                //             }
                //           >
                //             <i className="bi bi-pencil-square"></i>
                //           </button>
                //         ) : header === "General Literacy" ? (
                //           <button
                //             className="white-button"
                //             onClick={() =>
                //               handleShowLiteracyModal(student.name, student.id)
                //             }
                //           >
                //             <i className="bi bi-pencil-square"></i>
                //           </button>
                //         ) : header === "Additional Needs" ? (
                //           <button
                //             className="white-button"
                //             onClick={() =>
                //               handleShowAdditionalNeedsModal(
                //                 student.name,
                //                 student.id
                //               )
                //             }
                //           >
                //             <i className="bi bi-pencil-square"></i>
                //           </button> */}
            {/* //         ) : header === "Support Plan" ? (
                //           <button
                //             className="white-button"
                //             onClick={() =>
                //               handleShowSupportPlanModal(
                //                 student.name,
                //                 student.id
                //               )
                //             }
                //           >
                //             <i className="bi bi-pencil-square"></i>
                //           </button>
                //         ) : header === "EAL" ? (
                //           <input
                //             data-student-id={student.id}
                //             type="checkbox"
                //             className="circular-checkbox"
                //             onChange={() => {
                //               setEal((prevEal) => !prevEal);
                //               setCurrentStudentId(student.id); // Set current student ID
                //               handleSaveChanges();
                //             }}
                //           />
                //         ) : header === "SET" ? (
                //           <input
                //             data-student-id={student.id}
                //             type="checkbox"
                //             className="circular-checkbox"
                //             onChange={() =>
                //               handleShowConfirmationModal(
                //                 student.name,
                //                 student.id,
                //                 () => setSet(!set)
                //               )
                //             }
                //           />
                //         ) : header === "Attendance Affecting Scores" ? (
                //           <input
                //             data-student-id={student.id}
                //             type="checkbox"
                //             className="circular-checkbox"
                //             onChange={() =>
                //               handleShowConfirmationModal(
                //                 student.name,
                //                 student.id,
                //                 () =>
                //                   setAttendanceAffectingScores(
                //                     !attendanceAffectingScores
                //                   )
                //               )
                //             }
                //           />
                //         ) : (
                //           <button className="white-button">
                //             <i className="bi bi-pencil-square"></i>
                //           </button>
                //         )}
                //       </td>
                //     ))}
                //   </tr>
                // ))} */}
          </Table>
        </div>
        <LiteracyObservationsModal
          show={showLiteracyModal}
          handleClose={handleCloseLiteracyModal}
          studentName={currentStudentName}
          literacyObservations={literacyObservations}
          setLiteracyObservations={setLiteracyObservations}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
        />
        <ReadingScoreNotesModal
          show={showReadingScoreModal}
          handleClose={handleCloseReadingScoreModal}
          studentName={currentStudentName}
          readingScoreNotes={readingScoreNotes}
          setReadingScoreNotes={setReadingScoreNotes}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
          setSaveClicked={setSaveClicked}
        />
        <AdditionalNeedsModal
          show={showAdditionalNeedsModal}
          handleClose={handleCloseAdditionalNeedsModal}
          studentName={currentStudentName}
          additionalNeeds={additionalNeeds}
          setAdditionalNeeds={setAdditionalNeeds}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
        />
        <SupportPlanModal
          show={showSupportPlanModal}
          handleClose={handleCloseSupportPlanModal}
          studentName={currentStudentName}
          supportPlan={supportPlan}
          setSupportPlan={setSupportPlan}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
        />
        <ConfirmationModal
          show={showConfirmationModal}
          handleClose={handleCloseConfirmationModal}
          studentName={currentStudentName}
          handleConfirmChange={handleConfirmChange}
          handleSaveChanges={handleSaveChanges}
          teacherid={teacherid}
        />
        <SetEalModal
          show={showEalModal}
          handleClose={handleCloseEalModal}
          studentName={currentStudentName}
          eal={eal}
          setEal={setEal}
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
        />
        <SetSetModal
          show={showSetModal} // Correct the prop name
          handleClose={handleCloseSetModal} // Correct the close handler
          studentName={currentStudentName}
          set={set} // Correct state variable for SET
          setSet={setSet} // Correct setter function
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
        />
        <SetAttendanceAffectingScoresModal
          show={showAttendanceAffectingScoresModal} // Correct the prop name
          handleClose={handleCloseAttendanceAffectingScoresModal} // Correct the close handler
          studentName={currentStudentName}
          attendanceAffectingScores={attendanceAffectingScores} // Correct state variable for SET
          setAttendanceAffectingScores={setAttendanceAffectingScores} // Correct setter function
          handleSaveChanges={handleSaveChanges}
          currentStudentId={currentStudentId}
          teacherid={teacherid}
        />
      </Col>
    </Row>
  );
}

export default JISIReportClassTable;
