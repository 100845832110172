import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const StudentScoresBarChart = ({ assessmentResultsByStudent }) => {
  // Extract student names and their scores for each task
  const studentScores = assessmentResultsByStudent.map((student) => {
    const tasks = student.tasks.reduce((acc, task) => {
      acc[task.name] = (task.score * 100).toFixed(2); // Convert to percentage and round to 2 decimal places
      return acc;
    }, {});

    return {
      name: student.student.name,
      scores: tasks,
    };
  });

  // Extract tasks from the first student as a reference
  const tasks =
    assessmentResultsByStudent[0]?.tasks.map((task) => task.name) || [];

  // Create a bar chart for each task
  const taskCharts = tasks.map((taskName) => {
    // Gather scores for the current task, rounded to 2 decimal places
    const scores = studentScores.map((student) => ({
      name: student.name,
      score: parseFloat(student.scores[taskName] || "0"),
    }));

    // Sort scores in descending order
    scores.sort((a, b) => b.score - a.score);

    const data = {
      labels: scores.map((score) => score.name),
      datasets: [
        {
          label: taskName,
          data: scores.map((score) => score.score),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      indexAxis: "y", // Display bars horizontally
      scales: {
        x: {
          title: {
            display: true,
            text: "Scores",
            font: {
              size: 16, // Increase font size for the x-axis title
            },
          },
          ticks: {
            callback: (value) => value.toFixed(2), // Ensure x-axis labels are rounded to 2 decimal places
            font: {
              size: 14, // Increase the font size of the x-axis labels
            },
          },
        },
        y: {
          title: {
            display: true,
            text: "Students",
            font: {
              size: 16, // Increase the font size of the y-axis title
            },
          },
          ticks: {
            autoSkip: false,
            font: {
              size: 14, // Increase the font size of the y-axis labels
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
            },
          },
        },
        title: {
          display: true,
          text: taskName,
          font: {
            size: 18, // Set font size for the task title
          },
          padding: {
            bottom: 10, // Add some space between the title and the chart
          },
          align: "center", // Center the title
        },
      },
    };

    return (
      <div key={taskName} style={{ marginBottom: "40px" }}>
        <Bar data={data} options={options} />
      </div>
    );
  });

  return (
    <div>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Student Results by Subtest Area
      </h2>
      {taskCharts}
    </div>
  );
};

export default StudentScoresBarChart;
