import React from "react";

import { useNavigate } from "react-router";

function CloseButton() {
  let navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  return (
    <button
      className="white-button"
      onClick={handleClick}
    >
      Back
    </button>
  );
}

export default CloseButton;
