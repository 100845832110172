import { React, useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import ReferFriendsOverview from "../components/ReferFriendsOverview";
import OrientationPrompt from "../components/OrientationPrompt";

function ReferFriends(props) {
  const location = useLocation();

  console.log(location)

  let name = location.state.name.name;
  // let rollNumber = location.state.rollNumber.rollNumber;
  let teacherEmail = location.state.teacherEmail.teacherEmail;
  let teacherid = location.state.teacherid.teacherid;
  // let schoolid = location.state.schoolid.schoolid;
  // let teacherid = location.state.classDetails.classDetails.teacherId;

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);
  // console.log(rollNumber)
  // console.log(teacherEmail)

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        isLoggedIn={true}
        cloud={true}
        // name={name}
        // teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <ReferFriendsOverview
            // classDetails={location.state.classDetails}
            // teachers={location.state.teachers}
            // sharedWith={location.state.sharedWith}
            // // classDetails={specificClass}
            // gradeLevel={location.state.gradeLevel.gradeLevel}
            name={name}
            // rollNumber={rollNumber}
            teacherEmail={teacherEmail}
            teacherid={teacherid}
            // schoolid={schoolid}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default ReferFriends;
