import React from 'react';
import { exportToExcel } from '../services/ExcelExporter';
import secondsToTime from '../services/secondsToTime';

function GradeLevelExport({ schoolDetails, assessmentResultsByStudent, allAssessmentInstances, mergedData, assessmentInstanceMap }) {

    function sanitizeName(name) {
        return name
            .replace(/[:\\\/\?\*\[\]]/g, '') 
            .slice(0, 31); 
    }

    const filterDataByDate = (data) => {
        const cutoffDate = new Date('2023-09-01');
        return data.filter(item => {
          const itemDate = new Date(item.date || item.dateAssigned);
          return itemDate >= cutoffDate;
        });
    };

    const groupByGradeLevel = (gradeLevel) => {
        const filteredData = filterDataByDate(mergedData);
        return filteredData.filter(item => item[gradeLevel]);
    };

    const taskNames = [
        "Rhyming",
        "Initial Phoneme",
        "Phoneme Blending",
        "Letter Name",
        "Letter Sound",
        "Word Recognition",
        "Deletion",
        "Nonword Recognition",
      ];


    function transformDataForAssessmentExport(dataGroup) {
        const studentsData = [];
        const allTasks = new Set();

        dataGroup.forEach(studentResult => {
            studentResult.tasks.forEach(task => {
                allTasks.add(task.name);
            });
        });

        const allTasksArray = taskNames.filter(taskName => allTasks.has(taskName));

        dataGroup.forEach(studentResult => {
            console.log("studentResult:", studentResult)
            const taskScores = studentResult.tasks.map(task => task.score);
            const readingScore = taskScores.reduce((a, b) => a + b, 0) / taskScores.length;
            const readingScoreFormatted = isNaN(readingScore) ? "Not Started" : `${(readingScore * 100).toFixed(2)}%`;

            const atRisk = readingScore <= 0.2 ? "Red Flag" : readingScore <= 0.4 ? "Yellow Flag" : "";
            const timeFormatted = isNaN(studentResult.time) ? "Not Started" : secondsToTime(studentResult.time);

            let studentEntry = {
                "Student Name": studentResult.student.name,
                "Reading Score": readingScoreFormatted,
                "At-Risk": atRisk,
            };

            allTasksArray.forEach(taskName => {
                const task = studentResult.tasks.find(t => t.name === taskName);
                studentEntry[taskName] = task ? `${(task.score * 100).toFixed(2)}%` : "";
            });

            studentEntry["Time"] = timeFormatted;
            studentEntry["Class"] = studentResult.className;
            studentEntry["Assessment"] = studentResult.name;

            studentsData.push(studentEntry);
        });

        studentsData.sort((a, b) => {
            // Compare class names first
            const classCompare = a["Class"].localeCompare(b["Class"]);
            if (classCompare !== 0) {
                return classCompare; 
            }

            // If in the same class, sort by reading score
            let readingScoreA = parseFloat(a["Reading Score"].replace('%', '')) || 0;
            let readingScoreB = parseFloat(b["Reading Score"].replace('%', '')) || 0;
            return readingScoreB - readingScoreA; 
        });

        return studentsData;
    }

    const handleExportClickGrade = () => {
        const gradeLevel1Data = transformDataForAssessmentExport(groupByGradeLevel('gradeLevel1'));
        const gradeLevel2Data = transformDataForAssessmentExport(groupByGradeLevel('gradeLevel2'));
    
        const sheetsData = {
          "KG1": gradeLevel1Data,
          "KG2": gradeLevel2Data
        };
    
        exportToExcel(sheetsData, `${schoolDetails.name} - Grade Level view export`);
      };



    return (
        <div>
            {schoolDetails && (
                <div>
                    <button className="green-button" onClick={handleExportClickGrade}>Export Data by Grade Level</button>
                </div>
            )}
        </div>
    );
}

export default GradeLevelExport;
