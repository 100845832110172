import React, { useState, useEffect } from "react";
import { withRouter } from "../utils/withRouter.js";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import {
  createClass,
  getCountryGrades,
  getSchools,
  getTeachers,
} from "../services/APIClient.js";
import SimpleNavigationButton from "./SimpleNavigationButton.js";
import ConfirmClassOwnerModal from "./ConfirmClassOwnerModal"; // Import the modal component

function CreateTrialClassForm(props) {
  const [selectedGradeLevel, setSelectedGradeLevel] = useState("");
  const [classTitle, setClassTitle] = useState("");
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [classTitleError, setClassTitleError] = useState("");
  const [gradeError, setGradeError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [classOwner, setClassOwner] = useState(null);
  const [countryId, setCountryId] = useState(null);

  const teacherId = props.teacherid;
  const schoolid = props.router.location.state.schoolid.schoolid;

  const submitButtonRef = React.createRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const countryid = matchingSchool.countryid;
          setCountryId(countryid)
          const gradesResponse = await getCountryGrades(countryid);
          const { grade1, grade2 } = gradesResponse;
          setGradeLevelOptions([grade1, grade2]);

          const teachersResponse = await getTeachers(schoolid);
          setTeacherOptions(teachersResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [schoolid]);

  useEffect(() => {
    if (submitted) {
      const isClassTitleValid = classTitle.length > 0;
      const isGradeValid = selectedGradeLevel.length > 0;

      setClassTitleError(isClassTitleValid ? "" : "Please enter a class name.");
      setGradeError(isGradeValid ? "" : "Please select a grade level.");
    }
  }, [classTitle, selectedGradeLevel, submitted]);

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitted(true);

    const isClassTitleValid = classTitle.length > 0;
    const isGradeValid = selectedGradeLevel.length > 0;

    if (!isClassTitleValid || !isGradeValid) {
      setClassTitleError(
        !isClassTitleValid ? "Please enter a class name." : ""
      );
      setGradeError(!isGradeValid ? "Please select a grade level." : "");
      return;
    }

    setShowModal(true); // Show the modal instead of directly creating the class
  }

  function handleModalConfirm(selectedTeacherId) {
    const matchingGrade = gradeLevelOptions.includes(selectedGradeLevel);
    if (matchingGrade) {
      const trialClass = true;
      const grade = selectedGradeLevel;
      const formattedClassTitle = `24-Trial-${classTitle}`;

      const finalClassOwner = selectedTeacherId || teacherId;

      createClass(
        formattedClassTitle,
        grade,
        grade === gradeLevelOptions[0],
        grade === gradeLevelOptions[1],
        trialClass,
        false,
        finalClassOwner
      )
        .then(() => {
          // Only navigate if the user didn't select "createTeacher"

          setTimeout(() => {
            props.router.navigate("/classadmindashboard");
          }, 3000); // Delay for 3 seconds before navigating
        })
        .catch((error) => {
          console.error("Error creating class:", error);
        });
    }
    setShowModal(false);
  }

  function handleModalClose() {
    setShowModal(false);
  }

  function handleSelectOwner(teacherId) {
    setClassOwner(teacherId);
  }

  return (
    <>
      <Form className="form form-detail-trial" onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="class-name">
          <Form.Label className="create-class-label-trial" column sm="2">
            Class Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              placeholder="Trial Class ABC..."
              id="classname-form-input"
              size="md"
              type="text"
              value={`24-Trial-${classTitle}`}
              onChange={(e) => setClassTitle(e.target.value.slice(9))}
              isInvalid={submitted && !!classTitleError}
            />
            <Form.Control.Feedback type="invalid">
              {classTitleError}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="grade">
          <Form.Label className="create-class-label-trial" column sm="2">
            Class Level
          </Form.Label>
          <Col>
            <Form.Control
              as="select"
              id="grade-form-input"
              size="md"
              value={selectedGradeLevel}
              onChange={(e) => setSelectedGradeLevel(e.target.value)}
              isInvalid={submitted && !!gradeError}
            >
              <option value="">Select Class Level...</option>
              {/* {gradeLevelOptions.map((grade, index) => (
                <option key={index} value={grade}>
                  {grade}
                </option>
              ))} */}
              {gradeLevelOptions.map((grade, index) => {
                // Only render the first option if the country ID matches the restricted one
                if (countryId === '74bb2897-dbbe-41e3-b547-caee866545cf' && index === 1) {
                  return null; // Hide the 1st index option
                }
                return (
                  <option key={index} value={grade}>
                    {grade}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {gradeError}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <div className="float-right">
          <SimpleNavigationButton
            path="/classadmindashboard"
            label="Cancel"
            colour="white"
          />
          <button className="green-button" type="submit" ref={submitButtonRef}>
            Create
          </button>
        </div>
      </Form>

      <ConfirmClassOwnerModal
        show={showModal}
        onConfirm={handleModalConfirm}
        onClose={handleModalClose}
        teacherOptions={teacherOptions}
        onSelectOwner={handleSelectOwner}
      />
    </>
  );
}

export default withRouter(CreateTrialClassForm);
