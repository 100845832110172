import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./ReportClassTable.css";
import {
  getJiSiReportByClassId,
} from "../services/APIClient";

function ExpandedJiSiReportView(props) {
  const {
    students,
    showAtRiskOnly,
    setShowAtRiskOnly,
    teachers,
    teacherid,
    classid,
    studentsReadingScore,
    onSortData,
    showSortedData
  } = props;

  console.log(teachers, "Teachers");

  console.log(props)

  const dummyHeaderArray = [
    "Reading Score Notes",
    "General Literacy",
    "Additional Needs",
    "Support Plan",
    "EAL",
    "SET",
    "Attendance Affecting Scores",
  ];

  const [sortCriteria, setSortCriteria] = useState({ field: 'name', order: 'asc' });
  const [sortedData, setSortedData] = useState([]);
  const [jisiReportDetails, setJiSiReportDetails] = useState([]);
  const [combinedData, setCombinedData] = useState([]);


  const handleAtRiskFilterChange = (e) => {
    setShowAtRiskOnly(e.target.checked);
  };

  useEffect(() => {
    if (classid) {
      getJiSiReportByClassId(classid)
        .then((response) => {
          console.log("JiSi Class Report Data:", response);
          setJiSiReportDetails(response)
          combineData(studentsReadingScore, response);
        })
        .catch((error) => console.error("Error fetching JiSi report:", error));
    }
  }, [classid, studentsReadingScore]);

  console.log("jisiReportDetails:", jisiReportDetails)

  const combineData = (students, reports) => {
    const defaultReportDetails = {
      readingscorenotes: [],
      literacyobservations: "",
      additionalneeds: [],
      supportplan: [],
      eal: false,
      set: false,
      attendanceaffectingscores: false
    };

    const reportsMap = reports.reduce((map, report) => {
      // Spread the default values first, then override them with actual report data
      map[report.studentid] = { ...defaultReportDetails, ...report };
      return map;
    }, {});

    const combined = students.map(student => ({
      ...student,
      ...reportsMap[student.id]  // this will now always have all fields, some may just be default values
    }));

    setCombinedData(combined);
  };

  useEffect(() => {
    const sortedStudents = sortStudents(combinedData, sortCriteria);
    setSortedData(sortedStudents);
    onSortData(sortedStudents)
  }, [combinedData, sortCriteria]);

  const sortStudents = (students, criteria) => {
    return [...students].sort((a, b) => {
      if (criteria.field === 'name') {
        return criteria.order === 'asc' ? a.name?.localeCompare(b.name) : b.name?.localeCompare(a.name);
      } else if (criteria.field === 'readingScore') {
        let scoreA = a.readingScore === "No completed tasks" || a.readingScore === 'NA' ? -1 : Number(a.readingScore);
        let scoreB = b.readingScore === "No completed tasks" || b.readingScore === 'NA' ? -1 : Number(b.readingScore);
        return criteria.order === 'asc' ? scoreA - scoreB : scoreB - scoreA;
      }
    });
  };

  const handleSortChange = (field) => {
    setSortCriteria(prev => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortIcon = (field) => {
    if (sortCriteria.field === field) {
      return sortCriteria.order === 'asc' ?
        process.env.PUBLIC_URL + "/assets/imgs/desc-icon-active.png" :
        process.env.PUBLIC_URL + "/assets/imgs/asc-icon-active.png";
    }
    return process.env.PUBLIC_URL + "/assets/imgs/asc-icon-default.png";
  };

  const getReadingScoreClass = (score) => {
    if (score === 0) {
      return "low-color";
    } else if (score > 0 && score <= 20) {
      return "low-color";
    } else if (score > 20 && score <= 40) {
      return "med-color";
    } else if (score > 40 && score <= 100) {
      return "noRisk-color";
    } else {
      return "blah";
    }
  };

  console.log("sortedData:", sortedData)
  console.log("combinedData:", combinedData)

  const dataToDisplay = showSortedData?.length > 0 ? showSortedData : sortedData;

  const formatList = (items) => (
    <>{items?.map((item, index) => <div key={index} className="item-list">{item}</div>)}</>
  );

  return (
    <Row>
      <Col>
        <div>
          <Table className="custom-table">
            <thead className="table-header">
              <tr>
                <th colSpan={12} className="at-risk-filter-row">
                  <div className="at-risk-filter">
                    <label htmlFor="atRiskFilter">Show only "At-Risk"</label>
                    <input
                      type="checkbox"
                      name="atRiskFilter"
                      id="atRiskFilter"
                      checked={showAtRiskOnly}
                      onChange={handleAtRiskFilterChange}
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/imgs/0-20Bar.png"}
                      alt="0-20%"
                      className="risk-bar"
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/assets/imgs/21-40Bar.png"}
                      alt="21-40%"
                      className="risk-bar"
                    />
                  </div>
                </th>
              </tr>
              <tr className="jisi-row">
                <th
                  className="header-th-left header-size-results"
                  onClick={() => handleSortChange("name")}
                >
                  <div className="header-content">
                    Student Name
                    <img
                      className="sort-icon"
                      src={getSortIcon("name")}
                      alt="Sort"
                    />
                  </div>
                </th>
                <th
                  className="header-th header-size-results"
                  onClick={() => handleSortChange("readingScore")}
                >
                  <div className="header-content">
                    Reading Score
                    <img
                      className="sort-icon"
                      src={getSortIcon("readingScore")}
                      alt="Sort"
                    />
                  </div>
                </th>
                {dummyHeaderArray.map((header, index) => (
                  <th key={index} className="header-th header-size-results">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataToDisplay?.filter(
                (student) =>
                  !showAtRiskOnly ||
                  (student.readingScore !== undefined && student.readingScore !== null &&
                    student.readingScore <= 40)
              ).map((report) => (
                <tr key={report.id} className="jisi-row">
                  <td>{report.name}</td>
                  <td className="centre-student-items task-badge-parent">
                    {report.readingScore !== undefined && report.readingScore !== null ? (
                      report.readingScore === "No completed tasks" ? (
                        <span className="reading-score">
                          {report.readingScore}
                        </span>
                      ) : (
                        <span
                          className={`reading-score ${getReadingScoreClass(
                            report.readingScore
                          )}`}
                        >
                          {report.readingScore}%
                        </span>
                      )
                    ) : null}
                  </td>
                  <td>{formatList(report.readingscorenotes)}</td>
                  <td>{report.literacyobservations}</td>
                  <td>{formatList(report.additionalneeds)}</td>
                  <td>{formatList(report.supportplan)}</td>
                  <td>{report.eal ? "Yes" : "No"}</td>
                  <td>{report.set ? "Yes" : "No"}</td>
                  <td>{report.attendanceaffectingscores ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
}

export default ExpandedJiSiReportView;
